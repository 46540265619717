import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { ApiConstant, AppConstant, KeyConstant, LangConstant } from "const";
import { ApiUtils, BranchUtils, EventBusUtils, HumpUtils, SystemUtils } from "utils";
import localforage from "localforage";
import useInvalidateQueries from "hooks/common/useInvalidateQueries";

export default function useBranchDetails() {
  const { t: getLabel } = useTranslation();

  const { invalidateBranchQueries } = useInvalidateQueries();

  const branchDetailQuery = useQuery({
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    queryKey: [ApiConstant.REACT_QUERY_KEYS.getBranchDetails],
    queryFn: async () => {
      let response = await ApiUtils.createApiWithToken(false).get(ApiConstant.BRANCH_INFORMATION);
      response = response.data; // Api v2 trả về dữ liệu trong response.data
      if (response.status !== ApiConstant.STT_OK) return null;
      const branchDetails = HumpUtils.camelizeKeys(response.data[0]);
      if (!branchDetails) return null;
      if (!branchDetails.attachmentId) return { ...branchDetails, avatar: null };
      const localAvatar = await localforage.getItem(
        KeyConstant.KEY_CACHED_BRANCH_AVATAR_BLOB + branchDetails.attachmentId,
      );
      if (localAvatar) return { ...branchDetails, avatar: URL.createObjectURL(localAvatar) };
      const avatarPayload = HumpUtils.decamelizeKeys({ attachmentId: branchDetails.attachmentId });
      const requestConfigs = { ...ApiUtils.API_CONFIG, responseType: "blob" };
      const avatarResponse = await ApiUtils.createApiWithToken(false, requestConfigs).get(
        ApiConstant.GET_FILE_ATTACHMENT,
        avatarPayload,
      );
      if (avatarResponse.status !== ApiConstant.STT_OK) return { ...branchDetails, avatar: null };
      const avatarUrl = URL.createObjectURL(avatarResponse.data);
      // Cache avatar branch vào IndexedDB localforage
      await localforage.setItem(
        KeyConstant.KEY_CACHED_BRANCH_AVATAR_BLOB + branchDetails.attachmentId,
        avatarResponse.data,
      );
      return { ...branchDetails, avatar: avatarUrl };
    },
    onSuccess: async branchDetails => {
      if (branchDetails) {
        const currentBranch = HumpUtils.camelizeKeys(BranchUtils.getCurrentBranch());
        BranchUtils.handlePersistBranch(branchDetails, false);
        BranchUtils.handleUpdateBranchList({ ...currentBranch, ...branchDetails });
        EventBusUtils.emit(AppConstant.EVENT_BUS_EVENTS.fetchBranchDetails, branchDetails.avatar);
      } else {
        SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
      }
    },
    onError: error => {
      console.error(error);
      SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
    },
  });

  const checkHealthQuery = useQuery({
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    queryKey: [ApiConstant.REACT_QUERY_KEYS.getBranchList],
    queryFn: async () => {
      let response = await ApiUtils.createApiWithToken(false).get(ApiConstant.CHECK_HEALTH);
      return { ...response.data?.data };
    },
    onSettled: () => {
      SystemUtils.openCircleLoading(false);
    },
    onError: error => {
      console.error(error);
      SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
    },
  });

  return {
    branchDetails: branchDetailQuery.data,
    isLoading: branchDetailQuery.isLoading,
    invalidateBranchQueries,
    checkHealth: checkHealthQuery.data,
  };
}
