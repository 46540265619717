import React, { useState } from "react";
import { Add, Delete, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LangConstant, SystemConstant } from "const";
import { useTranslation } from "react-i18next";
import { ConfirmDialog } from "components";
import { useDeleteMember } from "hooks";

const MemberDialog = ({
  open,
  selectedDepartment,
  currentPageUsers,
  totalPages,
  page,
  onFilter,
  onOpenAddMemberDialog,
  onClose,
}) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();

  const userDetail = getLabel(LangConstant.OBJ_USER_DETAIL, { returnObjects: true });

  const { deleteMemberAsync } = useDeleteMember();

  const [deleteMemberId, setDeleteMemberId] = useState(null);

  const handleChangeSearch = event => {
    onFilter({ searchValue: event.target.value, page: SystemConstant.ACTIVE_PAGINATION });
  };

  const handleChangePage = (event, page) => {
    onFilter({ page: page });
  };

  const handleOpenDeleteMember = userId => {
    setDeleteMemberId(userId);
  };

  const handleCloseDeleteMember = () => {
    setDeleteMemberId(null);
  };

  const handleDeleteMember = () => {
    const userIds = [deleteMemberId];
    deleteMemberAsync({
      departmentId: selectedDepartment.departmentId,
      userIds: userIds,
      unitId: selectedDepartment.unitId,
      onClose: handleCloseDeleteMember,
    });
  };

  return (
    <Dialog open={open} fullWidth classes={{ paper: classes.component }} maxWidth="lg">
      <DialogTitle className={classes.title}>
        <Typography className={classes.titleName}>{getLabel(LangConstant.TXT_MEMBER_LIST)}</Typography>
      </DialogTitle>
      <DialogContent>
        <Box className={classes.memberHeader}>
          <TextField
            fullWidth
            name="name"
            className={classes.textField}
            size="small"
            onChange={handleChangeSearch}
            placeholder={getLabel(LangConstant.P_SEARCH_USER_BY_NAME_PHONE_EMAIL)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          <Button variant="contained" className={classes.addMemberButton} onClick={onOpenAddMemberDialog}>
            <Add />
            {getLabel(LangConstant.TXT_ADD_MEMBER)}
          </Button>
        </Box>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table stickyHeader className={classes.boxTable}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "35%", fontWeight: "bold" }}>{userDetail.userName}</TableCell>
                <TableCell sx={{ width: "25%", fontWeight: "bold" }}>{userDetail.phone}</TableCell>
                <TableCell sx={{ width: "30%", fontWeight: "bold" }}>{userDetail.email}</TableCell>
                <TableCell sx={{ width: "10%" }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentPageUsers && currentPageUsers.length > 0 ? (
                currentPageUsers.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.textCell} title={row.name}>
                      {row.name}
                    </TableCell>
                    <TableCell>{row.phone}</TableCell>
                    <TableCell className={classes.textCell} title={row.mail}>
                      {row.mail}
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleOpenDeleteMember(row.id)}>
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    <small>
                      <i>{getLabel(LangConstant.TXT_NOT_FOUND)}</i>
                    </small>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {totalPages > 1 && (
          <Box className={classes.pagination}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handleChangePage}
              color="primary"
              variant="outlined"
              shape="rounded"
              showFirstButton
              showLastButton
            />
          </Box>
        )}
        <ConfirmDialog
          open={Boolean(deleteMemberId)}
          title={getLabel(LangConstant.TXT_CONFIRM_DELETE_MEMBER)}
          onDeny={handleCloseDeleteMember}
          onConfirm={handleDeleteMember}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="error" className={classes.buttonCancel} onClick={onClose}>
          {getLabel(LangConstant.L_CLOSE)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MemberDialog;

const useStyles = makeStyles(() => ({
  component: {
    backgroundColor: "#FFFFFF",
    height: "750px",
  },

  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "8px 0",
  },

  titleName: {
    fontSize: "24px",
    fontWeight: 700,
  },

  memberHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  addMemberButton: {
    minWidth: "fit-content",
    marginLeft: "24px",
  },

  textField: {
    margin: "10px 0px",
  },

  tableContainer: {
    marginTop: "32px",
    width: "auto",
    height: "400px",
  },

  buttonCancel: {
    width: "150px",
    margin: "0px 16px 16px",
  },

  pagination: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: "32px",
  },

  boxTable: {
    whiteSpace: "nowrap",
    tableLayout: "fixed",
  },

  textCell: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));
