import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { ApiConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import { ApiUtils, BranchUtils, HumpUtils, StorageUtils, SystemUtils } from "utils";
import useInvalidateQueries from "hooks/common/useInvalidateQueries";
import qs from "qs";

export default function useCreateBranch() {
  const { t: getLabel } = useTranslation();

  const { invalidateBranchQueries } = useInvalidateQueries();

  const createBranchMutation = useMutation({
    mutationFn: async data => {
      const payload = { packageId: data.packageId, license: data.license };
      const response = await ApiUtils.createApiWithToken(true).post(
        ApiConstant.CREATE_BRANCH,
        qs.stringify(HumpUtils.decamelizeKeys(payload)),
      );
      return response.data;
    },
    onMutate: () => {
      SystemUtils.openCircleLoading();
    },
    onSettled: () => {
      SystemUtils.openCircleLoading(false);
    },
    onSuccess: async (response, { onClose }) => {
      if (response.status === ApiConstant.STT_OK) {
        await ApiUtils.apiCreateUserActivitiesLog(SystemConstant.LOGGER_ACTION_TYPES.ADD_BRANCH);
        const currentBranchList = StorageUtils.getStoreData(KeyConstant.KEY_BRANCH_LIST);
        const newBranchList = BranchUtils.sortBranchByName([...currentBranchList, response.data]);
        const isRemember = StorageUtils.getStoreData(KeyConstant.KEY_REMEMBER_KEY);
        StorageUtils.storeData(KeyConstant.KEY_BRANCH_LIST, newBranchList, isRemember);
        await invalidateBranchQueries();
        onClose();
      } else {
        SystemUtils.appendNotification(getLabel(LangConstant.TXT_FAILURE_VALIDATION), "error");
      }
    },
    onError: error => {
      console.error(error);
      SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
    },
  });

  const createBranch = payload => {
    createBranchMutation.mutate(payload);
  };

  return { createBranch };
}
