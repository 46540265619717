import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { AssignmentOutlined } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { LangConstant, SystemConstant } from "const";
import { useCreateDepartment, useEditDepartment } from "hooks";
import { CommonUtils, SystemUtils, UnitUtils } from "utils";

const DepartmentDialog = ({ open, unitList, selectedUnit, selectedDepartment, onClose }) => {
  const isEdit = selectedUnit === null && Boolean(selectedDepartment);
  const classes = useStyles();
  const { t: getLabel } = useTranslation();
  const departmentDetail = getLabel(LangConstant.OBJ_DEPARTMENT_DETAIL, { returnObjects: true });
  const departmentError = getLabel(LangConstant.OBJ_DEPARTMENT_ERROR, { returnObjects: true });
  const { createDepartment } = useCreateDepartment();
  const { editDepartment } = useEditDepartment();

  const [departmentForm, setDepartmentForm] = useState({
    name: selectedDepartment?.departmentName || "",
    selectedUnitId: selectedUnit?.unitId || selectedDepartment?.unitId || "",
  });

  const handleChangeDepartmentForm = event => {
    if (event.target.name === "name") {
       setDepartmentForm(currentState => ({
         ...currentState,
         [event.target.name]: CommonUtils.capitalizeFirstLetter(event.target.value),
       }));
    } else {
      setDepartmentForm(currentState => ({
        ...currentState,
        [event.target.name]: event.target.value,
      }));
    }
  };

  const handleSubmit = () => {
    if (!UnitUtils.isValidName(departmentForm.name)) {
      SystemUtils.appendNotification(getLabel(departmentError[SystemConstant.UNIT_ERROR.IN_WRONG_FORMAT]), "error");
    } else if (isEdit) {
      editDepartment({
        unitId: departmentForm.selectedUnitId,
        departmentName: departmentForm.name,
        departmentId: selectedDepartment.departmentId,
        onClose,
      });
    } else {
      createDepartment({
        unitId: departmentForm.selectedUnitId,
        departmentName: departmentForm.name,
        onClose,
      });
    }
  };

  useEffect(() => {
    setDepartmentForm(currentState => ({
      ...currentState,
      name: selectedDepartment?.departmentName || "",
      selectedUnitId: selectedUnit?.unitId || selectedDepartment?.unitId || "",
    }));
  }, [selectedUnit, selectedDepartment]);

  return (
    <Dialog open={open} fullWidth classes={{ paper: classes.component }}>
      <DialogTitle className={classes.title}>
        <Typography className={classes.titleName}>
          {getLabel(isEdit ? LangConstant.TXT_EDIT_DEPARTMENT : LangConstant.TXT_CREATE_DEPARTMENT)}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.departmentContent}>
        <InputLabel className={classes.labelField}>{departmentDetail.name}</InputLabel>
        <TextField
          fullWidth
          name="name"
          className={classes.infoField}
          value={departmentForm?.name}
          onChange={handleChangeDepartmentForm}
          placeholder={getLabel(LangConstant.TXT_ENTER_DEPARTMENT_NAME)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AssignmentOutlined />
              </InputAdornment>
            ),
          }}
        />
        <InputLabel className={classes.labelField}>{departmentDetail.unit}</InputLabel>
        <Select
          fullWidth
          name="selectedUnitId"
          className={classes.infoField}
          value={departmentForm.selectedUnitId}
          onChange={handleChangeDepartmentForm}
          disabled={isEdit}
        >
          {unitList.map((row, index) => (
            <MenuItem key={index} value={row.unitId}>
              {row.unitName}
            </MenuItem>
          ))}
        </Select>
      </DialogContent>
      <DialogContent className={classes.boxButton}>
        <Button variant="outlined" color="error" sx={{ width: "50%" }} onClick={onClose}>
          {getLabel(LangConstant.L_CANCEL_ADD_BRANCH)}
        </Button>
        <Button variant="contained" sx={{ width: "50%", ml: 4 }} className={classes.editButton} onClick={handleSubmit}>
          {getLabel(isEdit ? LangConstant.L_CONFIRM_BUTTON : LangConstant.L_CREATE)}
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default DepartmentDialog;

const useStyles = makeStyles(() => ({
  component: {
    backgroundColor: "#FFFFFF",
  },

  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "8px 0",
  },

  titleName: {
    fontSize: "24px",
    fontWeight: 700,
  },

  departmentContent: {
    margin: "0px",
    height: "400px",
    overflowY: "scroll",
  },

  infoField: {
    margin: "10px 0px",
  },

  labelField: {
    fontWeight: 600,
    color: "#000000",
  },

  boxButton: {
    display: "flex",
    justifyContent: "space-between",
  },
}));
