import React from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Add, Search } from "@mui/icons-material";
import { LangConstant } from "const";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";

const UnitListHeader = ({ searchValue, handleSearchUnit, onOpenUnitDialog }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation([LangConstant.NS_COMMON, LangConstant.NS_LOGIN]);

  const handleOpenUnitDialog = () => {
    onOpenUnitDialog();
  };

  const handleDebounceSearchUnit = debounce(handleSearchUnit, 500);

  return (
    <Box className={classes.boxHeader}>
      <Box className={classes.boxHeaderLeft}>
        <Typography variant="h6" className={classes.boxHeaderTitle}>
          {getLabel(LangConstant.TXT_TITLE_UNIT_LIST)}
        </Typography>
      </Box>
      <Box>
        <TextField
          size="small"
          defaultValue={searchValue}
          className={classes.textFieldSearch}
          onChange={handleDebounceSearchUnit}
          placeholder={getLabel(LangConstant.P_SEARCH_UNIT)}
          InputProps={{ startAdornment: <Search className={classes.icon} /> }}
        />
        <Button variant="contained" onClick={handleOpenUnitDialog}>
          <Add className={classes.icon} />
          {getLabel(LangConstant.L_ADD_UNIT_BUTTON)}
        </Button>
      </Box>
    </Box>
  );
};

export default UnitListHeader;

const useStyles = makeStyles(theme => ({
  boxHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: 10,
    marginBottom: 20,
    [theme.breakpoints.down("xl")]: {
      display: "block",
    },
  },

  boxHeaderLeft: {
    display: "flex",
    alignItems: "center",
  },

  boxHeaderTitle: {
    [theme.breakpoints.down("xl")]: {
      marginBottom: 18,
    },
  },

  icon: {
    marginRight: 4,
  },

  textFieldSearch: {
    width: "400px",
    marginRight: 20,
  },
}));
