import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import qs from "qs";
import { ApiConstant, AppConstant, KeyConstant, LangConstant, PathConstant, SystemConstant } from "const";
import { ApiUtils, AuthUtils, StorageUtils, SystemUtils } from "utils";
import usePreAuth from "./usePreAuth";

export default function useSmartOTP() {
  const { t: getLabel } = useTranslation(LangConstant.NS_COMMON);
  const { persistLoginConfigs } = usePreAuth();

  const getCurrentOtpRetry = () => {
    const SMART_OTP_CONFIGS = AuthUtils.getCurrentSmartOtpConfigs();
    return SMART_OTP_CONFIGS?.retry || 0;
  };

  const getCurrentErrorMessage = () => {
    const SMART_OTP_CONFIGS = AuthUtils.getCurrentSmartOtpConfigs();
    if (SMART_OTP_CONFIGS) {
      const retry = SMART_OTP_CONFIGS?.retry || 0;
      if (retry !== SystemConstant.SMART_OTP_CONFIGS.maxRetry && retry > 0) {
        return getLabel(LangConstant.TXT_SMART_OTP_RETRY, { retry: retry });
      }
    }
    return "";
  };

  const isBlocked = () => {
    const SMART_OTP_CONFIGS = AuthUtils.getCurrentSmartOtpConfigs();
    if (SMART_OTP_CONFIGS) {
      if (SMART_OTP_CONFIGS.otpBlockedAt !== null) {
        const smartOtpEnableAt = SMART_OTP_CONFIGS.otpBlockedAt + SystemConstant.SMART_OTP_CONFIGS.blockTime;
        return Date.now() < smartOtpEnableAt;
      }
    }
    return false;
  };

  const [smartOtpRemoteCode, setSmartOtpRemoteCode] = useState(
    StorageUtils.getStoreData(KeyConstant.KEY_SMART_OTP_REMOTE_CODE),
  );

  const [verifySmartOtpRemoteCode, setVerifySmartOtpRemoteCode] = useState(null);

  const [otp, setOtp] = useState({
    code: "",
    retry: getCurrentOtpRetry(),
    error: getCurrentErrorMessage(),
    resentOtpTrigger: 0,
    blocked: isBlocked(),
  });

  const handleChangeOtp = value => {
    setOtp(state => ({ ...state, code: value }));
  };

  const handleVerifyOTPSuccess = async (response, otpForm) => {
    let branchData = response.data.data;
    let defaultBranch = branchData.find(item => item.domain === AppConstant.DEFAULT_DOMAIN);
    if (!Boolean(defaultBranch)) defaultBranch = branchData[0];
    persistLoginConfigs(defaultBranch, otpForm, response, branchData, SystemConstant.LOGIN_ROLE.ADMIN);
    await ApiUtils.apiCreateUserActivitiesLog(SystemConstant.LOGGER_ACTION_TYPES.LOGIN);
    const newRetry = SystemConstant.SMART_OTP_CONFIGS.maxRetry;
    AuthUtils.setCurrentSmartOtpConfigs({
      code: otpForm.code,
      retry: newRetry,
      otpBlockedAt: null,
      otpSentAt: Date.now(),
    });
  };

  const handleWrongOTP = () => {
    const smartOtpData = AuthUtils.getCurrentSmartOtpConfigs();
    const newRetry = smartOtpData.retry - 1;
    AuthUtils.setCurrentSmartOtpConfigs({
      code: null,
      retry: newRetry,
      otpBlockedAt: null,
      otpSentAt: smartOtpData.otpSentAt,
    });
  };

  const handleOTPBlocked = () => {
    const smartOtpData = AuthUtils.getCurrentSmartOtpConfigs();
    AuthUtils.setCurrentSmartOtpConfigs({
      code: null,
      retry: 0,
      otpBlockedAt: Date.now(),
      otpSentAt: smartOtpData.otpSentAt,
    });
  };

  const handleReCountTimer = () => {
    setOtp(state => ({ ...state, resentOtpTrigger: state.resentOtpTrigger + 1, error: "" }));
  };

  /**
   * Update UI after sent OTP
   */
  useEffect(() => {
    const SMART_OTP_CONFIGS = AuthUtils.getCurrentSmartOtpConfigs();
    if (SMART_OTP_CONFIGS) {
      if (smartOtpRemoteCode === ApiConstant.STT_SMART_OTP_LIMIT) {
        setOtp(state => ({ ...state, error: getLabel(LangConstant.TXT_SMART_OTP_LIMIT_ERROR), blocked: false }));
      } else if (smartOtpRemoteCode === ApiConstant.STT_SMART_OTP_BLOCKED) {
        setOtp(state => ({
          ...state,
          code: "",
          retry: getCurrentOtpRetry(),
          error: getCurrentErrorMessage(),
          resentOtpTrigger: 0,
          blocked: true,
        }));
      } else if (smartOtpRemoteCode === ApiConstant.STT_OK) {
        setOtp(state => ({ ...state, resentOtpTrigger: state.resentOtpTrigger + 1, error: "", blocked: false }));
      }
    }
    StorageUtils.removeStoreData(KeyConstant.KEY_SMART_OTP_REMOTE_CODE);
    setSmartOtpRemoteCode(null);
  }, [smartOtpRemoteCode]);

  /**
   * Update UI after verify OTP
   */
  useEffect(() => {
    const SMART_OTP_CONFIGS = AuthUtils.getCurrentSmartOtpConfigs();
    if (SMART_OTP_CONFIGS) {
      if (verifySmartOtpRemoteCode === ApiConstant.STT_SMART_OTP_BLOCKED) {
        setOtp(state => ({
          ...state,
          code: "",
          retry: getCurrentOtpRetry(),
          error: getCurrentErrorMessage(),
          resentOtpTrigger: 0,
          blocked: true,
        }));
      } else if (verifySmartOtpRemoteCode === ApiConstant.STT_OK) {
        window.location.replace(PathConstant.BRANCH_MANAGEMENT);
      } else if (verifySmartOtpRemoteCode === ApiConstant.STT_SMART_OTP_WRONG) {
        setOtp(state => ({ ...state, retry: getCurrentOtpRetry(), error: getCurrentErrorMessage(), blocked: false }));
      } else if (verifySmartOtpRemoteCode === ApiConstant.STT_UNAUTHORIZED) {
        setOtp(state => ({ ...state, retry: getCurrentOtpRetry(), error: getCurrentErrorMessage(), blocked: false }));
      } else if (verifySmartOtpRemoteCode === ApiConstant.STT_SMART_OTP_EXPIRED) {
        setOtp(state => ({ ...state, code: "", error: getLabel(LangConstant.TXT_SMART_OTP_EXPIRED) }));
      }
    }
    setVerifySmartOtpRemoteCode(null);
  }, [verifySmartOtpRemoteCode]);

  const verifyOTPMutation = useMutation({
    mutationFn: async otpForm => {
      const preAuthForm = StorageUtils.getStoreData(KeyConstant.KEY_PRE_AUTH);
      const payload = {
        ...preAuthForm,
        password: window.atob(preAuthForm.password),
        otp: otpForm.code,
        mode: SystemConstant.WEB_ADMIN_VERIFY_MODE,
      };
      const response = await ApiUtils.createApiByConfig().post(ApiConstant.LOGIN, qs.stringify(payload));
      return response.data;
    },
    onMutate: () => {
      SystemUtils.openCircleLoading();
    },
    onSettled: () => {
      SystemUtils.openCircleLoading(false);
    },
    onSuccess: async (response, otpForm) => {
      if (response.status === ApiConstant.STT_OK) {
        await handleVerifyOTPSuccess(response, otpForm);
        setVerifySmartOtpRemoteCode(response.status);
      } else if (
        response.status === ApiConstant.STT_SMART_OTP_WRONG ||
        response.status === ApiConstant.STT_UNAUTHORIZED
      ) {
        // Smart OTP Wrong
        handleWrongOTP();
        setVerifySmartOtpRemoteCode(response.status);
      } else if (response.status === ApiConstant.STT_SMART_OTP_EXPIRED) {
        // Smart OTP Expired
        setVerifySmartOtpRemoteCode(response.status);
      } else if (response.status === ApiConstant.STT_SMART_OTP_BLOCKED) {
        // Smart OTP Blocked because they entered the wrong OTP many times
        handleOTPBlocked();
        setVerifySmartOtpRemoteCode(response.status);
      } else {
        // Server Internal Error
        SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
      }
    },
    onError: error => {
      console.error(error);
      SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
    },
  });

  const verifyOtp = payload => {
    verifyOTPMutation.mutate(payload);
  };

  return { otp, smartOtpRemoteCode, verifySmartOtpRemoteCode, verifyOtp, handleChangeOtp, handleReCountTimer };
}
