import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { ApiConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import { ApiUtils, HumpUtils, StorageUtils, SystemUtils } from "utils";
import useInvalidateQueries from "hooks/common/useInvalidateQueries";
import qs from "qs";

export default function useDeletePosition() {
  const { t: getLabel } = useTranslation();

  const { invalidatePositionQueries } = useInvalidateQueries();

  const deletePositionMutation = useMutation({
    mutationFn: async data => {
      const branchId = StorageUtils.getStoreData(KeyConstant.KEY_BRANCH_ID);
      const payload = { branchId, positionIds: data.positionIds };

      const response = await ApiUtils.createApiWithToken(false).post(
        ApiConstant.DELETE_POSITION,
        qs.stringify(HumpUtils.decamelizeKeys(payload)),
      );
      return HumpUtils.camelizeKeys(response.data);
    },
    onMutate: () => {
      SystemUtils.openCircleLoading();
    },
    onSettled: () => {
      SystemUtils.openCircleLoading(false);
    },
    onSuccess: async (response, payload) => {
      if (response.status === ApiConstant.STT_OK) {
        payload.onSuccess();
        payload.onClose();
        await invalidatePositionQueries();
        await ApiUtils.apiCreateUserActivitiesLog(SystemConstant.LOGGER_ACTION_TYPES.DELETE_POSITION);
        SystemUtils.appendNotification(getLabel(LangConstant.TXT_DELETE_POSITION_SUCCESS), "success");
      } else {
        SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
      }
    },
    onError: () => {
      SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
    },
  });

  const deletePosition = payload => {
    deletePositionMutation.mutate(payload);
  };

  return { deletePosition };
}
