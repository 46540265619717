import { useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import { ApiConstant, KeyConstant } from "const";
import { ApiUtils, HumpUtils, StorageUtils, SystemUtils } from "utils";

export default function usePositionList() {
  const location = useLocation();
  const history = useHistory();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const page = searchParams.get("page") || 1;
  const search = searchParams.get("search") || "";
  const unit = searchParams.get("unit_id") || "";
  const department = searchParams.get("department_id") || "";

  const [pagination, setPagination] = useState({ total: 1, current: parseInt(page) });
  const [searchInput, setSearchInput] = useState(search);
  const [unitId, setUnitId] = useState(unit);
  const [departmentId, setDepartmentId] = useState(department);

  const handleChangeSearchInput = event => {
    const newUrlSearchParams = new URLSearchParams(searchParams);
    const searchInputValue = event.target.value;
    newUrlSearchParams.set("page", "1");
    newUrlSearchParams.set("search", searchInputValue);
    history.push(location.pathname + "?" + newUrlSearchParams.toString());
    setPagination(state => ({ ...state, current: 1 }));
    setSearchInput(searchInputValue);
  };

  const handleChangeDepartment = event => {
    const newUrlSearchParams = new URLSearchParams(searchParams);
    const departmentIdValue = event.target.value;
    newUrlSearchParams.set("page", "1");
    newUrlSearchParams.set("department_id", departmentIdValue);
    history.push(location.pathname + "?" + newUrlSearchParams.toString());
    setPagination(state => ({ ...state, current: 1 }));
    setDepartmentId(departmentIdValue);
  };

  const handleChangeUnit = event => {
    const newUrlSearchParams = new URLSearchParams(searchParams);
    const unitIdValue = event.target.value;
    newUrlSearchParams.set("page", "1");
    newUrlSearchParams.set("unit_id", unitIdValue);
    newUrlSearchParams.set("department_id", "");
    history.push(location.pathname + "?" + newUrlSearchParams.toString());
    setPagination(state => ({ ...state, current: 1 }));
    setUnitId(unitIdValue);
    setDepartmentId("");
  };

  const handleChangePage = (_, page) => {
    const newUrlSearchParams = new URLSearchParams(searchParams);
    newUrlSearchParams.set("page", page);
    history.push(location.pathname + "?" + newUrlSearchParams.toString());
    setPagination(state => ({ ...state, current: parseInt(page) }));
  };

  const positionListQuery = useQuery({
    queryKey: [ApiConstant.REACT_QUERY_KEYS.getPositionList, searchInput, pagination.current, unitId, departmentId],
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    queryFn: async () => {
      SystemUtils.openCircleLoading();
      const branchId = StorageUtils.getStoreData(KeyConstant.KEY_BRANCH_ID);
      const paging = 1; // 1 for pagination | 0 for get all
      const limit = 10;
      const filter = JSON.stringify(
        HumpUtils.decamelizeKeys({ positionName: searchInput, unitId: unitId, departmentId: departmentId }),
      );
      const asc = 0;
      const offset = (pagination.current - 1) * limit;
      const orderBy = "created";
      const payload = { branchId, paging, limit, filter, asc, offset, orderBy };
      const response = await ApiUtils.createApiWithToken(false).get(
        ApiConstant.GET_LIST_POSITIONS,
        HumpUtils.decamelizeKeys(payload),
      );
      return HumpUtils.camelizeKeys(response.data);
    },
    onSettled: () => {
      SystemUtils.openCircleLoading(false);
    },
    onSuccess: response => {
      setPagination(state => ({ ...state, total: response?.data?.totalPages }));
    },
  });

  return {
    pagination: pagination,
    searchInput: searchInput,
    unitId,
    departmentId,
    positions: positionListQuery?.data?.data?.data || [],
    handleChangeSearchInput: handleChangeSearchInput,
    handleChangePage: handleChangePage,
    handleChangeUnit,
    handleChangeDepartment,
  };
}

