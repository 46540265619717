import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import qs from "qs";
import { ApiConstant, LangConstant, SystemConstant } from "const";
import { ApiUtils, HumpUtils, SystemUtils } from "utils";
import useInvalidateQueries from "hooks/common/useInvalidateQueries";

export default function useCreateUser() {
  const { t: getLabel } = useTranslation();
  const { invalidateUserQueries } = useInvalidateQueries();

  const createUserMutation = useMutation({
    mutationFn: async payload => {
      const response = await ApiUtils.createApiWithToken(false).post(
        ApiConstant.CREATE_ACCOUNT,
        qs.stringify(HumpUtils.decamelizeKeys(payload)),
      );
      return HumpUtils.camelizeKeys(response.data);
    },
    onMutate: () => {
      SystemUtils.openCircleLoading();
    },
    onSettled: () => {
      SystemUtils.openCircleLoading(false);
    },
    onSuccess: async (response, { onClose }) => {
      if (response.status === ApiConstant.STT_OK && parseInt(response.data) === SystemConstant.USER_ERROR.DUPLICATED) {
        SystemUtils.appendNotification(getLabel("TXT_PHONE_DUPLICATED"), "success");
        await invalidateUserQueries();
        onClose();
      } else if (response.status === ApiConstant.STT_OK) {
        SystemUtils.appendNotification(getLabel("TXT_CREATE_ACCOUNT_SUCCESS"), "success");
        await invalidateUserQueries();
        onClose();
      } else if (
        response.status === ApiConstant.STT_BAD_REQUEST &&
        parseInt(response.error) === SystemConstant.USER_ERROR.IN_WRONG_FORMAT
      ) {
        SystemUtils.appendNotification(getLabel("TXT_DATA_WRONG_FORMAT"), "error");
      } else {
        SystemUtils.appendNotification(getLabel("TXT_CREATE_ACCOUNT_FAIL"), "error");
      }
    },
    onError: error => {
      console.error(error);
      SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
    },
  });

  const createUser = payload => {
    createUserMutation.mutate(payload);
  };

  return { createUser };
}
