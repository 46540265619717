import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LangConstant, ImageConstant, AppConstant, SystemConstant } from "const";
import { ConfirmDialog, PageTitle, PaperHeader } from "components";
import {
  useBranchDetails,
  useDeleteDepartment,
  useDeleteUnit,
  useDepartmentList,
  useDepartmentUserList,
  useUnitList,
  useUnitUserList,
} from "hooks";
import {
  ActionPopover,
  CompanyProfile,
  CreateChannelDialog,
  DepartmentDialog,
  UnitDialog,
  UnitListHeader,
  UnitListTable,
  MemberDialog,
  AddMemberDialog,
} from "./components";
import { SystemUtils } from "utils";

const CompanyManagement = () => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation([LangConstant.NS_COMMON, LangConstant.NS_LOGIN]);

  const titleObjectContent = getLabel(LangConstant.OBJ_SIDEBAR_TITLE, { returnObjects: true });
  const CompanyProfileIcon = ImageConstant.CompanyProfileIcon;

  const {
    unitList,
    totalPage,
    page,
    searchValue,
    isLoading: isLoadingUnitList,
    handleSearchUnit,
    handleChangePage,
  } = useUnitList();

  const { branchDetails, isLoading: isLoadingBranchDetails } = useBranchDetails();
  const { departmentList, handleToggleShowDepartment } = useDepartmentList();

  const { deleteUnit } = useDeleteUnit();
  const { deleteDepartment } = useDeleteDepartment();

  const [actionPopoverConfig, setActionPopoverConfig] = useState({
    anchorEl: null,
    selectedRow: null,
    type: AppConstant.COMPANY_MANAGEMENT_TYPE.unit,
  });

  const [unitDialogState, setUnitDialogState] = useState({
    open: false,
    selectedUnit: null,
  });

  const [deleteDialogState, setDeleteDialogState] = useState({
    open: false,
    selectedRow: null,
    title: "",
  });

  const [departmentDialogState, setDepartmentDialogState] = useState({
    open: false,
    selectedUnit: null,
    selectedDepartment: null,
  });

  const [channelDialogState, setChannelDialogState] = useState({
    open: false,
    selectedUnit: null,
    selectedDepartment: null,
  });

  const [addMemberDialogState, setAddMemberDialogState] = useState({
    open: false,
    searchValue: "",
    selectedUnitId: "",
    page: SystemConstant.ACTIVE_PAGINATION,
  });

  const [memberDialogState, setMemberDialogState] = useState({
    open: false,
    selectedDepartment: null,
    searchValue: "",
    page: SystemConstant.ACTIVE_PAGINATION,
  });

  const { departmentUserList, totalPages, currentPageUsers } = useDepartmentUserList(
    actionPopoverConfig.selectedRow?.departmentId,
    memberDialogState.searchValue,
    memberDialogState.page,
  );

  const {
    unitUserList,
    totalPages: totalPagesUnit,
    currentPageUsers: currentPageUsersUnit,
  } = useUnitUserList(
    addMemberDialogState.selectedUnitId ||
      channelDialogState.selectedDepartment?.unitId ||
      channelDialogState.selectedUnit?.unitId ||
      "",
    addMemberDialogState.open ? addMemberDialogState.searchValue : memberDialogState.searchValue,
    addMemberDialogState.open ? addMemberDialogState.page : memberDialogState.page,
  );

  const handleOpenAction = (event, selectedRow, type) => {
    setActionPopoverConfig(currentState => ({
      ...currentState,
      anchorEl: event.target,
      selectedRow: selectedRow,
      type: type,
    }));
  };

  const handleCloseAction = () => {
    setActionPopoverConfig(currentState => ({ ...currentState, anchorEl: null }));
  };

  const handleOpenUnitDialog = data => {
    setUnitDialogState(currentState => ({ ...currentState, open: true, selectedUnit: data || null }));
  };

  const handleCloseUnitDialog = () => {
    setUnitDialogState(currentState => ({
      ...currentState,
      open: false,
      selectedUnit: null,
    }));
  };

  const handleOpenDepartmentDialog = (department, unit) => {
    setDepartmentDialogState(currentState => ({
      ...currentState,
      open: true,
      selectedUnit: unit,
      selectedDepartment: department,
    }));
    handleCloseAction();
  };

  const handleCloseDepartmentDialog = () => {
    setDepartmentDialogState(currentState => ({ ...currentState, open: false, selectedDepartment: null }));
  };

  const handleOpenMemberManagement = () => {
    setMemberDialogState(currentState => ({
      ...currentState,
      open: true,
      selectedDepartment: actionPopoverConfig.selectedRow,
    }));
    handleCloseAction();
  };

  const handleCloseMemberManagement = () => {
    setMemberDialogState(currentState => ({ ...currentState, open: false }));
  };

  const handleOpenCreateChannel = () => {
    setChannelDialogState(currentState => ({
      ...currentState,
      open: true,
      selectedUnit:
        actionPopoverConfig.type === AppConstant.COMPANY_MANAGEMENT_TYPE.unit ? actionPopoverConfig.selectedRow : null,
      selectedDepartment:
        actionPopoverConfig.type === AppConstant.COMPANY_MANAGEMENT_TYPE.unit ? null : actionPopoverConfig.selectedRow,
    }));
    handleCloseAction();
  };

  const handleCloseCreateChannel = () => {
    setChannelDialogState(currentState => ({
      ...currentState,
      open: false,
    }));
  };

  const handleEdit = () => {
    if (actionPopoverConfig.type === AppConstant.COMPANY_MANAGEMENT_TYPE.unit) {
      handleOpenUnitDialog(actionPopoverConfig.selectedRow);
    } else {
      handleOpenDepartmentDialog(actionPopoverConfig.selectedRow, null);
    }
    handleCloseAction();
  };

  const handleOpenDeleteDialog = () => {
    setDeleteDialogState(currentState => ({
      ...currentState,
      open: true,
      selectedRow: actionPopoverConfig.selectedRow,
      title: getLabel(
        actionPopoverConfig.type === AppConstant.COMPANY_MANAGEMENT_TYPE.unit
          ? LangConstant.TXT_CONFIRM_DELETE_UNIT
          : LangConstant.TXT_CONFIRM_DELETE_DEPARTMENT,
      ),
    }));
    handleCloseAction();
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogState(currentState => ({
      ...currentState,
      open: false,
      selectedRow: null,
      title: deleteDialogState.title,
    }));
  };

  const handleDelete = () => {
    if (actionPopoverConfig.type === AppConstant.COMPANY_MANAGEMENT_TYPE.unit) {
      deleteUnit({
        unitId: deleteDialogState.selectedRow.unitId,
        onClose: handleCloseDeleteDialog,
      });
    } else {
      deleteDepartment({
        unitId: deleteDialogState.selectedRow.unitId,
        departmentId: deleteDialogState.selectedRow.departmentId,
        onClose: handleCloseDeleteDialog,
      });
    }
  };

  const handleFilterMember = payload => {
    setMemberDialogState(currentState => ({ ...currentState, ...payload }));
  };

  const handleFilterAddMember = payload => {
    setAddMemberDialogState(currentState => ({ ...currentState, ...payload }));
  };

  const handleOpenAddMemberDialog = () => {
    setAddMemberDialogState(currentState => ({
      ...currentState,
      open: true,
      selectedDepartment: actionPopoverConfig.selectedRow,
      unitId: "",
    }));
  };

  const handleCloseAddMemberDialog = () => {
    setAddMemberDialogState(currentState => ({
      ...currentState,
      open: false,
      searchValue: "",
      selectedUnitId: "",
      page: SystemConstant.ACTIVE_PAGINATION,
    }));
  };

  useEffect(() => {
    SystemUtils.openCircleLoading(Boolean(isLoadingUnitList && isLoadingBranchDetails));
  }, [isLoadingUnitList, isLoadingBranchDetails]);

  return (
    <Fragment>
      <PageTitle>{titleObjectContent.companyManagement}</PageTitle>
      <Box className={classes.boxContainer}>
        <PaperHeader icon={<CompanyProfileIcon />} title={getLabel(LangConstant.TXT_COMPANY_PROFILE)} />
        <CompanyProfile branchDetails={branchDetails} />
        <Divider className={classes.dividerStyle} />
        <UnitListHeader
          searchValue={searchValue}
          handleSearchUnit={handleSearchUnit}
          onOpenUnitDialog={handleOpenUnitDialog}
        />
        <UnitListTable
          unitList={unitList}
          totalPage={totalPage}
          page={page}
          departmentList={departmentList}
          onToggleShowDepartment={handleToggleShowDepartment}
          onChangePage={handleChangePage}
          onOpenAction={handleOpenAction}
        />
        <ActionPopover
          anchorEl={actionPopoverConfig.anchorEl}
          open={Boolean(actionPopoverConfig.anchorEl)}
          selectedRow={actionPopoverConfig.selectedRow}
          type={actionPopoverConfig.type}
          onCloseAction={handleCloseAction}
          onOpenDepartmentDialog={handleOpenDepartmentDialog}
          onOpenMemberManagement={handleOpenMemberManagement}
          onCreateChannel={handleOpenCreateChannel}
          onEdit={handleEdit}
          onDelete={handleOpenDeleteDialog}
        />
        <UnitDialog
          open={unitDialogState.open}
          selectedUnit={unitDialogState.selectedUnit}
          onClose={handleCloseUnitDialog}
        />
        <ConfirmDialog
          open={deleteDialogState.open}
          title={deleteDialogState.title}
          onDeny={handleCloseDeleteDialog}
          onConfirm={handleDelete}
        />
        <DepartmentDialog
          open={departmentDialogState.open}
          unitList={unitList}
          selectedUnit={departmentDialogState.selectedUnit}
          selectedDepartment={departmentDialogState.selectedDepartment}
          onClose={handleCloseDepartmentDialog}
        />
        <CreateChannelDialog
          open={channelDialogState.open}
          selectedUnit={channelDialogState.selectedUnit}
          selectedDepartment={channelDialogState.selectedDepartment}
          departmentUserList={departmentUserList}
          unitUserList={unitUserList}
          onClose={handleCloseCreateChannel}
        />
        <MemberDialog
          open={memberDialogState.open}
          selectedDepartment={memberDialogState.selectedDepartment}
          totalPages={totalPages}
          currentPageUsers={currentPageUsers}
          page={memberDialogState.page}
          onFilter={handleFilterMember}
          onOpenAddMemberDialog={handleOpenAddMemberDialog}
          onClose={handleCloseMemberManagement}
        />
        <AddMemberDialog
          open={addMemberDialogState.open}
          selectedDepartment={memberDialogState.selectedDepartment}
          unitList={unitList}
          totalPages={totalPagesUnit}
          page={addMemberDialogState.page}
          currentPageUsers={currentPageUsersUnit}
          departmentUserList={departmentUserList}
          onFilter={handleFilterAddMember}
          onClose={handleCloseAddMemberDialog}
        />
      </Box>
    </Fragment>
  );
};

export default CompanyManagement;

const useStyles = makeStyles(theme => ({
  boxContainer: {
    padding: "30px",
    margin: "30px 0",
    backgroundColor: theme.palette.common.white,
    borderRadius: 4,
  },

  dividerStyle: {
    marginTop: "32px",
    marginBottom: "32px",
    borderWidth: "1px",
  },
}));
