import { ApiConstant, AppConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import { useMutation } from "react-query";
import { ApiUtils, EventBusUtils, HumpUtils, StorageUtils, SystemUtils } from "utils";
import { useTranslation } from "react-i18next";
import useInvalidateQueries from "hooks/common/useInvalidateQueries";
import qs from "qs";

export default function useDeleteMember() {
  const { t: getLabel } = useTranslation();

  const { invalidateUserQueries, invalidateUnitQueries, invalidateDepartmentQueries } = useInvalidateQueries();

  const deleteMemberMutation = useMutation({
    mutationFn: async data => {
      const branchId = StorageUtils.getStoreData(KeyConstant.KEY_BRANCH_ID);
      const payload = {
        branchId: branchId,
        departmentId: data.departmentId,
        userIds: data.userIds,
      };

      const response = await ApiUtils.createApiWithToken(false).post(
        ApiConstant.DELETE_DEPARTMENT_USERS,
        qs.stringify(HumpUtils.decamelizeKeys(payload)),
      );
      return response.data;
    },
    onMutate: () => {
      SystemUtils.openCircleLoading();
    },
    onSettled: () => {
      SystemUtils.openCircleLoading(false);
    },
    onSuccess: async (response, { onClose, unitId }) => {
      if (response.status === ApiConstant.STT_OK) {
        await ApiUtils.apiCreateUserActivitiesLog(SystemConstant.LOGGER_ACTION_TYPES.REMOVE_DEPARTMENT_USERS);
        SystemUtils.appendNotification(getLabel(LangConstant.TXT_DELETE_MEMBER_SUCCESS), "success");
        await invalidateUserQueries();
        await invalidateUnitQueries();
        await invalidateDepartmentQueries();
        EventBusUtils.emit(AppConstant.EVENT_BUS_EVENTS.refetchDepartments, unitId);
      } else {
        SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
      }
      onClose();
    },
    onError: (error, { onClose }) => {
      console.log(error);
      SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
      onClose();
    },
  });

  const deleteMemberAsync = async payload => {
    return deleteMemberMutation.mutateAsync(payload);
  };

  return { deleteMemberAsync };
}
