import { AppConstant, KeyConstant } from "const";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { EventBusUtils, StorageUtils } from "utils";

export default function useHandleLocale() {
  const { i18n } = useTranslation();

  const handleChangeLocale = () => {
    const lang = StorageUtils.getPermanenceData(KeyConstant.KEY_LANGUAGE);
    i18n.changeLanguage(lang);
  };

  useEffect(() => {
    handleChangeLocale();
  }, []);

  useEffect(() => {
    EventBusUtils.on(AppConstant.EVENT_BUS_EVENTS.changeLocale, handleChangeLocale);
    return () => EventBusUtils.off(AppConstant.EVENT_BUS_EVENTS.changeLocale, handleChangeLocale);
  });
}
