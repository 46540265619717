import React, {useEffect, useState} from "react";
import {makeStyles} from "@mui/styles";
import {Avatar, Box, Button, TextField, Typography} from "@mui/material";
import {isPossiblePhoneNumber, parsePhoneNumber} from "libphonenumber-js";
import QRCode from "react-qr-code";

const Qr = () => {
    const [ownerName, setOwnerName] = useState("");
    const [isShow, setIsShow] = useState(false);
    const [validatePhone, setValidatePhone] = useState(false);
    const [inviteUrl, setInviteUrl] = useState("");
    const classes = useStyles();


    const handleChangePhone = event => {
        setOwnerName(event.target.value.replace(/\D/g, ""));
    };

    const handleSubmit = event => {
        setIsShow(true)
    };

    console.log()
    useEffect(() => {
        var url = "https://" + window.env.DEEPLINK_HOST + "/forwardlink?";
        let phone = ""
        try {
            phone = ownerName !== "" ? parsePhoneNumber(ownerName, "VN").number : ""

            setValidatePhone(   isPossiblePhoneNumber(ownerName, "VN") === true);
        }catch (e) {
            console.log(e)
            setIsShow(false);
            setValidatePhone(false);
        }

        var mobileData = {
            type: 0,
            branch_domain: window.env.BRANCH_DOMAIN,
            branch_id: window.env.BRANCH_ID,
            owner_name: phone,
            service_id: 0,
        }
        const deepLinkUrl = window.env.TRIOS_SCHEMA + "://" + window.env.TRIOS_SCHEMA + "?" + new URLSearchParams(mobileData).toString();
        const data = {
            type: 0,
            branch_domain: window.env.BRANCH_DOMAIN,
            branch_id: window.env.BRANCH_ID,
            owner_name: phone,
            deeplink_url: deepLinkUrl,
            service_id: 0,
        };
        const searchParams = new URLSearchParams(data);
        url += searchParams.toString();
        setInviteUrl(url)
    }, [ownerName]);
    let logo = "https://" + window.env.BRANCH_DOMAIN + "/dev/v1/branch/attachment/get.json?branch_id=" + window.env.BRANCH_ID;
    return (
        <Box className={classes.root}>
            <Box className={classes.content}>
                <Box className={classes.logoDiv}>
                    <Avatar className={classes.logo} src={logo}
                    ></Avatar>
                </Box>

                <Typography >   <strong>Bạn thân mến,</strong></Typography>
                <TextField
                    label={"Vui lòng nhập số điện"}
                    fullWidth
                    required
                    defaultValue={ownerName || ""}
                    className={classes.infoField}
                    value={ownerName}
                    onChange={handleChangePhone}
                    InputProps={{
                        classes: {input: classes.infoValue},
                    }}
                    name="phone"
                />
                <Button  disabled={!validatePhone} onClick={handleSubmit}> Xác nhận</Button>
                <Typography>
                    Chào mừng bạn đến với hệ thống trao đổi thông tin trực tuyến an toàn
                    của {window.env.BRANCH_NAME}.
                </Typography>
                <Typography className="invitation">
                    Bạn nhận được lời mời tham gia từ quản trị viên của branch
                    {window.env.BRANCH_NAME} trên ứng dụng Trios. Để bắt đầu trò chuyện với các
                    thành viên khác trong tổ chức bạn vui lòng làm theo hướng dẫn dưới
                    đây:
                </Typography>
                <Typography className="invitation-option-1">
                    <strong>Cách 1:</strong>
                    Nhập số điện thoại và click vào link:
                    {isShow && (<a href={inviteUrl}>link tham gia.</a>)}

                </Typography>
                <Typography className="invitation-option-2">
                    <strong>Cách 2:</strong>
                    Bạn có thể thực hiện quét mã QR dưới đây để tham gia:
                </Typography>
                {isShow && (<QRCode
                    size={128}
                    style={{height: "auto", maxWidth: "50%", width: "50%"}}
                    value={inviteUrl}
                    viewBox={`0 0 64 64`}
                />)}

                {/*<div className="qr-code">*/}
                {/*  <img src="cid:${qr_code}" alt="QR Code"/>*/}
                {/*</div>*/}
                <Typography className="goodbye">Chúc bạn có thời gian sử dụng hệ thống hiệu quả.</Typography>
                <Typography className="regards">Trân trọng,</Typography>
                <Typography className="branch-name">{window.env.BRANCH_NAME}.</Typography>
            </Box>
        </Box>
    )
        ;
};

export default Qr;

const useStyles = makeStyles(theme => ({
    root: {
        padding: "16px",
        width: "100vw",
        height: "100vh",
        display: "flex",
    },

    content: {
        display: "flex",

        flexDirection: "column",
        height: "100%",
    },
    image: {
        marginTop: 50,
        display: "inline-block",
        maxWidth: "100%",
        width: 200,
        height: 200,
    },
    logoDiv: {
        width: "100%",
        backgroundImage: "linear-gradient(to bottom, #0bb3ff, #008fe8)",
        display: "flex",
        alignItems: "center",
        borderRadius: "8px 8px 0 0",
    },
    logo: {
        margin: "auto",
        width: 60,
        height: 60,
        borderRadius: "50%",
        border: "1px solid #cecece",
        overflow: "hidden",
        backgroundColor: "#fff",
        display: "flex",
        alignItems: "center",
        objectFit: "contain"
    }

}));
