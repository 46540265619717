import { useMutation } from "react-query";
import { ApiUtils, HumpUtils, StorageUtils, SystemUtils } from "utils";
import { ApiConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import { useTranslation } from "react-i18next";
import useInvalidateQueries from "hooks/common/useInvalidateQueries";
import qs from "qs";

export default function useDeleteUnit() {
  const { t: getLabel } = useTranslation();

  const { invalidateUnitQueries } = useInvalidateQueries();

  const deleteUnitMutation = useMutation({
    mutationFn: async data => {
      const branchId = StorageUtils.getStoreData(KeyConstant.KEY_BRANCH_ID);
      const payload = { branchId, unitId: data.unitId };
      const response = await ApiUtils.createApiWithToken(false).post(
        ApiConstant.POST_DELETE_UNIT,
        qs.stringify(HumpUtils.decamelizeKeys(payload)),
      );
      return HumpUtils.camelizeKeys(response.data);
    },
    onMutate: () => {
      SystemUtils.openCircleLoading();
    },
    onSettled: () => {
      SystemUtils.openCircleLoading(false);
    },
    onSuccess: async (response, { onClose }) => {
      if (response.status === ApiConstant.STT_OK) {
        await invalidateUnitQueries();
        await ApiUtils.apiCreateUserActivitiesLog(SystemConstant.LOGGER_ACTION_TYPES.DELETE_UNIT);
        SystemUtils.appendNotification(getLabel("TXT_DELETE_UNIT_SUCCESS"), "success");
        onClose();
      } else if (
        response.status === ApiConstant.STT_BAD_REQUEST &&
        parseInt(response.error) === SystemConstant.UNIT_ERROR.CONTAIN_DEPARTMENTS
      ) {
        SystemUtils.appendNotification(getLabel("TXT_UNIT_CONTAIN_DEPARTMENTS"), "error");
      } else if (response.status === ApiConstant.STT_NOT_FOUND) {
        SystemUtils.appendNotification(getLabel("TXT_NO_UNITS"), "error");
      } else {
        SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
      }
    },
    onError: error => {
      console.error(error);
      SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
    },
  });

  const deleteUnit = payload => {
    deleteUnitMutation.mutate(payload);
  };

  return { deleteUnit };
}
