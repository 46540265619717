import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { ApiConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import { ApiUtils, HumpUtils, StorageUtils, SystemUtils } from "utils";
import useInvalidateQueries from "hooks/common/useInvalidateQueries";
import qs from "qs";

export default function useActiveUser() {
  const { t: getLabel } = useTranslation();

  const { invalidateUserQueries } = useInvalidateQueries();

  const activeUserMutation = useMutation({
    mutationFn: async data => {
      const response = await ApiUtils.createApiWithToken(false).post(
        ApiConstant.ACTIVE_USER,
        qs.stringify(HumpUtils.decamelizeKeys(data)),
      );
      return response.data;
    },
    onMutate: () => {
      SystemUtils.openCircleLoading();
    },
    onSettled: () => {
      SystemUtils.openCircleLoading(false);
    },
    onSuccess: async (response, { onClose, active }) => {
      if (response.status === ApiConstant.STT_OK) {
        onClose();
        SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_SUCCESS), "success");
        await invalidateUserQueries();
        await ApiUtils.apiCreateUserActivitiesLog(
          active ? SystemConstant.LOGGER_ACTION_TYPES.ACTIVE_USER : SystemConstant.LOGGER_ACTION_TYPES.BLOCK_USER,
          StorageUtils.getStoreData(KeyConstant.KEY_BRANCH_ID),
        );
      } else {
        SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
      }
    },
    onError: error => {
      console.error(error);
      SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
    },
  });

  const activeUser = payload => {
    activeUserMutation.mutate(payload);
  };

  return { activeUser };
}
