import React, { memo } from "react";
import PropTypes from "prop-types";
import { SvgIcon } from "@mui/material";

const ReInviteIcon = ({ className, width, height }) => {
  return (
    <SvgIcon className={className} sx={{ width, height }} viewBox={`0 0 32 32`}>
      <path
        d="M17.1098 16.1056C18.0916 16.1056 18.8867 15.3104 18.8867 14.3287C18.8867 13.3469 18.0916 12.5518 17.1098 12.5518C16.1281 12.5518 15.3329 13.3469 15.3329 14.3287C15.3329 15.3104 16.1281 16.1056 17.1098 16.1056ZM13.1118 15.2171V14.3287C13.1118 14.0843 12.9119 13.8844 12.6675 13.8844C12.4232 13.8844 12.2233 14.0843 12.2233 14.3287V15.2171H11.3349C11.0905 15.2171 10.8906 15.417 10.8906 15.6613C10.8906 15.9057 11.0905 16.1056 11.3349 16.1056H12.2233V16.994C12.2233 17.2384 12.4232 17.4383 12.6675 17.4383C12.9119 17.4383 13.1118 17.2384 13.1118 16.994V16.1056H14.0002C14.2445 16.1056 14.4444 15.9057 14.4444 15.6613C14.4444 15.417 14.2445 15.2171 14.0002 15.2171H13.1118ZM17.1098 16.994C15.9237 16.994 13.556 17.5893 13.556 18.7709V19.2152C13.556 19.4595 13.7559 19.6594 14.0002 19.6594H20.2194C20.4637 19.6594 20.6636 19.4595 20.6636 19.2152V18.7709C20.6636 17.5893 18.2959 16.994 17.1098 16.994Z"
        fill="currentColor"
      />
      <path
        d="M16.0879 5C10.7371 5 6.33833 9.08887 5.90943 14.3022H4.83722C4.49801 14.3022 4.19232 14.5069 4.06313 14.8206C3.93393 15.1342 4.00677 15.4948 4.24757 15.7337L6.12268 17.5941C6.44908 17.918 6.97559 17.918 7.302 17.5941L9.17711 15.7337C9.41791 15.4948 9.49075 15.1342 9.36155 14.8206C9.23235 14.5069 8.92666 14.3022 8.58745 14.3022H7.5908C8.01467 10.0251 11.6518 6.6744 16.0879 6.6744C19.1794 6.6744 21.8856 8.30241 23.3846 10.7407C23.6267 11.1346 24.1423 11.2576 24.5362 11.0155C24.9302 10.7733 25.0532 10.2577 24.8109 9.8638C23.0179 6.94707 19.781 5 16.0879 5Z"
        fill="currentColor"
      />
      <path
        d="M25.8679 14.172C25.5419 13.8496 25.017 13.8496 24.691 14.172L22.8086 16.0324C22.5672 16.2711 22.4938 16.6319 22.6227 16.946C22.7518 17.26 23.0576 17.465 23.3972 17.465H24.4008C23.9752 21.7398 20.3255 25.0928 15.868 25.0928C12.762 25.0928 10.0446 23.4634 8.54 21.0253C8.29718 20.6318 7.78136 20.5097 7.38787 20.7526C6.9944 20.9953 6.87227 21.5112 7.11509 21.9047C8.9152 24.8216 12.1635 26.7672 15.868 26.7672C21.2349 26.7672 25.6518 22.6808 26.0823 17.465H27.1617C27.5013 17.465 27.8071 17.26 27.9362 16.946C28.0652 16.6319 27.9917 16.2711 27.7503 16.0324L25.8679 14.172Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default memo(ReInviteIcon);

ReInviteIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

ReInviteIcon.defaultProps = {
  width: 32,
  height: 32,
};
