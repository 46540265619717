import { useMutation } from "react-query";
import { ApiUtils, HumpUtils, StorageUtils, SystemUtils } from "utils";
import { ApiConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import { useTranslation } from "react-i18next";
import useInvalidateQueries from "hooks/common/useInvalidateQueries";
import qs from "qs";

export default function useEditUnit() {
  const { t: getLabel } = useTranslation();

  const { invalidateUnitQueries } = useInvalidateQueries();

  const editUnitMutation = useMutation({
    mutationFn: async data => {
      const branchId = StorageUtils.getStoreData(KeyConstant.KEY_BRANCH_ID);
      const payload = { branchId, unitName: data.unitName, unitId: data.unitId };
      const response = await ApiUtils.createApiWithToken(false).post(
        ApiConstant.POST_UPDATE_UNIT,
        qs.stringify(HumpUtils.decamelizeKeys(payload)),
      );
      return HumpUtils.camelizeKeys(response.data);
    },
    onMutate: () => {
      SystemUtils.openCircleLoading();
    },
    onSettled: () => {
      SystemUtils.openCircleLoading(false);
    },
    onSuccess: async (response, { onClose }) => {
      if (response.status === ApiConstant.STT_OK) {
        await invalidateUnitQueries();
        await ApiUtils.apiCreateUserActivitiesLog(SystemConstant.LOGGER_ACTION_TYPES.EDIT_UNIT);
        SystemUtils.appendNotification(getLabel("TXT_UPDATE_UNIT_SUCCESS"), "success");
        onClose();
      } else if (
        response.status === ApiConstant.STT_BAD_REQUEST &&
        parseInt(response.error) === SystemConstant.UNIT_ERROR.MISSING_PARAMETERS
      ) {
        SystemUtils.appendNotification(getLabel("TXT_MISSING_FORM_PARAMS"), "error");
      } else if (
        response.status === ApiConstant.STT_BAD_REQUEST &&
        parseInt(response.error) === SystemConstant.UNIT_ERROR.IN_WRONG_FORMAT
      ) {
        SystemUtils.appendNotification(getLabel("TXT_INVALID_UNIT_NAME"), "error");
      } else if (
        response.status === ApiConstant.STT_BAD_REQUEST &&
        parseInt(response.error) === SystemConstant.UNIT_ERROR.DUPLICATED
      ) {
        SystemUtils.appendNotification(getLabel("TXT_UNIT_DUPLICATED"), "error");
      } else {
        SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
      }
    },
    onError: error => {
      console.error(error);
      SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
    },
  });

  const editUnit = payload => {
    editUnitMutation.mutate(payload);
  };

  return { editUnit };
}
