import { ApiConstant, KeyConstant, LangConstant } from "const";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { ApiUtils, CommonUtils, HumpUtils, StorageUtils, SystemUtils } from "utils";
import localforage from "localforage";
import useInvalidateQueries from "hooks/common/useInvalidateQueries";

export default function useBranchList() {
  const { t: getLabel } = useTranslation();

  const { invalidateBranchQueries } = useInvalidateQueries();

  const branches = HumpUtils.camelizeKeys(StorageUtils.getStoreData(KeyConstant.KEY_BRANCH_LIST));

  const [pagination, setPagination] = useState({
    rowPerPageOptions: [10, 25, 50],
    count: branches.length,
    rowPerPage: 10,
    page: 0,
  });

  const [branchList, setBranchList] = useState([]);

  const [searchInput, setSearchInput] = useState("");

  const handleGetBranchAvatar = async branch => {
    try {
      if (!branch.attachmentId) return { ...branch, avatar: null };
      const localAvatarBlob = await localforage.getItem(
        KeyConstant.KEY_CACHED_BRANCH_AVATAR_BLOB + branch.attachmentId,
      );
      if (localAvatarBlob) return { ...branch, avatar: URL.createObjectURL(localAvatarBlob) };
      const avatar = await ApiUtils.apiGetAvatar(branch);
      await localforage.setItem(KeyConstant.KEY_CACHED_BRANCH_AVATAR_BLOB + branch.attachmentId, avatar);
      return { ...branch, avatar: avatar ? URL.createObjectURL(avatar) : null };
    } catch (error) {
      console.error(error);
      return { ...branch, avatar: null };
    }
  };

  const branchListQuery = useQuery({
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    queryKey: [ApiConstant.REACT_QUERY_KEYS.getBranchList],
    queryFn: async () => {
      SystemUtils.openCircleLoading();
      const branchListWithAvatar = [];
      const currentBranchList = HumpUtils.camelizeKeys(StorageUtils.getStoreData(KeyConstant.KEY_BRANCH_LIST));
      currentBranchList.forEach(branch => branchListWithAvatar.push(handleGetBranchAvatar(branch)));
      return Promise.all(branchListWithAvatar);
    },
    onSettled: () => {
      SystemUtils.openCircleLoading(false);
    },
    onError: error => {
      console.error(error);
      SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
    },
  });

  const handlePageChange = (_, newPage) => {
    setPagination(state => ({ ...state, page: newPage }));
  };

  const handleChangeRowPerPage = event => {
    setPagination(state => ({ ...state, rowPerPage: event.target.value }));
  };

  const handleChangeSearchInput = event => {
    setSearchInput(event.target.value);
  };

  const handleLabelDisplayedRows = ({ from, to, count }) => {
    return `${from}-${to} ${getLabel(LangConstant.TXT_OF)} ${
      count !== -1 ? count : `${getLabel(LangConstant.TXT_MORE_THAN)} ${to}`
    }`;
  };

  useEffect(() => {
    if (branchListQuery.data) {
      const outputBranchList = branchListQuery.data.filter(branch => {
        const branchName = branch.name.toLowerCase();
        const packageLowerCase = branch.package.toLowerCase();
        const packageIdLowerCase = branch.packageId.toLowerCase();
        const searchValueLower = searchInput.toLowerCase();
        return Boolean(
          branchName.includes(searchValueLower) ||
            packageLowerCase.includes(searchValueLower) ||
            packageIdLowerCase.includes(searchValueLower),
        );
      });

      const finalBranchList = CommonUtils.paginateFromArray(
        outputBranchList,
        pagination.page + 1,
        pagination.rowPerPage,
      );

      setBranchList(finalBranchList);
    }
  }, [branchListQuery.data, searchInput, pagination.page, pagination.rowPerPage]);

  return {
    branchList,
    pagination,
    searchInput,
    handlePageChange,
    handleChangeRowPerPage,
    handleChangeSearchInput,
    handleLabelDisplayedRows,
    invalidateBranchQueries,
  };
}
