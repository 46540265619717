import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, FormControlLabel, Input, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CheckCircleOutline, Password } from "@mui/icons-material";
import clsx from "clsx";
import { ApiConstant, KeyConstant, LangConstant } from "const";
import { CommonUtils, StorageUtils, SystemUtils } from "utils";
import { useChangePassword } from "hooks";
import { PaperHeader } from "components";

const ChangePasswordForm = () => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation([LangConstant.NS_COMMON, LangConstant.NS_LOGIN]);
  const { changePassword } = useChangePassword();
  const inputObjErrorMsg = getLabel(LangConstant.OBJ_ERROR_MSG, { returnObjects: true });

  const [isShowSuccessMsg, setIsShowSuccessMsg] = useState(false);
  const [passwordForm, setPasswordForm] = useState({ password: "", newPassword: "", passwordConfirmation: "" });
  const [errorMsg, setErrorMsg] = useState({ showPasswordErr: false, showPasswordConfirmationErr: false });

  const handleValidatePasswordForm = () => {
    if (passwordForm.newPassword !== passwordForm.passwordConfirmation) {
      setErrorMsg(currentState => ({ ...currentState, showPasswordConfirmationErr: true }));
      setPasswordForm(currentState => ({ ...currentState, passwordConfirmation: "" }));
      return false;
    }
    return true;
  };

  const handleSubmitPasswordForm = async event => {
    event.preventDefault();
    if (handleValidatePasswordForm()) {
      const username = StorageUtils.getStoreData(KeyConstant.KEY_USERNAME);
      const userId = StorageUtils.getStoreData(KeyConstant.KEY_USER_ID);
      const payload = { username, userId, oldPassword: passwordForm.password, newPassword: passwordForm.newPassword };
      const response = await changePassword(payload);
      if (response.status === ApiConstant.STT_OK) {
        setPasswordForm({ password: "", newPassword: "", passwordConfirmation: "" });
        setErrorMsg({ showPasswordErr: false, showPasswordConfirmationErr: false });
        setIsShowSuccessMsg(true);
      } else if (response.status === ApiConstant.STT_FORBIDDEN) {
        setPasswordForm(currentForm => ({ ...currentForm, password: "" }));
        setErrorMsg(currentState => ({ ...currentState, showPasswordErr: true }));
      } else {
        SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
      }
    }
  };

  const handleChangePasswordForm = event => {
    const id = event.target.id;
    const value = event.target.value;
    setPasswordForm(currentState => ({ ...currentState, [id]: value }));
  };

  const handleFocusPasswordInput = () => {
    setErrorMsg(currentState => ({ ...currentState, showPasswordErr: false }));
  };

  const handleFocusPasswordConfirmationInput = () => {
    setErrorMsg(currentState => ({ ...currentState, showPasswordConfirmationErr: false }));
  };

  const RightPaperHeader = () => {
    return (
      <Box className={classes.boxHeaderItem}>
        {isShowSuccessMsg && (
          <Fragment>
            <CheckCircleOutline color="success" className={classes.successIcon} />
            <Typography className={classes.statusTitle}>
              {getLabel(LangConstant.TXT_UPDATE_PASSWORD_SUCCESS)}
            </Typography>
          </Fragment>
        )}
      </Box>
    );
  };

  return (
    <Box className={clsx(classes.box, classes.firstBox)}>
      <PaperHeader
        icon={<Password className={classes.icon} />}
        title={getLabel(CommonUtils.getNSKey(LangConstant.NS_LOGIN, LangConstant.L_PASSWORD))}
        rightEl={<RightPaperHeader />}
      />
      <Box component="form" className={classes.rootForm} onSubmit={handleSubmitPasswordForm}>
        <FormControlLabel
          onChange={handleChangePasswordForm}
          label={getLabel(LangConstant.L_OLD_PASSWORD)}
          labelPlacement="top"
          classes={{
            root: classes.formControlLabelRoot,
            label: classes.formControlLabel,
          }}
          control={
            <Input
              id="password"
              type="password"
              autoComplete="on"
              value={passwordForm.password}
              onFocus={handleFocusPasswordInput}
              disableUnderline
              className={clsx(classes.formControl, {
                [classes.formControlError]: errorMsg.showPasswordErr,
              })}
              placeholder={
                errorMsg.showPasswordErr ? inputObjErrorMsg.wrongPassword : getLabel(LangConstant.P_OLD_PASSWORD)
              }
            />
          }
        />
        <FormControlLabel
          onChange={handleChangePasswordForm}
          label={getLabel(CommonUtils.getNSKey(LangConstant.NS_LOGIN, LangConstant.L_NEW_PASSWORD))}
          labelPlacement="top"
          classes={{
            root: classes.formControlLabelRoot,
            label: classes.formControlLabel,
          }}
          control={
            <Input
              id="newPassword"
              type="password"
              autoComplete="on"
              value={passwordForm.newPassword}
              className={clsx(classes.formControl)}
              disableUnderline
              placeholder={getLabel(CommonUtils.getNSKey(LangConstant.NS_LOGIN, LangConstant.P_PASSWORD))}
            />
          }
        />
        <FormControlLabel
          onChange={handleChangePasswordForm}
          label={getLabel(CommonUtils.getNSKey(LangConstant.NS_LOGIN, LangConstant.L_NEW_PASSWORD_CONFIRMATION))}
          labelPlacement="top"
          classes={{
            root: classes.formControlLabelRoot,
            label: classes.formControlLabel,
          }}
          control={
            <Input
              type="password"
              autoComplete="on"
              id="passwordConfirmation"
              onFocus={handleFocusPasswordConfirmationInput}
              value={passwordForm.passwordConfirmation}
              className={clsx(classes.formControl, {
                [classes.formControlError]: errorMsg.showPasswordConfirmationErr,
              })}
              placeholder={
                errorMsg.showPasswordConfirmationErr
                  ? inputObjErrorMsg.passwordConfirmationNotMatch
                  : getLabel(CommonUtils.getNSKey(LangConstant.NS_LOGIN, LangConstant.P_PASSWORD))
              }
              disableUnderline
            />
          }
        />
        <Box textAlign="center">
          <Button
            disabled={!(passwordForm.password && passwordForm.newPassword && passwordForm.passwordConfirmation)}
            className={classes.button}
            type="submit"
            variant="contained"
            color="primary"
          >
            {getLabel(LangConstant.L_UPDATE_PASSWORD_BUTTON)}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ChangePasswordForm;

const useStyles = makeStyles(theme => ({
  box: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 4,
    padding: "28px 36px",
    marginBottom: 16,
  },

  boxHeader: {
    borderBottom: "2px solid #E4E4E4",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
  },

  boxHeaderItem: {
    display: "flex",
    alignItems: "center",
  },

  statusTitle: {
    color: "#CBCACA",
    fontSize: 15,
    lineHeight: "20px",
    marginRight: 10,
  },

  firstBox: {
    marginTop: 25,
  },

  icon: {
    width: 36,
    height: 23,
  },

  rootForm: {
    width: "100%",
    margin: "20px auto 0",
  },

  formControlLabelRoot: {
    width: "100%",
    margin: "0 0 16px",
    alignItems: "flex-start",
  },

  formControlLabel: {
    marginBottom: 10,
    fontSize: 15,
    lineHeight: "20px",
  },

  formControl: {
    borderRadius: 4,
    backgroundColor: "#f5f5f5",
    fontSize: 15,
    padding: "8px 16px",
    width: "100%",
    "& ::placeholder": {
      color: "#CBCACA",
    },
  },

  formControlError: {
    border: "1px solid " + theme.palette.error.main,
    "& ::placeholder": {
      color: theme.palette.error.dark,
      opacity: 1,
    },
  },

  button: {
    minHeight: 46,
    minWidth: 345,
    borderRadius: 4,
    fontSize: 15,
    lineHeight: "20px",
  },

  successIcon: {
    marginRight: 8,
  },
}));
