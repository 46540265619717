import { useTranslation } from "react-i18next";
import qs from "qs";
import { ApiConstant, LangConstant, SystemConstant } from "const";
import { useMutation } from "react-query";
import { ApiUtils, HumpUtils, SystemUtils } from "utils";

export default function useCreateUnitChannel() {
  const { t: getLabel } = useTranslation();

  const createUnitChannelMutation = useMutation({
    mutationFn: async data => {
      const payload = data;
      const response = await ApiUtils.createApiWithToken(false).post(
        ApiConstant.POST_CREATE_UNIT_CHANNEL,
        qs.stringify(HumpUtils.decamelizeKeys(payload)),
      );
      return HumpUtils.camelizeKeys(response.data);
    },
    onMutate: () => {
      SystemUtils.openCircleLoading();
    },
    onSettled: () => {
      SystemUtils.openCircleLoading(false);
    },
    onSuccess: async (response, { onClose }) => {
      if (response.status === ApiConstant.STT_OK) {
        await ApiUtils.apiCreateUserActivitiesLog(SystemConstant.LOGGER_ACTION_TYPES.CREATE_UNIT_CHANNEL);
        SystemUtils.appendNotification(getLabel("TXT_CREATE_CHANNEL_SUCCESS"), "success");
        onClose();
      } else if (
        response.status === ApiConstant.STT_BAD_REQUEST &&
        parseInt(response.error) === SystemConstant.UNIT_ERROR.DUPLICATED
      ) {
        SystemUtils.appendNotification(getLabel("TXT_CHANNEL_DUPLICATED"), "error");
      } else {
        SystemUtils.appendNotification(getLabel("TXT_CREATE_CHANNEL_FAIL"), "error");
      }
    },
    onError: error => {
      console.error(error);
      SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
    },
  });

  const createUnitChannel = payload => {
    createUnitChannelMutation.mutate(payload);
  };

  return { createUnitChannel };
}
