import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, InputAdornment, TextField, InputLabel, MenuItem, Select, FormControl } from "@mui/material";
import { Add, Search } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { debounce } from "lodash";
import { ConfirmDialog } from "components";
import { LangConstant } from "const";
import { useDeletePosition } from "hooks";

const Heading = ({
  selectedPositions,
  units,
  departments,
  searchInput,
  unitId,
  departmentId,
  onClickCreatePosition,
  onChangeSearchInput,
  onChangeUnit,
  onChangeDepartment,
  onDeletePositionSuccess,
}) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();
  const { deletePosition } = useDeletePosition();

  const [isOpenDeleteConfirmDialog, setIsOpenDeleteConfirmDialog] = useState(false);

  const handleChangeSearchInput = debounce(onChangeSearchInput, 500);

  const handleToggleOpenDialog = () => {
    setIsOpenDeleteConfirmDialog(!isOpenDeleteConfirmDialog);
  };

  const handleDeletePositions = () => {
    const selectedPositionIdList = selectedPositions.map(position => position.positionId);
    deletePosition({
      positionIds: selectedPositionIdList,
      onClose: handleToggleOpenDialog,
      onSuccess: () => onDeletePositionSuccess(null, 1),
    });
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.filterContainer}>
        <TextField
          className={classes.textField}
          label={getLabel("P_SEARCH_POSITION")}
          variant="outlined"
          size="small"
          onChange={handleChangeSearchInput}
          defaultValue={searchInput}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search fontSize="small" />
              </InputAdornment>
            ),
          }}
        />
        <FormControl size="small" sx={{ width: 200 }}>
          <InputLabel shrink id="unit-label">
            {getLabel("TXT_UNIT")}
          </InputLabel>
          <Select
            value={unitId}
            name="unitId"
            labelId="unit-label"
            id="unit-simple-select"
            label={getLabel("TXT_UNIT")}
            onChange={onChangeUnit}
            notched
            displayEmpty
          >
            <MenuItem value="">{getLabel("TXT_CHOOSE_UNIT")}</MenuItem>
            {units.map(unit => (
              <MenuItem value={unit.unitId} key={unit.unitId}>
                {unit.unitName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl size="small" sx={{ width: 200 }}>
          <InputLabel shrink id="department-label">
            {getLabel("TXT_DEPARTMENT")}
          </InputLabel>
          <Select
            value={departmentId}
            name="departmentId"
            labelId="department-label"
            id="department-simple-select"
            label={getLabel("TXT_DEPARTMENT")}
            onChange={onChangeDepartment}
            disabled={!Boolean(unitId)}
            notched
            displayEmpty
          >
            <MenuItem value="">{getLabel("TXT_CHOOSE_DEPARTMENT")}</MenuItem>
            {departments.map(department => (
              <MenuItem value={department.departmentId} key={department.departmentId}>
                {department.departmentName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box className={classes.btnContainer}>
        <Button
          variant="contained"
          color="error"
          className={classes.button}
          disabled={selectedPositions.length === 0}
          onClick={handleToggleOpenDialog}
        >
          {getLabel("L_DELETE")}
        </Button>
        <Button
          variant="contained"
          className={classes.button}
          startIcon={<Add fontSize="small" />}
          onClick={onClickCreatePosition}
        >
          {getLabel("L_CREATE")}
        </Button>

        <ConfirmDialog
          open={isOpenDeleteConfirmDialog}
          title={getLabel(LangConstant.TXT_CONFIRM_DELETE_POSITION)}
          onDeny={handleToggleOpenDialog}
          onConfirm={handleDeletePositions}
        />
      </Box>
    </Box>
  );
};

export default Heading;

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: 8,
  },

  btnContainer: {
    display: "flex",
    gap: 8,
  },

  filterContainer: {
    display: "flex",
    gap: 8,
  },

  textField: {
    width: "400px",
  },

  button: {
    height: "40px",
  },
}));

