import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { ApiConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import { ApiUtils, HumpUtils, StorageUtils, SystemUtils } from "utils";
import useInvalidateQueries from "hooks/common/useInvalidateQueries";
import qs from "qs";

export default function useDeleteBranch() {
  const { t: getLabel } = useTranslation();

  const { invalidateBranchQueries } = useInvalidateQueries();

  const deleteBranchMutation = useMutation({
    mutationFn: async data => {
      data = HumpUtils.decamelizeKeys(data);
      const response = await ApiUtils.createApiWithToken(true, ApiUtils.API_CONFIG, data.token).post(
        ApiConstant.DELETE_BRANCH,
        qs.stringify({ state: data.state }),
      );
      return response.data;
    },
    onMutate: () => {
      SystemUtils.openCircleLoading();
    },
    onSettled: () => {
      SystemUtils.openCircleLoading(false);
    },
    onSuccess: async (response, data) => {
      if (response.status === ApiConstant.STT_OK) {
        const isRemember = StorageUtils.getStoreData(KeyConstant.KEY_REMEMBER_KEY);
        const branchList = StorageUtils.getStoreData(KeyConstant.KEY_BRANCH_LIST);
        const newBranchList = branchList.filter(item => item.id !== data.id);
        StorageUtils.storeData(KeyConstant.KEY_BRANCH_LIST, newBranchList, isRemember);
        await invalidateBranchQueries();
        await ApiUtils.apiCreateUserActivitiesLog(SystemConstant.LOGGER_ACTION_TYPES.DELETE_BRANCH);
      } else {
        SystemUtils.appendNotification(getLabel(LangConstant.TXT_DELETE_FAIL), "error");
      }
    },
    onError: error => {
      console.error(error);
      SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
    },
  });

  const deleteBranch = payload => {
    deleteBranchMutation.mutate(payload);
  };

  return { deleteBranch };
}
