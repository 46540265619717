import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Translate } from "@mui/icons-material";
import clsx from "clsx";
import { AppConstant, KeyConstant, LangConstant } from "const";
import { EventBusUtils, StorageUtils } from "utils";
import { PaperHeader } from "components";

const ChangeLanguageForm = () => {
  const classes = useStyles();
  const { t: getLabel, i18n } = useTranslation(LangConstant.NS_COMMON);

  const languages = LangConstant.ARR_LANGUAGES;

  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const [lang, setLang] = useState(currentLanguage);

  const handleChangeRadioInput = event => {
    setLang(event.target.value);
  };

  const handleSubmitLanguageForm = event => {
    event.preventDefault();
    StorageUtils.storePermanenceData(KeyConstant.KEY_LANGUAGE, lang);
    EventBusUtils.emit(AppConstant.EVENT_BUS_EVENTS.changeLocale);
  };

  useEffect(() => {
    setLang(i18n.language);
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  return (
    <Box className={classes.box}>
      <PaperHeader icon={<Translate className={classes.icon} />} title={getLabel(LangConstant.L_LANGUAGE)} />
      <form onSubmit={handleSubmitLanguageForm}>
        <RadioGroup name="language">
          {languages.map(language => (
            <FormControlLabel
              control={<Radio />}
              key={language.code}
              value={language.code}
              label={getLabel(language.label)}
              checked={language.code === lang}
              onChange={handleChangeRadioInput}
              classes={{
                label: clsx(classes.formControlLabel, classes.radioLabel),
              }}
            />
          ))}
        </RadioGroup>
        <Box textAlign="center">
          <Button
            disabled={currentLanguage === lang}
            className={classes.button}
            size="large"
            variant="contained"
            disableElevation
            type="submit"
          >
            {getLabel(LangConstant.L_SAVE_BUTTON)}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default ChangeLanguageForm;

const useStyles = makeStyles(theme => ({
  box: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 4,
    padding: "28px 36px",
    marginBottom: 16,
  },

  boxHeader: {
    borderBottom: "2px solid #E4E4E4",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
  },

  boxHeaderItem: {
    display: "flex",
    alignItems: "center",
    paddingBottom: 23,
  },

  statusTitle: {
    color: "#CBCACA",
    fontSize: 15,
    lineHeight: "20px",
    marginRight: 10,
  },

  firstBox: {
    marginTop: 45,
  },

  icon: {
    width: 36,
    height: 23,
  },

  rootForm: {
    width: "100%",
    margin: "20px auto 0",
  },

  formControlLabelRoot: {
    width: "100%",
    margin: "0 0 16px",
    alignItems: "flex-start",
  },

  formControlLabel: {
    marginBottom: 10,
    fontSize: 15,
    lineHeight: "20px",
  },

  radioLabel: {
    marginBottom: 0,
  },

  formControl: {
    borderRadius: 4,
    backgroundColor: "#f5f5f5",
    fontSize: 15,
    padding: "8px 16px",
    width: "100%",
    "& ::placeholder": {
      color: "#CBCACA",
    },
  },

  button: {
    minHeight: 46,
    minWidth: 345,
    borderRadius: 4,
    fontSize: 15,
    lineHeight: "20px",
  },
}));
