import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Check, ContentCopy } from "@mui/icons-material";
import theme from "theme/material";
import { LangConstant } from "const";

const GeneratedPassword = ({ isOpen, password, onClose }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();

  const langObj = getLabel(LangConstant.OBJ_RANDOM_ADMIN_PASSWORD, { returnObjects: true });

  const [isCopied, setIsCopied] = useState(false);

  const handleClose = () => {
    onClose();
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(password);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  };

  useEffect(() => {
    setIsCopied(false);
  }, [password]);

  return (
    <Dialog open={isOpen} classes={{ paper: classes.paperDialog }} fullWidth>
      <DialogTitle className={classes.confirmTitle}>
        <DialogContentText className={classes.titleText}>{langObj.title}</DialogContentText>
      </DialogTitle>
      <DialogContent sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "16px" }}>
        <Typography variant="inherit">{getLabel(langObj.content, { password })}</Typography>
        <IconButton size="small" title={langObj.copy} disabled={isCopied} onClick={handleCopy}>
          {isCopied ? (
            <Check fontSize="small" htmlColor={theme.palette.primary.main} />
          ) : (
            <ContentCopy fontSize="small" />
          )}
        </IconButton>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          {langObj.close}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GeneratedPassword;

const useStyles = makeStyles(() => ({
  paperDialog: {
    backgroundColor: "#FFFFFF",
  },

  confirmTitle: {
    marginTop: 10,
  },

  titleText: {
    color: "#000000",
  },
}));
