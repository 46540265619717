import { AppConstant } from "const";

export const appendNotification = (message, variant = "message") => {
  const event = new CustomEvent(AppConstant.CUSTOM_EVENTS.openSnackbar, { detail: { message, variant } });
  window.dispatchEvent(event);
};

export const appendNotifications = notifications => {
  const event = new CustomEvent(AppConstant.CUSTOM_EVENTS.openMultipleSnackbar, { detail: notifications });
  window.dispatchEvent(event);
};

export const openCircleLoading = (open = true) => {
  const event = new CustomEvent(AppConstant.CUSTOM_EVENTS.openCircleLoading, { detail: open });
  window.dispatchEvent(event);
};
