import React from "react";
import { makeStyles } from "@mui/styles";
import { Container, Box, IconButton, Typography } from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import { AuthUtils } from "utils";
import { useFirstLogin } from "hooks";
import { SetAvatarForm, SetPasswordForm } from "./components";

const FirstLogin = () => {
  const classes = useStyles();

  const {
    AVATAR_STEP,
    step,
    form,
    formMessage,
    handleChangePasswordForm,
    handleSubmitPasswordForm,
    handleChangeAvatarInput,
    handleSubmitImageForm,
    handleBackToPasswordForm,
  } = useFirstLogin();

  const handleClickBack = () => {
    if (step === AVATAR_STEP) handleBackToPasswordForm();
    else AuthUtils.handleLogout();
  };

  const isEnablePasswordSubmitButton = form.password && form.passwordConfirmation && !Boolean(formMessage.password);

  return (
    <Container maxWidth={false} classes={{ root: classes.rootContainer }}>
      <Box className={classes.rootBox}>
        <Box sx={{ display: "inline-flex", alignItems: "center", cursor: "pointer" }} onClick={handleClickBack}>
          <IconButton size="small" disableFocusRipple disableRipple disableTouchRipple>
            <ChevronLeft fontSize="small" />
          </IconButton>
          <Typography variant="caption">Quay lại</Typography>
        </Box>
        {step === AVATAR_STEP ? (
          <SetAvatarForm
            avatar={form}
            avatarErrorMsg={formMessage.avatar}
            onSubmitImageForm={handleSubmitImageForm}
            onAvatarInputChange={handleChangeAvatarInput}
          />
        ) : (
          <SetPasswordForm
            form={form}
            passwordValidated={isEnablePasswordSubmitButton}
            passwordErrorMsg={formMessage.password}
            onSubmitPasswordForm={handleSubmitPasswordForm}
            onPasswordFormChange={handleChangePasswordForm}
          />
        )}
      </Box>
    </Container>
  );
};

export default FirstLogin;

const useStyles = makeStyles(theme => ({
  rootContainer: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#f1f1f1",
  },

  rootBox: {
    maxWidth: "100vw",
    width: 830,
    backgroundColor: theme.palette.common.white,
    borderRadius: 4,
    padding: "8px",
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
  },
}));
