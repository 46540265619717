import { useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { TuneOutlined } from "@mui/icons-material";
import { Box, Button, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import clsx from "clsx";
import { KeyConstant, LangConstant } from "const";
import { BranchUtils, StorageUtils } from "utils";
import { useUpdateBranch } from "hooks";
import { PaperHeader } from "components";

const ChangeMode = () => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();
  const { updateBranchInfo } = useUpdateBranch();

  const branchModeArr = LangConstant.BRANCH_MODE;
  const loginModeArr = LangConstant.LOGIN_MODE;

  const currentBranchMode = StorageUtils.getStoreData(KeyConstant.KEY_BRANCH_MODE);
  const currentLoginMode = StorageUtils.getStoreData(KeyConstant.KEY_LOGIN_MODE);

  const [branchMode, setBranchMode] = useState(currentBranchMode);
  const [loginMode, setLoginMode] = useState(currentLoginMode);

  const handleSubmit = event => {
    event.preventDefault();
    const currentBranchDetails = BranchUtils.getCurrentBranch();
    const payload = { ...currentBranchDetails, loginMode, branchMode, isUpdateBranchConfigs: true };
    updateBranchInfo(payload);
  };

  const handleChangeBranchMode = event => {
    setBranchMode(event.target.value);
  };

  const handleChangeLoginMode = event => {
    setLoginMode(event.target.value);
  };

  return (
    <Box className={classes.box}>
      <PaperHeader icon={<TuneOutlined className={classes.icon} />} title={getLabel(LangConstant.TXT_MODE)} />
      <form onSubmit={handleSubmit}>
        <Typography className={classes.modeTitle}>{getLabel(LangConstant.TXT_BRANCH_MODE)}</Typography>
        <RadioGroup name="branchMode" className={classes.valueGroup} value={branchMode}>
          {branchModeArr.map(mode => (
            <FormControlLabel
              control={<Radio />}
              key={mode.code}
              value={mode.code}
              label={getLabel(mode.label)}
              onChange={handleChangeBranchMode}
              classes={{
                label: clsx(classes.formControlLabel, classes.radioLabel),
              }}
            />
          ))}
        </RadioGroup>
        <Typography className={classes.modeTitle}>{getLabel(LangConstant.TXT_LOGIN_MODE)}</Typography>
        <RadioGroup name="loginMode" value={loginMode}>
          {loginModeArr.map(mode => (
            <FormControlLabel
              control={<Radio />}
              key={mode.code}
              value={mode.code}
              label={getLabel(mode.label)}
              onChange={handleChangeLoginMode}
              classes={{
                label: clsx(classes.formControlLabel, classes.radioLabel),
              }}
            />
          ))}
        </RadioGroup>
        <Box textAlign="center">
          <Button
            disabled={branchMode === currentBranchMode && loginMode === currentLoginMode}
            className={classes.button}
            size="large"
            variant="contained"
            disableElevation
            type="submit"
          >
            {getLabel(LangConstant.L_SAVE_BUTTON)}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default ChangeMode;

const useStyles = makeStyles(theme => ({
  box: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 4,
    padding: "28px 36px",
    marginBottom: 16,
  },

  boxHeader: {
    borderBottom: "2px solid #E4E4E4",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
  },

  boxHeaderItem: {
    display: "flex",
    alignItems: "center",
    paddingBottom: 23,
  },

  statusTitle: {
    color: "#CBCACA",
    fontSize: 15,
    lineHeight: "20px",
    marginRight: 10,
  },

  firstBox: {
    marginTop: 45,
  },

  icon: {
    width: 36,
    height: 23,
  },

  rootForm: {
    width: "100%",
    margin: "20px auto 0",
  },

  formControlLabelRoot: {
    width: "100%",
    margin: "0 0 16px",
    alignItems: "flex-start",
  },

  formControlLabel: {
    marginBottom: 10,
    fontSize: 15,
    lineHeight: "20px",
  },

  radioLabel: {
    marginBottom: 0,
  },

  formControl: {
    borderRadius: 4,
    backgroundColor: "#f5f5f5",
    fontSize: 15,
    padding: "8px 16px",
    width: "100%",
    "& ::placeholder": {
      color: "#CBCACA",
    },
  },

  button: {
    minHeight: 46,
    minWidth: 345,
    borderRadius: 4,
    fontSize: 15,
    lineHeight: "20px",
  },

  modeTitle: {
    fontSize: 16,
    marginTop: 16,
    marginBottom: 8,
    fontWeight: 600,
  },

  valueGroup: {
    marginBottom: 20,
  },
}));
