import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import clsx from "clsx";
import { LangConstant, SystemConstant } from "const";
import { AuthUtils } from "utils";

const OTPAction = ({ disabled, otpType, onLogout, onResendOtp, onChangeOtpType, startCountDownNumber }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_COMMON);

  const OBJECT_LANG = getLabel(LangConstant.OBJ_SIDEBAR_TITLE, { returnObjects: true });

  const [isEnableResend, setIsEnableResend] = useState(false);
  const [currentOtpType, setCurrentOtpType] = useState(otpType || SystemConstant.OTP_TYPE.smartOtp);
  const [countdownResend, setCountdownResend] = useState(getCurrentCountdownTime());

  const onChangeTypeOfOTP = () => {
    const newOtpType =
      currentOtpType === SystemConstant.OTP_TYPE.smartOtp
        ? SystemConstant.OTP_TYPE.smsOtp
        : SystemConstant.OTP_TYPE.smartOtp;

    resetOtpConfigs();
    setCurrentOtpType(newOtpType);
    setCountdownResend(SystemConstant.SMART_OTP_CONFIGS.expireAfter / 1000);

    if (onChangeOtpType) onChangeOtpType(newOtpType);
  };

  const onResend = () => {
    if (onResendOtp) onResendOtp(currentOtpType);
  };

  const onStartCountDown = () => {
    resendOtpConfigs();
    setCountdownResend(SystemConstant.SMART_OTP_CONFIGS.expireAfter / 1000);
  };

  useEffect(() => {
    let resendTimeout = null;
    if (countdownResend) {
      setIsEnableResend(false);
      resendTimeout = setTimeout(() => {
        if (countdownResend > 0) setCountdownResend(countdownResend - 1);
      }, 1000);
    } else {
      setIsEnableResend(true);
    }

    return () => {
      if (resendTimeout) clearTimeout(resendTimeout);
    };
  }, [countdownResend]);

  useEffect(() => {
    if (otpType !== currentOtpType) setCurrentOtpType(otpType);
  }, [otpType]);

  useEffect(() => {
    if (startCountDownNumber > 0) {
      onStartCountDown();
    }
  }, [startCountDownNumber]);

  const otpLabelBtn =
    currentOtpType === SystemConstant.OTP_TYPE.smartOtp
      ? getLabel(LangConstant.TXT_CHANGE_SMS_OTP)
      : getLabel(LangConstant.TXT_CHANGE_SMART_OTP);

  const countdownText = getLabel(LangConstant.FM_COUNTDOWN_OTP, {
    time: getBelowTenNumber(countdownResend),
  });

  return (
    <Box>
      <Box className={classes.btnContainer}>
        {isEnableResend ? (
          <Button
            variant="text"
            disabled={disabled}
            className={clsx(classes.resendButton, "regular-md-txt")}
            onClick={onResend}
          >
            {getLabel(LangConstant.TXT_RESEND_OTP)}
          </Button>
        ) : disabled ? (
          <Button
            variant="text"
            disabled={disabled}
            className={clsx(classes.resendButton, "regular-md-txt")}
            onClick={onResend}
          >
            {getLabel(LangConstant.TXT_RESEND_OTP)}
          </Button>
        ) : (
          <Typography variant="subtitle">{countdownText}</Typography>
        )}

        {onLogout && (
          <Button variant="text" className={clsx("regular-md-txt", classes.resendButton)} onClick={onLogout}>
            {getLabel(OBJECT_LANG.logout)}
          </Button>
        )}
      </Box>

      {onChangeOtpType && (
        <Button variant="text" className={clsx("regular-md-txt", classes.resendButton)} onClick={onChangeTypeOfOTP}>
          {otpLabelBtn}
        </Button>
      )}
    </Box>
  );
};

OTPAction.propTypes = {
  otpType: PropTypes.number,

  onChangeOtpType: PropTypes.func,
  onLogout: PropTypes.func,
  onResendOtp: PropTypes.func,
};

export default OTPAction;

const getCurrentCountdownTime = () => {
  const SMART_OTP_CONFIGS = AuthUtils.getCurrentSmartOtpConfigs();
  if (SMART_OTP_CONFIGS) {
    const startTime = SMART_OTP_CONFIGS.otpSentAt;
    const endTime = startTime + SystemConstant.SMART_OTP_CONFIGS.expireAfter;
    return endTime - Date.now() <= 0 ? 0 : Math.ceil((endTime - Date.now()) / 1000);
  }

  return 0;
};

const resetOtpConfigs = () => {
  AuthUtils.setCurrentSmartOtpConfigs({
    code: null,
    retry: SystemConstant.SMART_OTP_CONFIGS.maxRetry,
    otpSentAt: Date.now(),
  });
};

const resendOtpConfigs = () => {
  const SMART_OTP_CONFIGS = AuthUtils.getCurrentSmartOtpConfigs();
  if (SMART_OTP_CONFIGS) AuthUtils.setCurrentSmartOtpConfigs({ ...SMART_OTP_CONFIGS, otpSentAt: Date.now() });
};

const getBelowTenNumber = number => {
  if (!number) return "00";
  if (number < 10) number = "0" + number;
  return number;
};

const useStyles = makeStyles({
  btnContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 16,
  },

  resendButton: {
    textTransform: "none",
    marginRight: 5,
    padding: "4px 8px",
  },
});
