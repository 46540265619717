import React, { memo } from "react";
import PropTypes from "prop-types";
import { SvgIcon } from "@mui/material";

const CompanyTagNameIcon = ({ className, width, height }) => {
  return (
    <SvgIcon className={className} width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      <path
        d="M20 7H15V4C15 2.9 14.1 2 13 2H11C9.9 2 9 2.9 9 4V7H4C2.9 7 2 7.9 2 9V20C2 21.1 2.9 22 4 22H20C21.1 22 22 21.1 22 20V9C22 7.9 21.1 7 20 7ZM9 12C9.83 12 10.5 12.67 10.5 13.5C10.5 14.33 9.83 15 9 15C8.17 15 7.5 14.33 7.5 13.5C7.5 12.67 8.17 12 9 12ZM12 18H6V17.25C6 16.25 8 15.75 9 15.75C10 15.75 12 16.25 12 17.25V18ZM13 9H11V4H13V9ZM18 16.5H14V15H18V16.5ZM18 13.5H14V12H18V13.5Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default memo(CompanyTagNameIcon);

CompanyTagNameIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

CompanyTagNameIcon.defaultProps = {
  width: 24,
  height: 24,
};
