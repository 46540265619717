import React, { useEffect, useState } from "react";
import { Search } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LangConstant, SystemConstant } from "const";
import { useTranslation } from "react-i18next";
import { useAddMember, useDeleteMember } from "hooks";

const AddMemberDialog = ({
  open,
  selectedDepartment,
  unitList,
  totalPages,
  currentPageUsers,
  page,
  departmentUserList,
  onFilter,
  onClose,
}) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();
  const userDetail = getLabel(LangConstant.OBJ_USER_DETAIL, { returnObjects: true });

  const { addMemberAsync } = useAddMember();
  const { deleteMemberAsync } = useDeleteMember();

  const [checkedList, setCheckedList] = useState([]);
  const [defaultUserList, setDefaultUserList] = useState([]);
  const [selectedUnitId, setSelectedUnitId] = useState(selectAllUnitValue);

  const handleChangeSearch = event => {
    onFilter({
      searchValue: event.target.value,
      page: SystemConstant.ACTIVE_PAGINATION,
    });
  };

  const handleChangeUnit = event => {
    setSelectedUnitId(event.target.value);
    onFilter({
      selectedUnitId: event.target.value,
      page: SystemConstant.ACTIVE_PAGINATION,
    });
  };

  const handleChangePage = (_, page) => {
    onFilter({ page: page });
  };

  const handleChangeCheckedList = userId => {
    if (checkedList.includes(userId)) {
      setCheckedList(currentState => currentState.filter(id => id !== userId));
    } else {
      setCheckedList(currentState => [...currentState, userId]);
    }
  };

  const handleAddMemberList = async () => {
    const addUserList = checkedList.filter(userId => !defaultUserList.includes(userId));
    if (addUserList.length > 0) {
      await addMemberAsync({
        departmentId: selectedDepartment.departmentId,
        userIds: addUserList,
        unitId: selectedDepartment.unitId,
        onClose: onClose,
      });
    }

    const removeUserList = defaultUserList.filter(userId => !checkedList.includes(userId));
    if (removeUserList.length > 0) {
      await deleteMemberAsync({
        departmentId: selectedDepartment.departmentId,
        userIds: removeUserList,
        unitId: selectedDepartment.unitId,
        onClose: onClose,
      });
    }
  };

  useEffect(() => {
    if (open) {
      const departmentUserIdList = departmentUserList.map(user => user.id);
      setCheckedList(departmentUserIdList);
      setDefaultUserList(departmentUserIdList);
      onFilter({
        selectedUnitId: selectAllUnitValue,
      });
    }
  }, [open]);

  return (
    <Dialog open={open} fullWidth classes={{ paper: classes.component }} maxWidth="xl">
      <DialogTitle className={classes.title}>
        <Typography className={classes.titleName}>{getLabel(LangConstant.TXT_ADD_MEMBER_DIALOG)}</Typography>
      </DialogTitle>
      <DialogContent>
        <Box className={classes.memberHeader}>
          <TextField
            name="name"
            className={classes.textField}
            size="small"
            onChange={handleChangeSearch}
            placeholder={getLabel(LangConstant.P_SEARCH_USER_BY_NAME_EMAIL)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          <FormControl fullWidth size="small" className={classes.selectField}>
            <InputLabel id="selectUnit">{getLabel(LangConstant.L_SELECT_UNIT)}</InputLabel>
            <Select
              value={selectedUnitId}
              size="small"
              onChange={handleChangeUnit}
              notched
              displayEmpty
              labelId="selectUnit"
              label={getLabel(LangConstant.L_SELECT_UNIT)}
            >
              <MenuItem value={selectAllUnitValue}>{getLabel(LangConstant.L_ALL)}</MenuItem>
              {unitList.length > 0 &&
                unitList.map((unit, index) => (
                  <MenuItem key={index} value={unit.unitId}>
                    {unit.unitName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table stickyHeader className={classes.boxTable}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "10%" }}></TableCell>
                <TableCell sx={{ width: "20%", fontWeight: "bold" }}>{userDetail.userName}</TableCell>
                <TableCell sx={{ width: "20%", fontWeight: "bold" }}>{userDetail.email}</TableCell>
                <TableCell sx={{ width: "25%", fontWeight: "bold" }}>{userDetail.unit}</TableCell>
                <TableCell sx={{ width: "25%", fontWeight: "bold" }}>{userDetail.department}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentPageUsers && currentPageUsers.length > 0 ? (
                currentPageUsers.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Checkbox
                        checked={checkedList.includes(row.id)}
                        onChange={() => handleChangeCheckedList(row.id)}
                      />
                    </TableCell>
                    <TableCell className={classes.textCell} title={row.name}>
                      {row.name}
                    </TableCell>
                    <TableCell className={classes.textCell} title={row.mail}>
                      {row.mail}
                    </TableCell>
                    <TableCell className={classes.textCell} title={row.unit}>
                      {row.unit}
                    </TableCell>
                    <TableCell className={classes.textCell} title={row.department}>
                      {row.department}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    <small>
                      <i>{getLabel(LangConstant.TXT_NOT_FOUND)}</i>
                    </small>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {totalPages > 1 && (
          <Box className={classes.pagination}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handleChangePage}
              color="primary"
              variant="outlined"
              shape="rounded"
              showFirstButton
              showLastButton
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="error" className={classes.buttonCancel} onClick={onClose}>
          {getLabel(LangConstant.L_CANCEL)}
        </Button>
        <Button variant="contained" className={classes.buttonCancel} onClick={handleAddMemberList}>
          {getLabel(LangConstant.L_CONFIRM_BUTTON)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddMemberDialog;

export const selectAllUnitValue = "*";

const useStyles = makeStyles(() => ({
  component: {
    backgroundColor: "#FFFFFF",
    height: "750px",
  },

  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "8px 0",
  },

  titleName: {
    fontSize: "24px",
    fontWeight: 700,
  },

  memberHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  addMemberButton: {
    minWidth: "fit-content",
    marginLeft: "24px",
  },

  textField: {
    margin: "10px 10px 10px 0px",
    width: "50%",
  },

  selectField: {
    margin: "10px 0px 10px 10px",
    width: "50%",
  },

  tableContainer: {
    marginTop: "32px",
    width: "auto",
    height: "400px",
  },

  buttonCancel: {
    width: "150px",
    margin: "0px 16px 16px",
  },

  pagination: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: "32px",
  },

  boxTable: {
    whiteSpace: "nowrap",
    tableLayout: "fixed",
  },

  textCell: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));
