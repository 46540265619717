import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Checkbox,
  IconButton,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { LangConstant } from "const";
import { DatetimeUtils } from "utils";

const Datatable = ({ positions, selectedPositions, pagination, onChangePage, onSelectPosition, onClickEdit }) => {
  const { t: getLabel } = useTranslation();
  const classes = useStyles();
  const selectedPositionIds = selectedPositions.map(position => position.positionId);

  return (
    <Fragment>
      <TableContainer sx={{ my: 4, height: 500 }}>
        <Table stickyHeader sx={{ minWidth: 650 }} className={classes.boxTable}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "10%" }}></TableCell>
              <TableCell sx={{ width: "20%", fontWeight: "bold" }}>{getLabel("TXT_POSITION_NAME")}</TableCell>
              <TableCell sx={{ width: "20%", fontWeight: "bold" }}>{getLabel("TXT_UNIT_NAME")}</TableCell>
              <TableCell sx={{ width: "20%", fontWeight: "bold" }}>{getLabel("TXT_DEPARTMENT_NAME")}</TableCell>
              <TableCell sx={{ width: "20%", fontWeight: "bold" }}>{getLabel("TXT_CREATED_AT")}</TableCell>
              <TableCell sx={{ width: "10%" }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {positions.length > 0 ? (
              positions.map((position, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Checkbox
                      checked={selectedPositionIds.includes(position.positionId)}
                      size="small"
                      onChange={() => onSelectPosition(position)}
                    />
                  </TableCell>
                  <TableCell className={classes.name} title={position.positionName}>
                    {position.positionName}
                  </TableCell>
                  <TableCell className={classes.name} title={position.unitName}>
                    {position.unitName}
                  </TableCell>
                  <TableCell className={classes.name} title={position.departmentName}>
                    {position.departmentName || getLabel(LangConstant.TXT_NOT_FOUND)}
                  </TableCell>
                  <TableCell>{DatetimeUtils.convertMillisecondsToDatetime(position.created)}</TableCell>
                  <TableCell>
                    <IconButton size="small" onClick={() => onClickEdit(position)}>
                      <Edit fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <small>
                    <i>{getLabel(LangConstant.TXT_NOT_FOUND)}</i>
                  </small>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination.total > 1 && (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", margin: "24px 0" }}>
          <Pagination
            count={pagination.total}
            onChange={onChangePage}
            page={pagination.current}
            className={classes.pagination}
            color="primary"
            variant="outlined"
            shape="rounded"
            showFirstButton
            showLastButton
          />
        </Box>
      )}
    </Fragment>
  );
};

export default Datatable;

const useStyles = makeStyles(() => ({
  pagination: {
    display: "flex",
    justifyContent: "center",
  },

  boxTable: {
    whiteSpace: "nowrap",
    tableLayout: "fixed",
  },

  name: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

