import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { ApiUtils, EventBusUtils, HumpUtils, StorageUtils, SystemUtils } from "utils";
import { ApiConstant, AppConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import useInvalidateQueries from "hooks/common/useInvalidateQueries";
import qs from "qs";

export default function useDeleteDepartment() {
  const { t: getLabel } = useTranslation();
  const { invalidateUnitQueries, invalidateDepartmentQueries } = useInvalidateQueries();

  const deleteDepartmentMutation = useMutation({
    mutationFn: async data => {
      const branchId = StorageUtils.getStoreData(KeyConstant.KEY_BRANCH_ID);
      const payload = { branchId, departmentId: data.departmentId };
      const response = await ApiUtils.createApiWithToken(false).post(
        ApiConstant.POST_DELETE_DEPARTMENT,
        qs.stringify(HumpUtils.decamelizeKeys(payload)),
      );
      return HumpUtils.camelizeKeys(response.data);
    },
    onMutate: () => {
      SystemUtils.openCircleLoading();
    },
    onSettled: () => {
      SystemUtils.openCircleLoading(false);
    },
    onSuccess: async (response, { onClose, unitId }) => {
      if (response.status === ApiConstant.STT_OK) {
        await invalidateUnitQueries();
        await invalidateDepartmentQueries();
        await ApiUtils.apiCreateUserActivitiesLog(SystemConstant.LOGGER_ACTION_TYPES.DELETE_DEPARTMENT);
        EventBusUtils.emit(AppConstant.EVENT_BUS_EVENTS.refetchDepartments, unitId);
        SystemUtils.appendNotification(getLabel("TXT_DELETE_DEPARTMENT_SUCCESS"), "success");
        onClose();
      } else if (
        response.status === ApiConstant.STT_BAD_REQUEST &&
        parseInt(response.error) === SystemConstant.DEPARTMENT_ERROR.CONTAIN_POSITIONS
      ) {
        SystemUtils.appendNotification(getLabel("TXT_DEPARTMENT_CONTAIN_POSITIONS"), "error");
      } else if (response.status === ApiConstant.STT_NOT_FOUND) {
        SystemUtils.appendNotification(getLabel("TXT_NO_DEPARTMENTS"), "error");
      } else {
        SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
      }
    },
    onError: error => {
      console.error(error);
      SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
    },
  });

  const deleteDepartment = payload => {
    deleteDepartmentMutation.mutate(payload);
  };

  return { deleteDepartment };
}
