import { memo } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LangConstant } from "const";

const PopupConfirm = ({ open, title, onClose, onAccept }) => {
  const classes = useStyles();

  const { t: getLabel } = useTranslation();

  const onHandleAccept = () => {
    onClose();
    onAccept();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent className={classes.confirmContent}>{title}</DialogContent>
      <DialogActions>
        <Button sx={{ width: "100px" }} onClick={onClose}>
          {getLabel(LangConstant.L_CANCEL_BUTTON)}
        </Button>
        <Button sx={{ width: "100px" }} variant="contained" onClick={onHandleAccept}>
          {getLabel(LangConstant.L_AGREE)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(PopupConfirm);

const useStyles = makeStyles(() => ({
  confirmContent: {
    fontSize: 18,
    paddingTop: 30,
  },
}));
