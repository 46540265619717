import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { ApiConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import { ApiUtils, HumpUtils, StorageUtils, SystemUtils } from "utils";
import useInvalidateQueries from "hooks/common/useInvalidateQueries";
import qs from "qs";

export default function useUpdatePosition() {
  const { t: getLabel } = useTranslation();
  const positionError = getLabel(LangConstant.OBJ_POSITION_ERROR, { returnObjects: true });

  const { invalidatePositionQueries } = useInvalidateQueries();

  const updatePositionMutation = useMutation({
    mutationFn: async data => {
      const payload = {
        branchId: StorageUtils.getStoreData(KeyConstant.KEY_BRANCH_ID),
        positionName: data.positionName,
        unitId: data.unitId,
        departmentId: data.departmentId,
        positionId: data.positionId,
      };
      const response = await ApiUtils.createApiWithToken(false).post(
        ApiConstant.UPDATE_POSITION,
        qs.stringify(HumpUtils.decamelizeKeys(payload)),
      );
      return HumpUtils.camelizeKeys(response.data);
    },
    onMutate: () => {
      SystemUtils.openCircleLoading();
    },
    onSettled: () => {
      SystemUtils.openCircleLoading(false);
    },
    onSuccess: async (response, { onClose }) => {
      if (response.status === ApiConstant.STT_OK) {
        await invalidatePositionQueries();
        await ApiUtils.apiCreateUserActivitiesLog(SystemConstant.LOGGER_ACTION_TYPES.EDIT_POSITION);
        SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_POSITION_SUCCESS), "success");
        onClose();
      } else if (
        response.status === ApiConstant.STT_BAD_REQUEST &&
        parseInt(response.error) === SystemConstant.POSITION_ERROR.MISSING_PARAMETERS
      ) {
        SystemUtils.appendNotification(
          getLabel(positionError[SystemConstant.POSITION_ERROR.MISSING_PARAMETERS]),
          "error",
        );
      } else if (
        response.status === ApiConstant.STT_BAD_REQUEST &&
        parseInt(response.error) === SystemConstant.POSITION_ERROR.IN_WRONG_FORMAT
      ) {
        SystemUtils.appendNotification(getLabel(positionError[SystemConstant.POSITION_ERROR.IN_WRONG_FORMAT]), "error");
      } else if (
        response.status === ApiConstant.STT_BAD_REQUEST &&
        parseInt(response.error) === SystemConstant.POSITION_ERROR.DUPLICATED
      ) {
        SystemUtils.appendNotification(getLabel(positionError[SystemConstant.POSITION_ERROR.DUPLICATED]), "error");
      } else {
        SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
      }
    },
    onError: error => {
      console.error(error);
      SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
    },
  });

  const updatePosition = payload => {
    updatePositionMutation.mutate(payload);
  };

  return { updatePosition };
}
