import React, { memo } from "react";
import PropTypes from "prop-types";
import { SvgIcon } from "@mui/material";

const SettingIcon = ({ className, width, height }) => {
  return (
    <SvgIcon className={className} width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.4022 13.5801C20.7599 13.7701 21.0359 14.0701 21.23 14.3701C21.6081 14.9901 21.5775 15.7501 21.2096 16.4201L20.4942 17.6201C20.1161 18.2601 19.4109 18.6601 18.6854 18.6601C18.3277 18.6601 17.9291 18.5601 17.6021 18.3601C17.3364 18.1901 17.0298 18.1301 16.7028 18.1301C15.691 18.1301 14.8428 18.9601 14.8121 19.9501C14.8121 21.1001 13.8719 22.0001 12.6967 22.0001H11.3068C10.1213 22.0001 9.18115 21.1001 9.18115 19.9501C9.16071 18.9601 8.31248 18.1301 7.30075 18.1301C6.9635 18.1301 6.65691 18.1901 6.40142 18.3601C6.0744 18.5601 5.66561 18.6601 5.31815 18.6601C4.58234 18.6601 3.87719 18.2601 3.49906 17.6201L2.79391 16.4201C2.41579 15.7701 2.39535 14.9901 2.77347 14.3701C2.93699 14.0701 3.24357 13.7701 3.59104 13.5801C3.87719 13.4401 4.06114 13.2101 4.23487 12.9401C4.74585 12.0801 4.43926 10.9501 3.5706 10.4401C2.55886 9.87009 2.23184 8.60009 2.81435 7.61009L3.49906 6.43009C4.0918 5.44009 5.35903 5.09009 6.38098 5.67009C7.27009 6.15009 8.4249 5.83009 8.9461 4.98009C9.10961 4.70009 9.20159 4.40009 9.18115 4.10009C9.16071 3.71009 9.27312 3.34009 9.46729 3.04009C9.84542 2.42009 10.5301 2.02009 11.2762 2.00009H12.7171C13.4734 2.00009 14.1581 2.42009 14.5362 3.04009C14.7202 3.34009 14.8428 3.71009 14.8121 4.10009C14.7917 4.40009 14.8837 4.70009 15.0472 4.98009C15.5684 5.83009 16.7232 6.15009 17.6225 5.67009C18.6343 5.09009 19.9117 5.44009 20.4942 6.43009L21.1789 7.61009C21.7717 8.60009 21.4446 9.87009 20.4227 10.4401C19.554 10.9501 19.2474 12.0801 19.7686 12.9401C19.9321 13.2101 20.1161 13.4401 20.4022 13.5801ZM9.10961 12.0101C9.10961 13.5801 10.4075 14.8301 12.012 14.8301C13.6164 14.8301 14.8837 13.5801 14.8837 12.0101C14.8837 10.4401 13.6164 9.18009 12.012 9.18009C10.4075 9.18009 9.10961 10.4401 9.10961 12.0101Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default memo(SettingIcon);

SettingIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

SettingIcon.defaultProps = {
  width: 24,
  height: 24,
};
