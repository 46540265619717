import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useLocation, useHistory } from "react-router-dom";
import HumpUtils from "humps";
import { ApiUtils, StorageUtils, SystemUtils } from "utils";
import { ApiConstant, KeyConstant, LangConstant } from "const";

export default function useUnitList() {
  const { t: getLabel } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const page = searchParams.get("page") || 1;
  const search = searchParams.get("search") || "";

  const [searchValue, setSearchValue] = useState(search);
  const [pagination, setPagination] = useState(parseInt(page));

  const handleSearchUnit = event => {
    const newUrlSearchParams = new URLSearchParams(searchParams);
    newUrlSearchParams.set("page", "1");
    newUrlSearchParams.set("search", event.target.value);
    history.push(location.pathname + "?" + newUrlSearchParams.toString());
    setPagination(1);
    setSearchValue(event.target.value);
  };

  const handleChangePage = (_, page) => {
    const newUrlSearchParams = new URLSearchParams(searchParams);
    newUrlSearchParams.set("page", page);
    history.push(location.pathname + "?" + newUrlSearchParams.toString());
    setPagination(page);
  };

  const unitListPaginationQuery = useQuery({
    queryKey: [ApiConstant.REACT_QUERY_KEYS.getUnitList, searchValue, pagination],
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const branchId = StorageUtils.getStoreData(KeyConstant.KEY_BRANCH_ID);
      const paging = 1; // 1 for pagination | 0 for get all
      const limit = 10;
      const filter = JSON.stringify(HumpUtils.decamelizeKeys({ unitName: searchValue }));
      const asc = 0;
      const offset = (pagination - 1) * limit;
      const orderBy = "created";
      const payload = { branchId, paging, limit, asc, offset, orderBy, filter };
      const response = await ApiUtils.createApiWithToken(false).get(
        ApiConstant.GET_UNIT_LIST,
        HumpUtils.decamelizeKeys(payload),
      );
      return HumpUtils.camelizeKeys(response.data);
    },
    onError: error => {
      console.error(error);
      SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
    },
  });

  const totalUnitListQuery = useQuery({
    queryKey: [ApiConstant.REACT_QUERY_KEYS.getTotalUnitList],
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const branchId = StorageUtils.getStoreData(KeyConstant.KEY_BRANCH_ID);
      const paging = 1; // 1 for pagination | 0 for get all
      const limit = 10000;
      const asc = 0;
      const offset = 0;
      const orderBy = "created";
      const payload = { branchId, paging, limit, asc, offset, orderBy };
      const response = await ApiUtils.createApiWithToken(false).get(
        ApiConstant.GET_UNIT_LIST,
        HumpUtils.decamelizeKeys(payload),
      );
      return HumpUtils.camelizeKeys(response.data);
    },
    onError: error => {
      console.error(error);
      SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
    },
  });

  return {
    isLoading: unitListPaginationQuery.isLoading || totalUnitListQuery.isLoading,
    unitList: unitListPaginationQuery?.data?.data?.data || [],
    totalPage: unitListPaginationQuery?.data?.data?.totalPages || 1,
    page: pagination,
    searchValue: searchValue,
    totalUnitList: totalUnitListQuery?.data?.data?.data || [],
    handleSearchUnit: handleSearchUnit,
    handleChangePage: handleChangePage,
  };
}
