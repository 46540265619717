import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Dialog, Typography } from "@mui/material";
import { Cancel, Download } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

const ImportUserFailureDialog = ({ open, isFailureValidation, onClose, onClickDownload }) => {
  const classes = useStyle();
  const { t: getLabel } = useTranslation();

  return (
    <Dialog open={open} fullWidth>
      <Box className={classes.root}>
        <Cancel className={classes.icons} color="error" />
        <Typography className={classes.title} color="error">
          {getLabel(isFailureValidation ? "TXT_FILE_NOT_VALID" : "TXT_IMPORT_FAILURE")}
        </Typography>
        <Typography className={classes.message}>
          {getLabel(isFailureValidation ? "TXT_CHOOSE_ANOTHER_FILE_MSG" : "TXT_IMPORT_FAILURE_MSG")}
        </Typography>
        {isFailureValidation ? (
          <Button className={classes.button} variant="contained" onClick={onClose}>
            {getLabel("L_ACCEPT")}
          </Button>
        ) : (
          <Box className={classes.buttonContainer}>
            <Button className={classes.button} variant="outlined" color="error" onClick={onClose}>
              {getLabel("L_CANCEL_BUTTON")}
            </Button>
            <Button className={classes.button} variant="contained" startIcon={<Download />} onClick={onClickDownload}>
              {getLabel("L_DOWNLOAD_FILE")}
            </Button>
          </Box>
        )}
      </Box>
    </Dialog>
  );
};

export default ImportUserFailureDialog;

const useStyle = makeStyles(() => ({
  root: {
    padding: "32px 16px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  icons: {
    width: 64,
    height: 64,
    margin: "0 0 16px",
  },

  title: {
    fontSize: "15px",
    fontWeight: "700",
    textAlign: "center",
  },

  message: {
    fontSize: "15px",
    textAlign: "center",
    marginBottom: "16px",
    whiteSpace: "pre-line",
  },

  button: {
    minWidth: "200px",
  },

  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "16px",
  },
}));
