import React, { memo } from "react";
import PropTypes from "prop-types";
import { SvgIcon } from "@mui/material";

const UpgradeIcon = ({ className, width, height }) => {
  return (
    <SvgIcon className={className} width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      <g clipPath="url(#clip0_258_5075)">
        <path
          d="M9.19281 8.21234V16.4163C9.19281 17.2714 9.19281 18.125 9.19281 18.9787C9.19281 19.6259 8.83818 19.9626 8.21172 19.9848C7.54662 20.007 6.87876 20 6.21366 19.9848C5.35538 19.9709 5.06147 19.6716 5.06009 18.8013C5.06009 15.5243 5.06009 12.2469 5.06009 8.96899V8.14997C3.84718 8.14997 2.70327 8.13889 1.56073 8.14997C0.921848 8.15967 0.356096 8.06267 0.0911603 7.38501C-0.161357 6.74199 0.162913 6.29022 0.598953 5.86062C2.3169 4.16023 4.02243 2.44598 5.73761 0.741438C6.73802 -0.253573 7.68185 -0.256346 8.66984 0.731736C10.3947 2.45845 12.1177 4.18748 13.8388 5.91882C14.2694 6.34703 14.6005 6.79604 14.3315 7.44321C14.0624 8.09038 13.5353 8.20818 12.9212 8.20402C11.7056 8.20264 10.4913 8.21234 9.19281 8.21234Z"
          fill="currentColor"
        />
        <path
          d="M20.4001 1.43841C22.2146 1.43841 24.0278 1.43841 25.8409 1.43841C26.713 1.43841 26.9862 1.7225 26.9959 2.59694C27.0014 3.05888 27.0014 3.52082 26.9959 3.98275C26.9862 4.58558 26.6964 4.95282 26.0796 4.95282C23.1405 4.96252 20.2027 4.97223 17.265 4.93481C16.9186 4.93481 16.5157 4.70615 16.2425 4.46502C15.5741 3.86668 14.9392 3.23145 14.341 2.5623C14.1713 2.37522 14.0209 1.98719 14.1037 1.8001C14.1865 1.61302 14.5701 1.45365 14.824 1.44949C16.6813 1.42455 18.5414 1.43841 20.4001 1.43841Z"
          fill="currentColor"
        />
        <path
          d="M16.4136 18.0225C15.2656 18.0225 14.1175 18.0225 12.9639 18.0225C12.0656 18.0225 11.9497 17.8937 11.947 16.9693C11.947 16.3688 11.947 15.7683 11.947 15.1678C11.947 14.7202 12.1746 14.4749 12.6245 14.4749C15.1524 14.4749 17.679 14.4679 20.2055 14.4818C20.7837 14.4818 21.079 14.838 21.09 15.4131C21.1011 15.9438 21.108 16.4746 21.09 17.0054C21.0666 17.7121 20.7851 18.0004 20.0592 18.0115C18.8491 18.0419 17.632 18.0225 16.4136 18.0225Z"
          fill="currentColor"
        />
        <path
          d="M19.3955 11.4566C18.224 11.4566 17.0497 11.4787 15.881 11.4413C15.605 11.433 15.1911 11.3027 15.0889 11.0963C14.9868 10.8898 15.1966 10.5239 15.3553 10.2842C15.6988 9.76865 16.1349 9.31411 16.4592 8.79304C16.8276 8.20823 17.305 7.92691 17.9936 7.93245C19.6494 7.9477 21.3053 7.93245 22.9611 7.94077C23.7477 7.94077 24.0333 8.23872 24.0568 9.0314C24.0719 9.51505 24.0761 10.0015 24.0568 10.4851C24.0264 11.1101 23.7063 11.4552 23.0508 11.4552C21.831 11.4524 20.6126 11.4566 19.3955 11.4566Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_258_5075">
          <rect width="27" height="20" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default memo(UpgradeIcon);

UpgradeIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

UpgradeIcon.defaultProps = {
  width: 27,
  height: 20,
};
