import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  OutlinedInput,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Divider,
} from "@mui/material";
import { DeleteOutline, Logout, RemoveRedEyeOutlined, Search } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { KeyConstant, LangConstant, PathConstant, SystemConstant } from "const";
import { AuthUtils, BranchUtils, StorageUtils } from "utils";
import { useBranchList, useDeleteBranch, useUpdateBranch } from "hooks";
import { CreateBranch, PopupConfirm } from "./components";

const BranchManagement = () => {
  const { t: getLabel } = useTranslation(LangConstant.NS_COMMON);
  const classes = useStyles();
  const history = useHistory();

  const {
    branchList,
    pagination,
    searchInput,
    handlePageChange,
    handleChangeRowPerPage,
    handleChangeSearchInput,
    handleLabelDisplayedRows,
  } = useBranchList();

  const { changeBranchStatus } = useUpdateBranch();
  const { deleteBranch } = useDeleteBranch();

  const sidebarTitles = getLabel(LangConstant.OBJ_SIDEBAR_TITLE, { returnObjects: true });
  const tableBranchObj = getLabel(LangConstant.OBJ_TABLE_BRANCH, { returnObjects: true });
  const tableErrorObj = getLabel(LangConstant.OBJ_TABLE_ERROR, { returnObjects: true });

  const tableField = [
    { label: tableBranchObj.number },
    { label: tableBranchObj.avatar },
    { label: tableBranchObj.branchName },
    { label: tableBranchObj.package },
    { label: tableBranchObj.packageId },
    { label: tableBranchObj.license },
    { label: tableBranchObj.existAccount },
    { label: tableBranchObj.status },
    { label: tableBranchObj.action },
  ];

  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState(null);

  const handleOpenCreateBranchDialog = () => {
    setIsOpenCreate(true);
  };

  const handleCloseCreateBranchDialog = () => {
    setIsOpenCreate(false);
  };

  const handleViewBranchDetails = branch => {
    BranchUtils.handlePersistBranch(branch);
    history.push(PathConstant.COMPANY_MANAGEMENT);
  };

  const handleOpenConfirmDeleteBranchDialog = branch => {
    setSelectedBranch(branch);
  };

  const handleCloseConfirmDeleteBranchDialog = () => {
    setSelectedBranch(null);
  };

  const handleDeleteBranch = () => {
    deleteBranch({
      state: SystemConstant.ACTIVE_STATUS.inactive,
      token: selectedBranch.accessToken,
      id: selectedBranch.id,
    });
  };

  const handleChangeBranchState = (event, branch) => {
    changeBranchStatus({
      ...branch,
      state: event.target.checked
        ? SystemConstant.BRANCH_ACTIVE_STATUS.active
        : SystemConstant.BRANCH_ACTIVE_STATUS.inactive,
    });
  };

  return (
    <Box className={classes.component}>
      <Box className={classes.header}>
        <Typography variant="h5" className={classes.title}>
          {sidebarTitles.branchManagement}
        </Typography>
        <Box className={classes.adminInfo}>
          <Typography className={classes.adminName}>{StorageUtils.getStoreData(KeyConstant.KEY_USERNAME)}</Typography>
          <IconButton size="small" onClick={AuthUtils.handleLogout}>
            <Logout fontSize="small" />
          </IconButton>
        </Box>
      </Box>
      <Box className={classes.searchComponent}>
        <Button variant="contained" className={classes.createButton} onClick={handleOpenCreateBranchDialog}>
          {getLabel(LangConstant.L_CREATE)}
        </Button>
        <OutlinedInput
          size="small"
          value={searchInput}
          className={classes.textField}
          onChange={handleChangeSearchInput}
          placeholder={getLabel(LangConstant.P_SEARCH)}
          startAdornment={<Search className={classes.searchIcon} />}
        />
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead className={classes.tableHead}>
            <TableRow>
              {tableField.map(item => (
                <TableCell key={item.label} align="center" className={classes.tableHeadContent}>
                  {item.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {branchList.map((item, index) => (
              <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell align="center" className={classes.tableContentCell}>
                  <strong>{index + 1}</strong>
                </TableCell>
                <TableCell align="center" className={classes.tableContentCell}>
                  <Box display="flex" alignItems="center" justifyContent="center" width="100%">
                    <Avatar className={classes.branchAvatar} src={item.avatar}>
                      {item?.name?.charAt(0)}
                    </Avatar>
                  </Box>
                </TableCell>
                <TableCell
                  align="center"
                  className={`${classes.tableContentCell} ${classes.tableCellName}`}
                  onClick={() => handleViewBranchDetails(item)}
                >
                  {item.name}
                </TableCell>
                <TableCell align="center" className={classes.tableContentCell}>
                  {item.package}
                </TableCell>
                <TableCell align="center" className={classes.tableContentCell}>
                  {item.packageId}
                </TableCell>
                <TableCell align="center" className={classes.tableContentCell}>
                  {item.license}
                </TableCell>
                <TableCell align="center" className={classes.tableContentCell}>
                  {item.existedAccount}
                </TableCell>
                <TableCell align="center" className={classes.tableContentCell}>
                  <Switch
                    size="small"
                    checked={Boolean(item.state)}
                    onChange={event => handleChangeBranchState(event, item)}
                  />
                </TableCell>
                <TableCell align="center" className={classes.tableContentCell}>
                  <IconButton size="small" onClick={() => handleViewBranchDetails(item)}>
                    <RemoveRedEyeOutlined fontSize="small" />
                  </IconButton>
                  <IconButton size="small" onClick={() => handleOpenConfirmDeleteBranchDialog(item)}>
                    <DeleteOutline fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Divider />
        <TablePagination
          component="div"
          rowsPerPageOptions={pagination.rowPerPageOptions}
          count={pagination.count}
          rowsPerPage={pagination.rowPerPage}
          page={pagination.page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleChangeRowPerPage}
          labelDisplayedRows={handleLabelDisplayedRows}
          labelRowsPerPage={tableErrorObj.rowsPerPage}
          classes={{
            displayedRows: classes.displayedRows,
            selectLabel: classes.displayedRows,
            menuItem: classes.menuItem,
          }}
        />
      </TableContainer>
      <Typography className={clsx(classes.footer, "regular-sm-txt")}>
        {getLabel(LangConstant.TXT_APP_COPYRIGHT)}
      </Typography>
      <CreateBranch open={isOpenCreate} onClose={handleCloseCreateBranchDialog} />
      <PopupConfirm
        open={Boolean(selectedBranch)}
        onClose={handleCloseConfirmDeleteBranchDialog}
        onAccept={handleDeleteBranch}
        title={getLabel(LangConstant.TXT_CONFIRM_DELETE_BRANCH)}
      />
    </Box>
  );
};

export default BranchManagement;

const useStyles = makeStyles(() => ({
  component: {
    padding: 50,
    minHeight: "100vh",
    background: "#f7f7f7",
  },

  title: {
    marginBottom: 40,
  },

  tableHeader: {
    padding: 10,
  },

  displayedRows: {
    fontSize: 14,
    color: "#000000",
  },

  menuItem: {
    fontSize: 14,
  },

  searchIcon: {
    marginRight: 10,
  },

  textField: {
    backgroundColor: "#fff",
    borderRadius: 4,
    fontSize: 15,
    width: "100%",
    transition: "all .1s linear",
    maxWidth: "30%",

    "&::placeholder": {
      color: "#CBCACA",
    },
  },

  searchComponent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 30,
  },

  createButton: {
    width: 150,
  },

  tableHead: {
    backgroundColor: "#F5F5F5",
  },

  tableContentCell: {
    fontSize: 14,
  },

  tableCellName: {
    color: "blue",
    cursor: "pointer",
  },

  branchAvatar: {
    border: "1px solid rgba(0, 0, 0, 0.25)",

    "& > img": {
      width: "70%",
      height: "70%",
    },
  },

  branchStatus: {
    width: 15,
    height: 15,
    borderRadius: 50,
    backgroundColor: "#0cc30c",
    margin: "auto",
  },

  confirmContent: {
    fontSize: 18,
  },

  footer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    lineHeight: "20px",
    textAlign: "center",
    padding: "10px 0",
  },

  header: {
    display: "flex",
    justifyContent: "space-between",
  },

  adminInfo: {
    display: "flex",
    alignItems: "center",
    paddingBottom: 25,
  },

  adminName: {
    marginRight: 10,
    fontSize: "16px",
  },

  tableHeadContent: {
    textTransform: "uppercase",
    fontSize: "14px",
    fontWeight: "bold",
  },

  branchNameButton: {
    textAlign: "left",
    justifyContent: "flex-start",
    background: "transparent !important",
  },
}));
