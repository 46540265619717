import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Dialog, Typography } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

const ImportUserSuccessDialog = ({ open, onClose }) => {
  const classes = useStyle();
  const { t: getLabel } = useTranslation();

  return (
    <Dialog open={open} fullWidth>
      <Box className={classes.root}>
        <CheckCircle className={classes.icons} color="success" />
        <Typography className={classes.title}>{getLabel("TXT_IMPORT_SUCCESS")}</Typography>
        <Typography className={classes.message}>{getLabel("TXT_IMPORT_SUCCESS_MSG")}</Typography>
        <Button className={classes.button} variant="contained" onClick={onClose}>
          {getLabel("L_ACCEPT")}
        </Button>
      </Box>
    </Dialog>
  );
};

export default ImportUserSuccessDialog;

const useStyle = makeStyles(() => ({
  root: {
    padding: "32px 16px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  icons: {
    width: 64,
    height: 64,
    margin: "0 0 16px",
  },

  title: {
    fontSize: "15px",
    fontWeight: "700",
    textAlign: "center",
  },

  message: {
    fontSize: "15px",
    textAlign: "center",
    marginBottom: "16px",
  },

  button: {
    minWidth: "200px",
  },
}));
