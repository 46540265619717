import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Box } from "@mui/material";
import { LangConstant } from "const";
import { useCreateBranch } from "hooks";
import PopupConfirm from "./PopupConfirm";

const CreateBranch = ({ open, onClose }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();
  const { createBranch } = useCreateBranch();

  const [packageId, setPackageId] = useState("");
  const [license, setLicense] = useState("");
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);

  const handleCloseConfirmPopup = () => {
    setIsOpenConfirm(false);
  };

  const handleAcceptCloseDialog = () => {
    setPackageId("");
    setLicense("");
    onClose();
  };

  const handleCreateBranch = event => {
    event.preventDefault();
    createBranch({ packageId: packageId, license: license, onClose: handleAcceptCloseDialog });
  };

  const handleChangeLicense = event => {
    setLicense(event.target.value);
  };

  const handleChangePackageId = event => {
    setPackageId(event.target.value);
  };

  const handleCancelCreate = () => {
    if (!Boolean(license === "" && packageId === "")) {
      setIsOpenConfirm(true);
    } else {
      onClose();
    }
  };

  return (
    <>
      <Dialog open={open} onClose={handleCancelCreate} classes={{ paper: classes.createComponent }}>
        <DialogTitle className={classes.createTitle}>{getLabel(LangConstant.TXT_ADD_BRANCH)}</DialogTitle>
        <DialogContent className={classes.createBranchContent}>
          <Box className={classes.displayContent}>
            <Box component="form" onSubmit={handleCreateBranch}>
              <TextField
                required
                fullWidth
                type="text"
                value={packageId}
                label="Package ID"
                onChange={handleChangePackageId}
                className={classes.createInput}
                size="small"
                autoComplete="off"
              />
              <TextField
                required
                fullWidth
                type="text"
                value={license}
                label="License Number"
                onChange={handleChangeLicense}
                className={classes.createInput}
                size="small"
                autoComplete="off"
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions className={classes.actionButtons}>
          <Button onClick={handleCancelCreate} variant="text">
            {getLabel(LangConstant.L_CANCEL_ADD_BRANCH)}
          </Button>
          <Button onClick={handleCreateBranch} variant="contained">
            {getLabel(LangConstant.L_SAVE_INFO)}
          </Button>
        </DialogActions>
      </Dialog>
      <PopupConfirm
        open={isOpenConfirm}
        onClose={handleCloseConfirmPopup}
        onAccept={handleAcceptCloseDialog}
        title={getLabel(LangConstant.TXT_CONFIRM_CANCEL_CREATE_BRANCH)}
      />
    </>
  );
};

export default CreateBranch;

const useStyles = makeStyles(() => ({
  createComponent: {
    width: 600,
  },

  createTitle: {
    fontSize: 24,
  },

  createBranchContent: {
    display: "grid",
  },

  createInput: {
    marginTop: 8,
    marginBottom: 8,
  },

  infoValue: {
    fontSize: 16,
    padding: 12,
  },

  actionButtons: {
    margin: "0px 16px 16px",
  },

  chooseAvatar: {
    width: "25%",
  },

  chooseImage: {
    margin: "10px auto",
    textTransform: "none",
  },

  icon: {
    margin: 0,
  },

  iconButton: {
    padding: 0,
    minWidth: "unset",
  },
}));
