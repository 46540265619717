import { ApiConstant } from "const";
import { useMutation } from "react-query";
import { ApiUtils, SystemUtils } from "utils";

export default function useUploadBranchAvatar() {
  const uploadBranchAvatarMutation = useMutation({
    mutationFn: async file => {
      const formData = new FormData();
      formData.append("file", file);
      const response = await ApiUtils.createApiWithToken(false).post(ApiConstant.UPLOAD_AVATAR, formData);
      if (response.status === ApiConstant.STT_OK) return response.data.data.id;
      return null;
    },
    onMutate: () => {
      SystemUtils.openCircleLoading();
    },
    onSettled: () => {
      SystemUtils.openCircleLoading(false);
    },
  });

  const uploadBranchAvatar = payload => {
    uploadBranchAvatarMutation.mutate(payload);
  };

  const uploadBranchAvatarAsync = async payload => {
    return uploadBranchAvatarMutation.mutateAsync(payload);
  };

  return { uploadBranchAvatar, uploadBranchAvatarAsync };
}
