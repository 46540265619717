import { Box, Button, Popover, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AppConstant, LangConstant } from "const";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { AddCircleOutline, AddComment, Delete, Edit, SupervisedUserCircle } from "@mui/icons-material";

const ActionPopover = ({
  open,
  anchorEl,
  selectedRow,
  type,
  onCloseAction,
  onOpenDepartmentDialog,
  onOpenMemberManagement,
  onCreateChannel,
  onEdit,
  onDelete,
}) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();

  const handleClickFirstButton = () => {
    type === AppConstant.COMPANY_MANAGEMENT_TYPE.unit
      ? onOpenDepartmentDialog(null, selectedRow)
      : onOpenMemberManagement();
  };

  return (
    <Fragment>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={onCloseAction}
        classes={{ paper: classes.paper }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box className={classes.wrapAction}>
          <Button className={classes.styleButton} onClick={handleClickFirstButton}>
            {type === AppConstant.COMPANY_MANAGEMENT_TYPE.unit ? <AddCircleOutline /> : <SupervisedUserCircle />}
            <Typography className={classes.actionName}>
              {getLabel(
                type === AppConstant.COMPANY_MANAGEMENT_TYPE.unit
                  ? LangConstant.TXT_CREATE_DEPARTMENT
                  : LangConstant.TXT_MEMBER_MANAGEMENT,
              )}
            </Typography>
          </Button>
          <Button
            className={classes.styleButton}
            onClick={onCreateChannel}
            disabled={selectedRow?.groupId}
          >
            <AddComment />
            <Typography className={classes.actionName}>{getLabel(LangConstant.TXT_CREATE_CHANNEL)}</Typography>
          </Button>
          <Button className={classes.styleButton} onClick={onEdit}>
            <Edit />
            <Typography className={classes.actionName}>{getLabel(LangConstant.TXT_EDIT_INFO)}</Typography>
          </Button>
          <Button className={classes.styleButton} onClick={onDelete}>
            <Delete />
            <Typography className={classes.actionName}>{getLabel(LangConstant.TXT_DELETE)}</Typography>
          </Button>
        </Box>
      </Popover>
    </Fragment>
  );
};

export default ActionPopover;

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: 0,
    borderRadius: 4,
    overflowX: "unset",
    overflowY: "unset",
    background: "#FFFFFF",
    width: 360,
  },

  wrapAction: {
    margin: 10,
  },

  styleButton: {
    color: "gray",
    width: "100%",
    justifyContent: "left",
    marginBottom: 0,
    gap: "20px",
    textTransform: "unset",
  },

  actionName: {
    fontSize: 16,
    fontWeight: 500,
    textAlign: "left",
  },
}));
