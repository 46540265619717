import useUserList from "./useUserList";
import { CommonUtils } from "utils";
import { SystemConstant } from "const";

export default function useDepartmentUserList(departmentId, searchValue = "", page = SystemConstant.ACTIVE_PAGINATION) {
  const { totalUsers } = useUserList();

  const departmentUserList = totalUsers
    .filter(user => user.departmentId === departmentId)
    .map(user => ({ label: user.name, id: user.accountId }));

  const departmentUserListFilter = totalUsers
    .filter(
      user =>
        (user.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
          user.mail?.includes(searchValue) ||
          user.phone?.includes(searchValue)) &&
        user.departmentId === departmentId,
    )
    .map(user => ({ id: user.accountId, name: user.name, phone: user.phone, mail: user.mail }));

  const totalPages = Math.ceil(departmentUserListFilter.length / SystemConstant.USERS_LIMITED_NUMBER);

  return {
    departmentUserList,
    totalPages,
    currentPageUsers: CommonUtils.paginateFromArray(
      departmentUserListFilter,
      page,
      SystemConstant.USERS_LIMITED_NUMBER,
    ),
  };
}
