import { useMutation } from "react-query";
import { ApiConstant, AppConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import { ApiUtils, EventBusUtils, HumpUtils, StorageUtils, SystemUtils } from "utils";
import qs from "qs";
import { useTranslation } from "react-i18next";
import useInvalidateQueries from "hooks/common/useInvalidateQueries";

export default function useAddMember() {
  const { t: getLabel } = useTranslation();

  const { invalidateUserQueries, invalidateUnitQueries, invalidateDepartmentQueries } = useInvalidateQueries();

  const addMemberMutation = useMutation({
    mutationFn: async data => {
      const branchId = StorageUtils.getStoreData(KeyConstant.KEY_BRANCH_ID);
      const payload = {
        branchId: branchId,
        departmentId: data.departmentId,
        userIds: data.userIds,
      };

      const response = await ApiUtils.createApiWithToken(false).post(
        ApiConstant.ADD_USERS_TO_DEPARTMENT,
        qs.stringify(HumpUtils.decamelizeKeys(payload)),
      );
      return response.data;
    },
    onMutate: () => {
      SystemUtils.openCircleLoading();
    },
    onSettled: () => {
      SystemUtils.openCircleLoading(false);
    },
    onSuccess: async (response, { onClose, unitId }) => {
      if (response.status === ApiConstant.STT_OK) {
        SystemUtils.appendNotification(getLabel(LangConstant.TXT_ADD_MEMBER_SUCCESS), "success");
        await invalidateUserQueries();
        await invalidateUnitQueries();
        await invalidateDepartmentQueries();
        await ApiUtils.apiCreateUserActivitiesLog(SystemConstant.LOGGER_ACTION_TYPES.ADD_DEPARTMENT_USERS);
        EventBusUtils.emit(AppConstant.EVENT_BUS_EVENTS.refetchDepartments, unitId);
        onClose();
      } else {
        SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
      }
    },
    onError: error => {
      console.error(error);
      SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
    },
  });

  const addMemberAsync = async payload => {
    return addMemberMutation.mutateAsync(payload);
  };

  return { addMemberAsync };
}
