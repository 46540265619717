import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  CardMedia,
  FormControl,
  FormControlLabel,
  FormLabel,
  Input,
  InputAdornment,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { LodashUtils, BranchUtils } from "utils";
import { AppConstant, ImageConstant, LangConstant } from "const";
import { useUpdateBranch, useUploadBranchAvatar } from "hooks";

const CompanyProfile = ({ branchDetails }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation([LangConstant.NS_COMMON, LangConstant.NS_LOGIN]);

  const CompanyTagNameIcon = ImageConstant.CompanyTagNameIcon;
  const errorObjectContent = getLabel(LangConstant.ERR_OBJECT, { ns: LangConstant.NS_LOGIN, returnObjects: true });
  const { uploadBranchAvatarAsync } = useUploadBranchAvatar();
  const { updateBranchInfo } = useUpdateBranch();

  const [form, setForm] = useState({ companyName: "", companyAvatar: null, companyAvatarId: "" });
  const [initForm, setInitForm] = useState({ companyName: "", companyAvatar: null, companyAvatarId: "" });
  const [validatedMsg, setValidatedMsg] = useState("");

  const handleChangeCompanyName = e => {
    const name = e.target.value;
    if (name.length < AppConstant.COMPANY_NAME_LENGTH_LIMITED) {
      setForm(currentForm => ({ ...currentForm, companyName: name }));
    }
  };

  const handleChangeCompanyAvatar = async e => {
    if (e.target.value.length !== 0) {
      const avatar = e.target.files[0];
      if (!validateAvatar(avatar)) {
        setValidatedMsg(errorObjectContent.imageNotValid);
        setForm(currentForm => ({
          ...currentForm,
          companyAvatar: initForm.companyAvatar,
          companyAvatarId: initForm.companyAvatarId,
        }));
        return;
      }
      const avatarId = await uploadBranchAvatarAsync(avatar);
      if (!avatarId) {
        setValidatedMsg(errorObjectContent.imageNotValid);
        setForm(currentForm => ({
          ...currentForm,
          companyAvatar: initForm.companyAvatar,
          companyAvatarId: initForm.companyAvatarId,
        }));
        return;
      }
      const previewAvatar = URL.createObjectURL(avatar);
      setValidatedMsg("");
      setForm(currentForm => ({ ...currentForm, companyAvatar: previewAvatar, companyAvatarId: avatarId }));
    }
  };

  const handleDiscardFormData = () => {
    const fileInput = document.getElementById("companyAvatar");
    fileInput.value = null;
    setForm({ ...initForm });
    setValidatedMsg("");
  };

  const handleFormSubmit = async event => {
    event.preventDefault();
    const currentBranchDetails = BranchUtils.getCurrentBranch();
    const payload = { ...currentBranchDetails, name: form.companyName, attachmentId: form.companyAvatarId };
    updateBranchInfo(payload);
  };

  useEffect(() => {
    if (branchDetails) {
      const data = {
        companyName: branchDetails.name,
        companyAvatar: branchDetails.avatar,
        companyAvatarId: branchDetails.attachmentId,
      };
      setForm(state => ({ ...state, ...data }));
      setInitForm(state => ({ ...state, ...data }));
    }
  }, [branchDetails]);

  return (
    <Box component="form" onSubmit={handleFormSubmit}>
      <Box className={classes.inputContainer}>
        <FormControl className={classes.avatarContainer}>
          <Typography variant="h5" className={clsx(classes.formControlLabel, "semiBold-sm-txt")}>
            {getLabel(LangConstant.L_COMPANY_AVATAR)}
          </Typography>
          <Input
            id="companyAvatar"
            type="file"
            className="hidden"
            onChange={handleChangeCompanyAvatar}
            inputProps={{
              accept: AppConstant.ACCEPTABLE_IMAGE_TYPES.join(","),
            }}
          />
          <Box className={classes.avatarGroup}>
            {Boolean(form.companyAvatar || initForm.companyAvatar) && (
              <CardMedia image={form.companyAvatar || initForm.companyAvatar} className={classes.avatarPreview} />
            )}
            <FormLabel htmlFor="companyAvatar" className={classes.avatarButton}>
              <Typography variant="button" className={clsx(classes.avatarButtonText)}>
                {getLabel(LangConstant.L_CHANGE_COMPANY_AVATAR_BUTTON)}
              </Typography>
            </FormLabel>
          </Box>
          <Typography variant="caption" className={clsx(classes.avatarValidateMsg, "regular-sm-txt")}>
            {validatedMsg}
          </Typography>
        </FormControl>
        <FormControlLabel
          control={
            <Input
              disableUnderline
              id="companyName"
              onChange={handleChangeCompanyName}
              value={form.companyName}
              placeholder={getLabel(LangConstant.P_COMPANY_NAME)}
              classes={{
                root: classes.textField,
                focused: classes.textFieldFocus,
              }}
              startAdornment={
                <InputAdornment position="start">
                  <CompanyTagNameIcon className={classes.textFieldIcon} />
                </InputAdornment>
              }
            />
          }
          classes={{
            root: classes.formControlRoot,
            label: clsx(classes.formControlLabel, "semiBold-sm-txt"),
          }}
          label={getLabel(LangConstant.L_COMPANY_NAME)}
          labelPlacement="top"
        />
      </Box>
      <FormControl className={classes.buttonContainer}>
        <Button
          disabled={LodashUtils.isEqual(form, initForm) || validatedMsg !== "" || form.companyName === ""}
          className={clsx(classes.primaryButton, "semiBold-sm-txt")}
          variant="contained"
          type="submit"
        >
          {getLabel(LangConstant.L_CONFIRM_BUTTON)}
        </Button>
        <Button
          className={clsx(classes.secondaryButton, "semiBold-sm-txt")}
          disabled={LodashUtils.isEqual(form, initForm)}
          onClick={handleDiscardFormData}
          type="reset"
        >
          {getLabel(LangConstant.L_DISCARD_BUTTON)}
        </Button>
      </FormControl>
    </Box>
  );
};

export default CompanyProfile;

const validateAvatar = avatar => {
  return avatar && AppConstant.ACCEPTABLE_IMAGE_TYPES.includes(avatar.type);
};

const useStyles = makeStyles(theme => ({
  formControlRoot: {
    alignItems: "flex-start",
    margin: 0,
  },

  formControlLabel: {
    lineHeight: "20px",
    color: "#6C7078",
    marginBottom: 8,
  },

  textField: {
    minWidth: 400,
    borderRadius: 4,
    border: "1px solid #D4D5D8",
    fontSize: 15,
    lineHeight: "16px",
    padding: "4px 14px",
    width: "100%",
    transition: "all .1s linear",
    height: "41px",

    "&::placeholder": {
      color: "#CBCACA",
    },
  },

  textFieldIcon: {
    color: theme.palette.common.black,
  },

  textFieldFocus: {
    border: "1px solid " + theme.palette.primary.main,
  },

  avatarContainer: {
    display: "block",
  },

  avatarGroup: {
    display: "flex",
    alignItems: "center",
  },

  avatarPreview: {
    width: 41,
    height: 41,
    borderRadius: "50%",
    marginRight: 16,
    border: "1px solid #e1e1e1",
  },

  avatarButton: {
    minWidth: 160,
    height: 41,
    border: "1px solid #D4D5D8",
    borderRadius: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    backgroundColor: theme.palette.common.white,
    transition: "all .1s linear",

    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      "& *": {
        color: theme.palette.common.white,
      },
    },
  },

  avatarButtonIcon: {
    marginRight: 12,
    color: theme.palette.primary.main,
  },

  avatarButtonText: {
    lineHeight: "20px",
    color: theme.palette.primary.main,
    textTransform: "unset",
    fontSize: "14px",
    fontWeight: "600",
  },

  avatarValidateMsg: {
    lineHeight: "16px",
    color: "#FF2C28",
    display: "block",
    margin: "4px 66px 0",
  },

  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "row",
  },

  primaryButton: {
    lineHeight: "20px",
    minWidth: 164,
    borderRadius: 4,
    marginRight: 19,
    padding: "8px 16px",
    textTransform: "unset",
  },

  secondaryButton: {
    lineHeight: "20px",
    backgroundColor: "#F5F5F5",
    color: theme.palette.common.black,
    minWidth: 164,
    borderRadius: 4,
    padding: "8px 16px",
    textTransform: "unset",
  },

  inputContainer: {
    display: "flex",
    alignItems: "flex-start",
    gap: "24px",
    marginBottom: "16px",

    [theme.breakpoints.down("lg")]: {
      flexDirection: "column-reverse",
    },
  },
}));
