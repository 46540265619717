import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import clsx from "clsx";
import { LangConstant, SystemConstant } from "const";
import { useActiveUser } from "hooks";

const ConfirmActiveDialog = ({ users, isDisable, isShow, onClose, onClear }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();
  const { activeUser } = useActiveUser();

  const [title, setTitle] = useState("");
  const [errorContent, setErrorContent] = useState(null);

  const handleCloseAfterSuccess = () => {
    onClose();
    onClear();
  };

  const handleSubmit = () => {
    const inactiveUsers = users.filter(user => user.branchAccountStatus === SystemConstant.ACTIVE_STATUS.inactive);
    const activeUsers = users.filter(user => user.branchAccountStatus === SystemConstant.ACTIVE_STATUS.active);
    if (isDisable && inactiveUsers.length > 0) {
      setErrorContent(getLabel(LangConstant.TXT_INACTIVE_ERROR));
    } else if (!isDisable && activeUsers.length > 0) {
      setErrorContent(getLabel(LangConstant.TXT_ACTIVE_ERROR));
    } else {
      activeUser({
        active: isDisable ? SystemConstant.USER_ACTIVE_STATUS.inactive : SystemConstant.USER_ACTIVE_STATUS.active,
        ids: users.map(item => item.accountId),
        onClose: handleCloseAfterSuccess,
      });
    }
  };

  /**
   * Handle set popup title
   */
  useEffect(() => {
    setTitle(
      users.length === 1
        ? isDisable
          ? getLabel(LangConstant.FM_REMOVE_SINGLE_PERSON, {
              name: users[0]["name"],
            })
          : getLabel(LangConstant.FM_ACTIVE_SINGLE_PERSON, {
              name: users[0]["name"],
            })
        : isDisable
          ? getLabel(LangConstant.FM_REMOVE_MULTIPLE_PEOPLE, {
              number: users.length,
            })
          : getLabel(LangConstant.FM_ACTIVE_MULTIPLE_PERSON, {
              number: users.length,
            }),
    );
  }, [users]);

  return (
    <Fragment>
      <Dialog open={isShow} onClose={onClose}>
        <Box className={classes.popup}>
          <DialogTitle className={classes.header}>
            {isDisable
              ? getLabel(LangConstant.TXT_CONFIRM_REMOVE_USER_HEADING)
              : getLabel(LangConstant.TXT_CONFIRM_ACTIVE_USER)}
          </DialogTitle>
          <DialogContent className={classes.title}>{title}</DialogContent>
          <DialogActions>
            <Button
              className={clsx(classes.button, classes.deleteButton)}
              onClick={handleSubmit}
              fullWidth
              color={isDisable ? "error" : "success"}
              variant="contained"
              disableElevation
            >
              {isDisable ? getLabel(LangConstant.L_REMOVE_BUTTON) : getLabel(LangConstant.L_ACTIVE_ACTION)}
            </Button>
            <Button
              variant="outlined"
              className={clsx(classes.button, classes.cancelButton)}
              onClick={onClose}
              color="error"
              fullWidth
            >
              {getLabel(LangConstant.L_CANCEL_BUTTON)}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Dialog open={Boolean(errorContent)} onClose={() => setErrorContent(null)}>
        <DialogTitle>{getLabel(LangConstant.TXT_NOTIFICATION_ERROR_TITLE)}</DialogTitle>
        <DialogContent>{errorContent}</DialogContent>
        <DialogActions>
          <Button onClick={() => setErrorContent(null)}>{getLabel(LangConstant.L_OK)}</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default ConfirmActiveDialog;

ConfirmActiveDialog.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      image: PropTypes.string,
      name: PropTypes.string,
      state: PropTypes.number,
    }),
  ),

  isShow: PropTypes.bool,

  onClose: PropTypes.func,
  clearCheckedUser: PropTypes.func,
};

ConfirmActiveDialog.defaultProps = {
  onClose: () => {},
  clearCheckedUser: () => {},
};

const useStyles = makeStyles(theme => ({
  popup: {
    width: 500,
    backgroundColor: theme.palette.common.white,
    borderRadius: 4,
    padding: "16px",
  },

  header: {
    textAlign: "center",
    color: theme.palette.common.black,
    fontWeight: 700,
  },

  title: {
    textAlign: "center",
    color: "#6C7078",
    fontSize: 15,
    lineHeight: "16px",
    fontWeight: 400,
  },

  button: {
    borderRadius: 4,
    textTransform: "uppercase",
  },

  deleteButton: {
    color: "#fff",
  },
}));
