import { useMutation } from "react-query";
import { ApiConstant, KeyConstant, SystemConstant } from "const";
import { ApiUtils, StorageUtils, SystemUtils } from "utils";
import useInvalidateQueries from "hooks/common/useInvalidateQueries";

export default function useImportUser() {
  const { invalidateUserQueries } = useInvalidateQueries();

  const importUserMutation = useMutation({
    mutationFn: async file => {
      const formData = new FormData();
      formData.append("file", file);
      return ApiUtils.createApiWithToken(false, { ...ApiUtils.API_CONFIG, responseType: "blob" }).post(
        ApiConstant.IMPORT_USER,
        formData,
      );
    },
    onMutate: () => {
      SystemUtils.openCircleLoading();
    },
    onSettled: () => {
      SystemUtils.openCircleLoading(false);
    },
    onSuccess: async response => {
      if (response.status === ApiConstant.STT_OK) {
        await invalidateUserQueries();
        await ApiUtils.apiCreateUserActivitiesLog(
          SystemConstant.LOGGER_ACTION_TYPES.IMPORT_NEW_USERS_FROM_EXCEL,
          StorageUtils.getStoreData(KeyConstant.KEY_BRANCH_ID),
        );
      }
    },
  });

  const importUsersAsync = async payload => {
    return importUserMutation.mutateAsync(payload);
  };

  return { importUsersAsync };
}
