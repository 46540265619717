import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Card, Tab, Tabs, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Alarm, Search } from "@mui/icons-material";
import dayjs from "dayjs";
import { KeyConstant, LangConstant, SystemConstant } from "const";
import { SystemErrorTable, UserActivityTable } from "./components";
import { StorageUtils, SystemUtils } from "utils";
import { PageTitle } from "components";
import { useSystemTracking } from "hooks";

const SystemTracking = () => {
  const { t: getLabel } = useTranslation(LangConstant.NS_COMMON);
  const classes = useStyles();

  const sidebarTitles = getLabel(LangConstant.OBJ_SIDEBAR_TITLE, { returnObjects: true });
  const branchErrorObj = getLabel(LangConstant.OBJ_BRANCH_ERROR, { returnObjects: true });

  const [fromDate, setFromDate] = useState(dayjs().subtract(7, "days").format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [tab, setTab] = useState(SYSTEM_ERRORS_TABLE);
  const [systemErrorTablePage, setSystemErrorTablePage] = useState(1);
  const [userActivitiesTablePage, setUserActivitiesTablePage] = useState(1);

  const handleGetPayload = () => {
    const page = tab === SYSTEM_ERRORS_TABLE ? systemErrorTablePage : userActivitiesTablePage;
    const fromDateConvert = dayjs(fromDate).startOf("day").valueOf();
    const toDateConvert = dayjs(toDate).endOf("day").valueOf();
    return {
      tab: tab,
      branchId: StorageUtils.getStoreData(KeyConstant.KEY_BRANCH_ID),
      paging: 1,
      asc: 0,
      offset: (page - 1) * LIMITATION,
      limit: LIMITATION,
      sinceTime: fromDateConvert,
      lastTime: toDateConvert,
      orderBy: "created",
    };
  };

  const { systemErrors, userActivities, fetchSystemErrors, fetchUserActivities } = useSystemTracking(
    handleGetPayload(),
  );

  const handleFromDateChange = event => {
    setFromDate(event.target.value);
  };

  const handleToDateChange = event => {
    setToDate(event.target.value);
  };

  const handleSearch = (from, to, type) => {
    const fromDateConvert = dayjs(from).startOf("day").valueOf();
    const toDateConvert = dayjs(to).endOf("day").valueOf();
    if (!Boolean(fromDateConvert) || !Boolean(toDateConvert)) {
      SystemUtils.appendNotification(getLabel(LangConstant.TXT_DATE_NULL), "error");
    } else if (fromDateConvert > toDateConvert) {
      SystemUtils.appendNotification(getLabel(LangConstant.TXT_INVALID_DATE), "error");
    } else if (type === SYSTEM_ERRORS_TABLE) {
      fetchSystemErrors();
    } else if (type === USER_ACTIVITIES_TABLE) {
      fetchUserActivities();
    }
  };

  const handleClickSearch = () => {
    handleSearch(fromDate, toDate, tab);
  };

  const handleChangeTab = (_, newTab) => {
    setTab(newTab);
    setSystemErrorTablePage(1);
    setUserActivitiesTablePage(1);
    setFromDate(dayjs().subtract(7, "days").format("YYYY-MM-DD"));
    setToDate(dayjs().format("YYYY-MM-DD"));
  };

  /**
   * Fetch page data
   */
  useEffect(() => {
    const type =
      tab === SYSTEM_ERRORS_TABLE
        ? SystemConstant.SYSTEM_TRACKING_TYPE.errorsList
        : SystemConstant.SYSTEM_TRACKING_TYPE.userActivities;
    handleSearch(fromDate, toDate, type);
  }, [tab, systemErrorTablePage, userActivitiesTablePage]);

  return (
    <Fragment>
      <PageTitle>{sidebarTitles.systemTracking}</PageTitle>
      <Card className={classes.body}>
        <Box className={classes.searchContainer}>
          <Box>
            <Tabs value={tab} onChange={handleChangeTab}>
              <Tab label={getLabel(LangConstant.TXT_SYSTEM_ERROR_LIST)} />
              <Tab label={getLabel(LangConstant.TXT_USER_ACTIVITY_LIST)} />
            </Tabs>
          </Box>
          <Box className={classes.chooseDate}>
            <Typography variant="h5" className={classes.subTitle}>
              <Alarm fontSize="small" />
              <span>{branchErrorObj.updateTime}:</span>
            </Typography>
            <TextField
              type="date"
              size="small"
              name="from"
              label={branchErrorObj.fromDate}
              classes={{ root: classes.dateInput }}
              value={fromDate}
              onChange={handleFromDateChange}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                max: dayjs().format("YYYY-MM-DD"),
              }}
            />
            <TextField
              type="date"
              size="small"
              name="to"
              label={branchErrorObj.toDate}
              classes={{ root: classes.dateInput }}
              value={toDate}
              onChange={handleToDateChange}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                max: dayjs().format("YYYY-MM-DD"),
              }}
            />
            <Button
              variant="contained"
              size="large"
              className={classes.searchBtn}
              onClick={handleClickSearch}
              disableElevation
            >
              <Search fontSize="small" />
            </Button>
          </Box>
        </Box>
      </Card>
      <Box className={classes.tableWrapper}>
        {tab === SYSTEM_ERRORS_TABLE && (
          <SystemErrorTable
            classes={classes}
            limitation={LIMITATION}
            page={systemErrorTablePage}
            setPage={setSystemErrorTablePage}
            branchErrors={systemErrors || { data: [], totalElements: 0 }}
          />
        )}
        {tab === USER_ACTIVITIES_TABLE && (
          <UserActivityTable
            classes={classes}
            limitation={LIMITATION}
            page={userActivitiesTablePage}
            setPage={setUserActivitiesTablePage}
            userActivities={userActivities || { data: [], totalElements: 0 }}
          />
        )}
      </Box>
    </Fragment>
  );
};

export default SystemTracking;

const SYSTEM_ERRORS_TABLE = 0;
const USER_ACTIVITIES_TABLE = 1;
const LIMITATION = 10;

const useStyles = makeStyles(theme => ({
  headerTitle: {
    marginBottom: 33,
  },

  subTitle: {
    fontSize: 16,
    display: "flex",
    alignItems: "center",
    gap: "4px",
    marginRight: "16px",
  },

  chooseBranch: {
    display: "flex",
    alignContent: "center",
    marginBottom: 20,
    width: "40%",
  },

  chooseDate: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    [theme.breakpoints.down("xl")]: {
      justifyContent: "flex-end",
      marginTop: "16px",
    },
  },

  dateTitle: {
    fontSize: 14,
  },

  dateInput: {
    marginRight: "8px",
    width: "200px",
    "& *": {
      fontSize: 14,
    },
  },

  searchBtn: {
    padding: "9px 22px",
  },

  tableContainer: {
    backgroundColor: "#FFFFFF",
  },

  tableRow: {
    padding: 10,
    "& td, th": {
      fontSize: "14px",
    },
    "& th": {
      fontWeight: "bold",
    },
  },

  displayedRows: {
    fontSize: 14,
    color: "#000000",
  },

  menuItem: {
    fontSize: 14,
  },

  errorNotificationTitle: {
    fontSize: 20,
  },

  errorPaper: {
    minWidth: 300,
  },

  body: {
    padding: "24px 24px 0",
    boxShadow: "unset",
  },

  searchContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "24px",
    [theme.breakpoints.down("xl")]: {
      display: "block",
    },
  },

  tableWrapper: {
    background: "#fff",
    padding: "0 24px 24px",
    borderRadius: "4px",
  },

  tableCell: {
    fontSize: "14px",
  },
}));
