import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { LangConstant } from "const";
import { makeStyles } from "@mui/styles";
import { Box, Button, FormControlLabel, Input, Typography } from "@mui/material";

const SetPasswordForm = ({ onSubmitPasswordForm, onPasswordFormChange, passwordValidated, passwordErrorMsg, form }) => {
  const classes = useStyles();

  const { t: getLabel } = useTranslation(LangConstant.NS_LOGIN);

  return (
    <Box component="form" className={classes.rootForm} onSubmit={onSubmitPasswordForm}>
      <FormControlLabel
        label={getLabel(LangConstant.L_NEW_PASSWORD)}
        labelPlacement="top"
        classes={{
          root: classes.formControlLabelRoot,
          label: classes.formControlLabel,
        }}
        control={
          <Input
            id="password"
            type="password"
            classes={{ root: classes.formControl }}
            placeholder={getLabel(LangConstant.P_NEW_PASSWORD)}
            onChange={onPasswordFormChange}
            value={form.password}
            disableUnderline
          />
        }
      />
      <FormControlLabel
        label={getLabel(LangConstant.L_NEW_PASSWORD_CONFIRMATION)}
        labelPlacement="top"
        classes={{
          root: classes.formControlLabelRoot,
          label: classes.formControlLabel,
        }}
        control={
          <Input
            id="passwordConfirmation"
            type="password"
            classes={{ root: classes.formControl }}
            placeholder={getLabel(LangConstant.P_NEW_PASSWORD)}
            onChange={onPasswordFormChange}
            value={form.passwordConfirmation}
            disableUnderline
          />
        }
      />
      <Button
        classes={{ root: classes.button }}
        type="submit"
        disabled={!passwordValidated}
        variant="contained"
        color="primary"
        fullWidth
      >
        {getLabel(LangConstant.L_CONTINUE_BUTTON)}
      </Button>
      {passwordErrorMsg && (
        <Typography className={classes.textError} variant="caption">
          {passwordErrorMsg}
        </Typography>
      )}
    </Box>
  );
};

export default SetPasswordForm;

SetPasswordForm.prototype = {
  onSubmitPasswordForm: PropTypes.func,
  onPasswordFormChange: PropTypes.func,
  passwordValidated: PropTypes.bool,
  passwordErrorMsg: PropTypes.string,
  form: PropTypes.object,
};

const useStyles = makeStyles(theme => ({
  rootForm: {
    maxWidth: 346,
    width: "100%",
    margin: "56px auto",
  },

  formControlLabelRoot: {
    width: "100%",
    margin: "0 0 16px",
    alignItems: "flex-start",
  },

  formControlLabel: {
    marginBottom: 10,
    fontSize: 15,
    lineHeight: "20px",
  },

  formControl: {
    borderRadius: 4,
    backgroundColor: "#f5f5f5",
    fontSize: 15,
    padding: "4px 16px",
    width: "100%",
    border: "1px solid rgba(0, 0, 0, 0.1)",

    "&::placeholder": {
      color: "#CBCACA",
    },
  },

  button: {
    minHeight: 40,
    borderRadius: 4,
    fontSize: 15,
    lineHeight: "20px",
    fontWeight: 600,
  },

  textError: {
    display: "block",
    textAlign: "center",
    color: theme.palette.error.main,
    paddingTop: 7,
  },
}));
