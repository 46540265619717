import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";

const PaperHeader = ({ icon, title, rightEl }) => {
  const classes = useStyles();

  return (
    <Box className={classes.boxHeader}>
      <Box className={classes.boxHeaderLeft}>
        {icon}
        <Typography variant="h6" className={classes.boxHeaderTitle}>
          {title}
        </Typography>
      </Box>
      {rightEl && <Box className={classes.boxHeaderRight}>{rightEl}</Box>}
    </Box>
  );
};

export default PaperHeader;

const useStyles = makeStyles(theme => ({
  boxHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: 10,
    marginBottom: 20,
    borderBottom: "2px solid #E4E4E4",
  },

  boxHeaderLeft: {
    display: "flex",
    alignItems: "center",
  },

  boxHeaderRight: {
    display: "flex",
    alignItems: "center",
  },

  boxHeaderTitle: {
    marginLeft: 18,
  },
}));
