import React, { memo } from "react";
import PropTypes from "prop-types";
import { SvgIcon } from "@mui/material";

const LogoutIcon = ({ className, width, height }) => {
  return (
    <SvgIcon className={className} width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      <g clipPath="url(#clip0_258_4636)">
        <path
          d="M9.19766 19.2442C9.17361 19.6909 9.18649 20.0818 9.12551 20.4597C8.95374 21.5421 7.90849 22.2448 6.86668 21.9201C4.95741 21.3239 3.06102 20.6857 1.17322 20.0302C0.4114 19.7631 0.00858872 19.148 0.00772985 18.3414C-0.00257662 12.8435 -0.00257662 7.34564 0.00772985 1.84779C0.00772985 0.789448 0.809917 0.012018 1.86805 0.0102999C5.20505 0.00457298 8.53976 0.00170898 11.8722 0.00170898C13.4182 0.00170898 14.5991 1.1365 14.6592 2.68363C14.6953 3.59851 14.6781 4.51596 14.6635 5.43256C14.6549 6.01499 14.2599 6.4196 13.7445 6.41874C13.2292 6.41788 12.8358 6.01671 12.8255 5.43342C12.8109 4.60273 12.8255 3.77204 12.8204 2.9422C12.8204 2.16907 12.4957 1.83318 11.733 1.82545C10.4739 1.8203 9.21398 1.82545 7.95401 1.82545C7.95401 1.86411 7.94542 1.91136 7.95401 1.91136C8.87902 2.33658 9.18563 3.08137 9.18134 4.06497C9.16159 8.33096 9.17275 12.5978 9.17275 16.8647V17.393C10.1673 17.393 11.1095 17.4239 12.0483 17.3801C12.543 17.3569 12.8144 16.9617 12.8212 16.412C12.829 15.3235 12.8212 14.236 12.8212 13.1476C12.8166 13.019 12.8198 12.8902 12.8307 12.7619C12.8942 12.2516 13.2601 11.9175 13.748 11.9192C14.2358 11.9209 14.6438 12.2542 14.6524 12.767C14.673 14.1123 14.7219 15.4636 14.6197 16.8045C14.5106 18.2477 13.3271 19.2313 11.8825 19.2425C10.9961 19.2511 10.1124 19.2442 9.19766 19.2442Z"
          fill="currentColor"
        />
        <path
          d="M16.4929 10.0757H13.7961C13.4817 10.0757 13.1665 10.0834 12.8513 10.0757C12.2844 10.055 11.9203 9.69339 11.9211 9.1668C11.922 8.64021 12.299 8.26137 12.853 8.25536C13.8974 8.24333 14.9418 8.25106 15.987 8.25106H16.4929C16.4929 7.37141 16.5015 6.53298 16.4929 5.69027C16.486 5.23669 16.6097 4.86129 17.0469 4.66973C17.5055 4.46871 17.8671 4.65426 18.1978 4.98843C19.3255 6.12838 20.4643 7.2563 21.5955 8.39194C22.14 8.94001 22.1417 9.39874 21.5955 9.94595C20.4626 11.0833 19.3272 12.2087 18.1952 13.3452C17.8809 13.6613 17.539 13.8563 17.083 13.6793C16.6741 13.5213 16.4938 13.2043 16.4929 12.6425C16.4929 11.8023 16.4929 10.9622 16.4929 10.0757Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_258_4636">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default memo(LogoutIcon);

LogoutIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

LogoutIcon.defaultProps = {
  width: 24,
  height: 24,
};
