import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { AppConstant } from "const";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

const useHandleSnackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const action = snackbarId => (
    <IconButton onClick={() => closeSnackbar(snackbarId)} size="small">
      <Close fontSize="small" htmlColor="#ddd" />
    </IconButton>
  );

  const handleOpenSnackbar = e => {
    const message = e?.detail?.message || null;
    const variant = e?.detail?.variant || "success";
    if (message) {
      enqueueSnackbar(message, { variant: variant, action: action });
    }
  };

  const handleOpenMultipleSnackbar = e => {
    const notifications = e?.detail || [];
    notifications.forEach(notification => {
      const message = notification.message || null;
      const variant = notification.variant || "success";
      if (message) enqueueSnackbar(message, { variant: variant, action: action });
    });
  };

  useEffect(() => {
    window.addEventListener(AppConstant.CUSTOM_EVENTS.openSnackbar, handleOpenSnackbar);
    window.addEventListener(AppConstant.CUSTOM_EVENTS.openMultipleSnackbar, handleOpenMultipleSnackbar);

    return () => {
      window.removeEventListener(AppConstant.CUSTOM_EVENTS.openSnackbar, handleOpenSnackbar);
      window.removeEventListener(AppConstant.CUSTOM_EVENTS.openMultipleSnackbar, handleOpenMultipleSnackbar);
    };
  });
};

export default useHandleSnackbar;
