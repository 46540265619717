import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import { PathConstant } from "const";

const NotFound = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        <Typography variant="h3" marginBottom="16px">
          404
        </Typography>
        <Typography variant="h5" marginBottom="16px">
          The page you are looking for isn't here
        </Typography>
        <Typography variant="caption" display="block" marginBottom="16px">
          You either tried some shady route or you came here by mistake. Whichever it is, try using the navigation.
        </Typography>
        <a href={PathConstant.BRANCH_MANAGEMENT}>Go Back</a>
      </Box>
    </Box>
  );
};

export default NotFound;

const useStyles = makeStyles(theme => ({
  root: {
    padding: "16px",
    width: "100vw",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  content: {
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    height: "100%",
  },

  image: {
    marginTop: 50,
    display: "inline-block",
    maxWidth: "100%",
    width: 200,
    height: 200,
  },
}));
