import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Box, Button, CardMedia, Checkbox, FormControlLabel, Grid, Input, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LangConstant, ImageConstant, PathConstant } from "const";
import { useLogin } from "hooks";

const Login = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t: getLabel } = useTranslation(LangConstant.NS_LOGIN);
  const { isLoginSuccess, login } = useLogin();

  const [form, setForm] = useState({ username: "", password: "", remember: false });
  const [validated, setValidated] = useState(false);

  const handleLogin = event => {
    event.preventDefault();
    login(form);
  };

  const handleChangeTextFields = event => {
    const key = event.target.id;
    const value = event.target.value;
    setForm(currentTextFields => ({ ...currentTextFields, [key]: value }));
  };

  const handleCheckedRemember = event => {
    if (event.target.checked) {
      setForm(currentForm => ({ ...currentForm, remember: true }));
    } else {
      setForm(currentForm => ({ ...currentForm, remember: false }));
    }
  };

  /**
   * Validate login form
   */
  useEffect(() => {
    setValidated(() => validateTextField(form));
  }, [form]);

  /**
   * Redirect to verify smart OTP page after login success
   */
  useEffect(() => {
    if (isLoginSuccess) {
      history.replace(PathConstant.VERIFY_SMART_OTP);
    }
  }, [isLoginSuccess]);

  return (
    <Grid container className={classes.loginContainer}>
      <Grid item lg={4} md={6} xs={12} className={classes.loginFormContainer}>
        <Box className={classes.loginFormItem}>
          <Box className={classes.logoContainer}>
            <CardMedia image={ImageConstant.TriosLogo} className={classes.logo} />
          </Box>
          <Typography variant="h5" className={classes.typography}>
            {getLabel(LangConstant.TXT_LOGIN)}
          </Typography>
          <Box component="form" onSubmit={handleLogin}>
            <Box className={classes.formGroup}>
              <FormControlLabel
                label={getLabel(LangConstant.L_USERNAME)}
                labelPlacement="top"
                classes={{
                  root: classes.formControlLabel,
                  label: classes.formLabel,
                }}
                control={
                  <Input
                    fullWidth
                    id="username"
                    disableUnderline
                    placeholder={getLabel(LangConstant.P_USERNAME)}
                    onChange={handleChangeTextFields}
                    classes={{ root: classes.formControl }}
                  />
                }
              />
            </Box>
            <Box className={classes.formGroup}>
              <FormControlLabel
                label={getLabel(LangConstant.L_PASSWORD)}
                labelPlacement="top"
                classes={{
                  root: classes.formControlLabel,
                  label: classes.formLabel,
                }}
                control={
                  <Input
                    fullWidth
                    id="password"
                    type="password"
                    autoComplete="on"
                    disableUnderline
                    placeholder={getLabel(LangConstant.P_PASSWORD)}
                    onChange={handleChangeTextFields}
                    classes={{ root: classes.formControl }}
                  />
                }
              />
            </Box>
            <Box className={classes.formGroup}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleCheckedRemember}
                    id="remember-me"
                    size="small"
                    classes={{
                      root: classes.formCheckbox,
                      checked: classes.formCheckboxChecked,
                    }}
                  />
                }
                label={getLabel(LangConstant.L_REMEMBER_ME)}
                classes={{
                  root: classes.formControlLabelCheckbox,
                  label: classes.formCheckboxLabel,
                }}
              />
            </Box>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              disabled={!validated}
              classes={{ root: classes.loginButton }}
            >
              {getLabel(LangConstant.L_LOGIN_BUTTON)}
            </Button>
          </Box>
        </Box>
      </Grid>
      <Grid item lg={8} md={6} xs={12} className={classes.rightContainer}>
        <CardMedia image={ImageConstant.LoginBackgroundImage} />
      </Grid>
    </Grid>
  );
};

export default Login;

const validateTextField = payload => payload.username && payload.password;

const useStyles = makeStyles(theme => ({
  loginContainer: {
    height: "100vh",
  },

  loginFormContainer: {
    padding: "0 80px",
    display: "flex",
    alignItems: "center",
  },

  loginFormItem: {
    flex: 1,
  },

  logoContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  logo: {
    width: 72,
    height: 65,
    backgroundPosition: "center",
  },

  typography: {
    textAlign: "center",
    padding: "24px 0",
    fontWeight: "bold",
  },

  formLabel: {
    color: theme.palette.common.black,
    fontSize: 15,
    lineHeight: "20px",
    paddingBottom: 10,
    display: "inline-block",
  },

  formGroup: {
    paddingBottom: 16,
  },

  formControl: {
    border: "none",
    outline: "none",
    borderRadius: 4,
    backgroundColor: "#f5f5f5",
    fontSize: 15,
    padding: "6px 16px",
    width: "100%",
  },

  formControlLabel: {
    alignItems: "flex-start",
    width: "100%",
    margin: 0,
  },

  formControlLabelCheckbox: {
    alignItems: "center",
    width: "100%",
    margin: 0,
  },

  formCheckboxLabel: {
    fontSize: 14,
    userSelect: "none",
    cursor: "pointer",
  },

  formCheckbox: {
    color: theme.palette.common.black,
    opacity: "0.5",
  },

  formCheckboxChecked: {
    color: theme.palette.primary.main,
    opacity: "1",
  },

  rightContainer: {
    backgroundColor: "#f1f1f1",
    display: "flex",
    alignItems: "center",
    maxWidth: "100vw",
    width: "100%",
    justifyContent: "center",
    overflow: "hidden",
    "& div": {
      maxWidth: 634,
      maxHeight: 472,
      width: "100%",
      height: "100%",
      backgroundSize: "contain",
    },
  },

  textError: {
    display: "block",
    textAlign: "center",
    color: theme.palette.error.main,
    paddingTop: 7,
    whiteSpace: "pre-line",
    fontSize: 12,
  },

  loginButton: {
    minHeight: 40,
    borderRadius: 4,
    textTransform: "unset ",
  },
}));
