export const ACCEPTABLE_IMAGE_TYPES = ["image/png", "image/jpeg"];

export const COMPANY_NAME_LENGTH_LIMITED = 60;

export const INPUT_DEBOUNCE_TIME = 500; // milliseconds

export const TRIOS_SERVICE_PHONE_NUM = "0123-456-789";

export const DEFAULT_DOMAIN = "trios.com.vn";

export const MAX_SNACKS = 3;

export const CUSTOM_EVENTS = {
  openSnackbar: "customEventOpenSnackbar",
  openMultipleSnackbar: "customEventOpenMultipleSnackbar",
  openCircleLoading: "customEventOpenCircleLoading",
};

export const API_STATUS = {
  nothing: "nothing",
  calling: "calling",
  success: "success",
  error: "error",
};

export const RESEND_OTP_COUNTDOWN = 60; //seconds

export const EVENT_BUS_EVENTS = {
  fetchBranchDetails: "fetchBranchDetails",
  changeLocale: "changeLocale",
  refetchDepartments: "refetchDepartments",
};

export const IMPORT_USER_FILE_EXTENSIONS = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];

export const COMPANY_MANAGEMENT_TYPE = {
  unit: 0,
  department: 1,
};
