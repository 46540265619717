import React, { Fragment } from "react";
import {
  Box,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp, MoreVert } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { AppConstant, LangConstant } from "const";
import { useTranslation } from "react-i18next";
import DepartmentListTable from "./DepartmentListTable";

const UnitListTable = ({
  unitList,
  totalPage,
  page,
  departmentList,
  onToggleShowDepartment,
  onChangePage,
  onOpenAction,
}) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();

  const langObj = getLabel(LangConstant.OBJ_UNIT_LIST_TABLE, { returnObjects: true });

  const handleClickAction = (event, row, type) => {
    onOpenAction(event, row, type);
  };

  return (
    <Box>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table stickyHeader className={classes.boxTable}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "5%" }}></TableCell>
              <TableCell sx={{ width: "40%", fontWeight: "bold" }}>{langObj.name}</TableCell>
              <TableCell sx={{ width: "25%", fontWeight: "bold" }}>{langObj.departmentNumber}</TableCell>
              <TableCell sx={{ width: "25%", fontWeight: "bold" }}>{langObj.employeeNumber}</TableCell>
              <TableCell sx={{ width: "5%" }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {unitList && unitList.length > 0 ? (
              unitList.map((row, index) => (
                <Fragment key={index}>
                  <TableRow>
                    <TableCell>
                      <IconButton size="small" onClick={() => onToggleShowDepartment(row.unitId)}>
                        {Object.keys(departmentList).includes(row.unitId) ? (
                          <KeyboardArrowUp fontSize="small" />
                        ) : (
                          <KeyboardArrowDown fontSize="small" />
                        )}
                      </IconButton>
                    </TableCell>
                    <TableCell className={classes.name} title={row.unitName}>
                      {row.unitName}
                    </TableCell>
                    <TableCell>{row.departmentNumber}</TableCell>
                    <TableCell>{row.employeeNumber}</TableCell>
                    <TableCell>
                      <IconButton
                        size="small"
                        onClick={event => handleClickAction(event, row, AppConstant.COMPANY_MANAGEMENT_TYPE.unit)}
                      >
                        <MoreVert fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  {Object.keys(departmentList).includes(row.unitId) && (
                    <DepartmentListTable
                      departmentList={departmentList[row.unitId]}
                      onOpenAction={handleClickAction}
                      classes={{ name: classes.name }}
                    />
                  )}
                </Fragment>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  <small>
                    <i>{getLabel(LangConstant.TXT_NOT_FOUND)}</i>
                  </small>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {totalPage > 1 && (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", margin: "24px 0" }}>
          <Pagination
            count={totalPage}
            page={page}
            onChange={onChangePage}
            color="primary"
            variant="outlined"
            shape="rounded"
            showFirstButton
            showLastButton
          />
        </Box>
      )}
    </Box>
  );
};

export default UnitListTable;

const useStyles = makeStyles({
  tableContainer: {
    maxHeight: "500px",
    overflowX: "hidden",
  },

  boxTable: {
    whiteSpace: "nowrap",
    tableLayout: "fixed",
  },

  name: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});
