import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import HumpUtils from "humps";
import { ApiConstant, AppConstant, KeyConstant, LangConstant } from "const";
import { ApiUtils, EventBusUtils, StorageUtils, SystemUtils } from "utils";

export default function useDepartmentList() {
  const { t: getLabel } = useTranslation();

  const [departmentList, setDepartmentList] = useState({});
  const [selectedUnitId, setSelectedUnitId] = useState(null);

  const departmentListQuery = useQuery({
    queryKey: [ApiConstant.REACT_QUERY_KEYS.getDepartmentList],
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: false,
    queryFn: async () => {
      SystemUtils.openCircleLoading();
      const branchId = StorageUtils.getStoreData(KeyConstant.KEY_BRANCH_ID);
      const unitId = selectedUnitId;
      const paging = 1; // 1 for pagination | 0 for get all
      const limit = 10000;
      const asc = 0;
      const offset = 0;
      const orderBy = "created";
      const payload = { branchId, unitId, paging, limit, asc, offset, orderBy };
      const response = await ApiUtils.createApiWithToken(false).get(
        ApiConstant.GET_DEPARTMENT_LIST,
        HumpUtils.decamelizeKeys(payload),
      );
      return HumpUtils.camelizeKeys(response.data);
    },
    onSettled: () => {
      setSelectedUnitId(null);
      SystemUtils.openCircleLoading(false);
    },
    onSuccess: response => {
      setDepartmentList(() => ({ [selectedUnitId]: response?.data?.data || [] }));
    },
    onError: error => {
      console.error(error);
      SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
    },
  });

  const handleToggleShowDepartment = unitId => {
    if (Object.keys(departmentList).includes(unitId)) {
      setSelectedUnitId(null);
      setDepartmentList(currentState => {
        const { [unitId]: departmentListValue, ...rest } = currentState;
        return rest;
      });
    } else {
      setSelectedUnitId(unitId);
    }
  };

  const handleRefetchUnitDepartments = unitId => {
    if (Object.keys(departmentList).includes(unitId)) {
      setSelectedUnitId(unitId);
    }
  };

  useEffect(() => {
    if (selectedUnitId) departmentListQuery.refetch();
  }, [selectedUnitId]);

  useEffect(() => {
    EventBusUtils.on(AppConstant.EVENT_BUS_EVENTS.refetchDepartments, handleRefetchUnitDepartments);
    return () => EventBusUtils.off(AppConstant.EVENT_BUS_EVENTS.refetchDepartments, handleRefetchUnitDepartments);
  });

  return {
    departmentList: departmentList,
    handleToggleShowDepartment: handleToggleShowDepartment,
  };
}
