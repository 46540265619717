import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import qs from "qs";
import { ApiConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import { ApiUtils, HumpUtils, StorageUtils, SystemUtils } from "utils";

export default function useResetUserPassword() {
  const { t: getLabel } = useTranslation();

  const resetUserPasswordMutation = useMutation({
    mutationFn: async data => {
      const response = await ApiUtils.createApiWithToken(false).post(
        ApiConstant.RESET_PASSWORD,
        qs.stringify(HumpUtils.decamelizeKeys(data)),
      );
      return response.data;
    },
    onMutate: () => {
      SystemUtils.openCircleLoading();
    },
    onSettled: () => {
      SystemUtils.openCircleLoading(false);
    },
    onSuccess: async response => {
      if (response.status === ApiConstant.STT_OK) {
        SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_SUCCESS), "success");
        await ApiUtils.apiCreateUserActivitiesLog(
          SystemConstant.LOGGER_ACTION_TYPES.RESET_USER_PASSWORD,
          StorageUtils.getStoreData(KeyConstant.KEY_BRANCH_ID),
        );
      } else {
        SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
      }
    },
    onError: error => {
      console.error(error);
      SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
    },
  });

  const resetUserPassword = payload => {
    resetUserPasswordMutation.mutate(payload);
  };

  return { resetUserPassword };
}
