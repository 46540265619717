import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import qs from "qs";
import { ApiConstant, LangConstant, SystemConstant } from "const";
import { ApiUtils, HumpUtils, SystemUtils } from "utils";

export default function useInviteUser() {
  const { t: getLabel } = useTranslation();

  const inviteUserMutation = useMutation({
    mutationFn: async payload => {
      const response = await ApiUtils.createApiWithToken(false).post(
        ApiConstant.CREATE_ACCOUNT,
        qs.stringify(HumpUtils.decamelizeKeys(payload)),
      );
      return HumpUtils.camelizeKeys(response.data);
    },
    onMutate: () => {
      SystemUtils.openCircleLoading();
    },
    onSettled: () => {
      SystemUtils.openCircleLoading(false);
    },
    onSuccess: async response => {
      if (response.status === ApiConstant.STT_OK && parseInt(response.data) === SystemConstant.USER_ERROR.DUPLICATED) {
        SystemUtils.appendNotification(getLabel(LangConstant.TXT_INVITE_SUCCESS), "success");
      } else {
        SystemUtils.appendNotification(getLabel(LangConstant.TXT_INVITE_FAIL), "error");
      }
    },
    onError: error => {
      console.error(error);
      SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
    },
  });

  const inviteUser = payload => {
    inviteUserMutation.mutate(payload);
  };

  return { inviteUser };
}
