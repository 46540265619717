import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { LangConstant } from "const";
import { PageTitle } from "components";
import { ChangePasswordForm, ChangeLanguageForm, ChangeMode } from "./components";

const Settings = () => {
  const { t: getLabel } = useTranslation([LangConstant.NS_COMMON, LangConstant.NS_LOGIN]);

  const titleObjectContent = getLabel(LangConstant.OBJ_SIDEBAR_TITLE, { returnObjects: true });

  return (
    <Fragment>
      <PageTitle>{titleObjectContent.settings}</PageTitle>
      <Box>
        <ChangePasswordForm />
        <ChangeLanguageForm />
        <ChangeMode />
      </Box>
    </Fragment>
  );
};

export default Settings;
