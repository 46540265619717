import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import qs from "qs";
import { ApiUtils, HumpUtils, StorageUtils, SystemUtils } from "utils";
import { ApiConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import useInvalidateQueries from "hooks/common/useInvalidateQueries";

export default function useCreateUnit() {
  const { t: getLabel } = useTranslation();

  const { invalidateUnitQueries } = useInvalidateQueries();

  const createUnitMutation = useMutation({
    mutationFn: async data => {
      const branchId = StorageUtils.getStoreData(KeyConstant.KEY_BRANCH_ID);
      const payload = { branchId, unitName: data.unitName };
      const response = await ApiUtils.createApiWithToken(false).post(
        ApiConstant.POST_CREATE_UNIT,
        qs.stringify(HumpUtils.decamelizeKeys(payload)),
      );
      return HumpUtils.camelizeKeys(response.data);
    },
    onMutate: () => {
      SystemUtils.openCircleLoading();
    },
    onSettled: () => {
      SystemUtils.openCircleLoading(false);
    },
    onSuccess: async (response, { onClose }) => {
      if (response.status === ApiConstant.STT_OK) {
        SystemUtils.appendNotification(getLabel("TXT_CREATE_UNIT_SUCCESS"), "success");
        await invalidateUnitQueries();
        await ApiUtils.apiCreateUserActivitiesLog(SystemConstant.LOGGER_ACTION_TYPES.CREATE_UNIT);
        onClose();
      } else if (
        response.status === ApiConstant.STT_BAD_REQUEST &&
        parseInt(response.error) === SystemConstant.UNIT_ERROR.MISSING_PARAMETERS
      ) {
        SystemUtils.appendNotification(getLabel("TXT_MISSING_FORM_PARAMS"), "error");
      } else if (
        response.status === ApiConstant.STT_BAD_REQUEST &&
        parseInt(response.error) === SystemConstant.UNIT_ERROR.IN_WRONG_FORMAT
      ) {
        SystemUtils.appendNotification(getLabel("TXT_INVALID_UNIT_NAME"), "error");
      } else if (
        response.status === ApiConstant.STT_BAD_REQUEST &&
        parseInt(response.error) === SystemConstant.UNIT_ERROR.DUPLICATED
      ) {
        SystemUtils.appendNotification(getLabel("TXT_UNIT_DUPLICATED"), "error");
      } else {
        SystemUtils.appendNotification(getLabel("TXT_CREATE_UNIT_FAIL"), "error");
      }
    },
    onError: error => {
      console.error(error);
      SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
    },
  });

  const createUnit = payload => {
    createUnitMutation.mutate(payload);
  };

  return { createUnit };
}
