import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import qs from "qs";
import { ApiConstant, AppConstant, KeyConstant, LangConstant, PathConstant, SystemConstant } from "const";
import { ApiUtils, HumpUtils, StorageUtils, SystemUtils } from "utils";

export default function useFirstLogin() {
  const { t: getLabel } = useTranslation();

  const errorObjectContent = getLabel(LangConstant.ERR_OBJECT, { returnObjects: true });
  const role = StorageUtils.getStoreData(KeyConstant.KEY_ADMIN_ROLE);
  const PASSWORD_STEP = 1;
  const AVATAR_STEP = 2;

  const [step, setStep] = useState(PASSWORD_STEP);
  const [form, setForm] = useState({ password: "", passwordConfirmation: "", avatar: null, previewAvatar: null });
  const [formMessage, setFormMessage] = useState({ password: "", avatar: "" });

  const changePasswordMutation = useMutation({
    mutationFn: async () => {
      const userId = StorageUtils.getStoreData(KeyConstant.KEY_USER_ID);
      const username = StorageUtils.getStoreData(KeyConstant.KEY_USERNAME);
      const oldPassword = window.atob(StorageUtils.getStoreData(KeyConstant.KEY_OLD_PASSWORD));
      const newPassword = form.passwordConfirmation;
      const apiPayload = HumpUtils.decamelizeKeys({ userId, oldPassword, newPassword, username });
      const response = await ApiUtils.createApiWithToken(false).post(
        ApiConstant.CHANGE_PASSWORD,
        qs.stringify(apiPayload),
      );
      return response.data;
    },
    onMutate: () => {
      SystemUtils.openCircleLoading();
    },
    onSettled: () => {
      SystemUtils.openCircleLoading(false);
    },
    onSuccess: async response => {
      if (response.status === ApiConstant.STT_OK) {
        await ApiUtils.apiCreateUserActivitiesLog(SystemConstant.LOGGER_ACTION_TYPES.CHANGE_PASSWORD);
        const remember = Boolean(StorageUtils.getStoreData(KeyConstant.KEY_FIRST_LOGIN));
        StorageUtils.storeData(KeyConstant.KEY_FIRST_LOGIN, 0, remember);
        StorageUtils.removeStoreData(KeyConstant.KEY_OLD_PASSWORD);
        window.location.replace(PathConstant.BRANCH_MANAGEMENT);
      } else {
        setStep(PASSWORD_STEP);
        setForm({ password: "", passwordConfirmation: "", avatar: null, previewAvatar: null });
        SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
      }
    },
    onError: error => {
      console.error(error);
      setStep(PASSWORD_STEP);
      setForm({ password: "", passwordConfirmation: "", avatar: null, previewAvatar: null });
      SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
    },
  });

  const changeAvatarMutation = useMutation({
    mutationFn: async () => {
      const formData = new FormData();
      formData.append("file", form.avatar);
      return ApiUtils.createApiWithToken(false).post(ApiConstant.UPLOAD_AVATAR, formData);
    },
    onMutate: () => {
      SystemUtils.openCircleLoading();
    },
    onSettled: () => {
      SystemUtils.openCircleLoading(false);
    },
    onSuccess: response => {
      if (response.status === ApiConstant.STT_OK) {
        changePasswordMutation.mutate();
      } else {
        setStep(PASSWORD_STEP);
        setForm({ password: "", passwordConfirmation: "", avatar: null, previewAvatar: null });
        SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
      }
    },
    onError: error => {
      console.error(error);
      setStep(PASSWORD_STEP);
      setForm({ password: "", passwordConfirmation: "", avatar: null, previewAvatar: null });
      SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
    },
  });

  const handleChangePasswordForm = event => {
    const id = event.target.id;
    const value = event.target.value;
    setForm(currentForm => ({ ...currentForm, [id]: value }));
    setFormMessage({ password: "", avatar: "" });
  };

  const handleSubmitPasswordForm = event => {
    event.preventDefault();
    setFormMessage({ password: "", avatar: "" });
    if (form.password.trim() !== form.passwordConfirmation.trim()) {
      setFormMessage({ password: errorObjectContent.passwordConfirmationNotMatch, avatar: "" });
    } else if (role === 0) {
      setStep(AVATAR_STEP);
    } else {
        changePasswordMutation.mutate();
    }
  };

  const handleChangeAvatarInput = event => {
    setFormMessage({ password: "", avatar: "" });
    if (event.target.value.length !== 0) {
      const file = event.target.files[0];
      if (file && AppConstant.ACCEPTABLE_IMAGE_TYPES.includes(file.type)) {
        const previewUrl = URL.createObjectURL(file);
        setForm(currentForm => ({ ...currentForm, avatar: file, previewAvatar: previewUrl }));
      } else {
        setForm(currentForm => ({ ...currentForm, avatar: null, previewAvatar: null }));
        setFormMessage({ avatar: errorObjectContent.imageNotValid, password: "" });
      }
    }
  };

  const handleSubmitImageForm = event => {
    event.preventDefault();
    if (!formMessage.avatar && !formMessage.password) {
      changeAvatarMutation.mutate(form);
    }
  };

  const handleBackToPasswordForm = () => {
    setStep(PASSWORD_STEP);
    setForm({ password: "", passwordConfirmation: "", avatar: null, previewAvatar: null });
  };

  return {
    PASSWORD_STEP,
    AVATAR_STEP,
    step,
    form,
    formMessage,
    handleChangePasswordForm,
    handleSubmitPasswordForm,
    handleChangeAvatarInput,
    handleSubmitImageForm,
    handleBackToPasswordForm,
  };
}
