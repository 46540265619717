import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AssignmentOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ConfirmDialog } from "components";
import { LangConstant, SystemConstant } from "const";
import { useCreateDepartmentChannel, useCreateUnitChannel } from "hooks";
import { CommonUtils } from "utils";

const CreateChannelDialog = ({ open, selectedUnit, selectedDepartment, departmentUserList, unitUserList, onClose }) => {
  const isUnit = Boolean(selectedUnit) && selectedDepartment === null;

  const classes = useStyles();
  const { t: getLabel } = useTranslation();
  const channelDialog = getLabel(LangConstant.OBJ_CHANNEL_DIALOG, { returnObjects: true });

  const { createUnitChannel } = useCreateUnitChannel();
  const { createDepartmentChannel } = useCreateDepartmentChannel();

  const [channelForm, setChannelForm] = useState({
    name: selectedUnit?.unitName || selectedDepartment?.departmentName || "",
    mode: SystemConstant.CHANNEL_MODE.private.code,
    user: isUnit ? unitUserList[0] : departmentUserList[0],
    userList: isUnit ? unitUserList : departmentUserList,
  });

  const [confirmDialogState, setConfirmDialogState] = useState({ open: false, title: "" });

  const handleChangeManager = (event, newValue) => {
    setChannelForm(currentState => ({ ...currentState, user: newValue || null }));
  };

  const handleChangeMode = event => {
    setChannelForm(currentState => ({ ...currentState, mode: event.target.value }));
  };

  const handleOpenCofirmDialog = () => {
    setConfirmDialogState(currentState => ({
      ...currentState,
      open: true,
      title: getLabel(isUnit ? channelDialog.unitConfirm : channelDialog.departmentConfirm),
    }));
  };

  const handleCloseConfirmDialog = () => {
    setConfirmDialogState(currentState => ({ ...currentState, open: false }));
  };

  const handleCreateChannel = () => {
    const payload = {
      privateF: channelForm.mode,
      adminId: channelForm.user.id,
      groupType: SystemConstant.GROUP_TYPE.CHANNEL,
      groupId: CommonUtils.uuid(),
      onClose: onClose,
    };
    if (isUnit) {
      createUnitChannel({ ...payload, unitId: selectedUnit.unitId });
    } else {
      createDepartmentChannel({ ...payload, departmentId: selectedDepartment.departmentId });
    }
    handleCloseConfirmDialog();
  };

  useEffect(() => {
    setChannelForm(currentState => ({
      ...currentState,
      name: selectedUnit?.unitName || selectedDepartment?.departmentName || "",
      mode: SystemConstant.CHANNEL_MODE.private.code,
      user: isUnit ? unitUserList[0] : departmentUserList[0],
      userList: isUnit ? unitUserList : departmentUserList,
    }));
  }, [selectedUnit, selectedDepartment]);

  return (
    <Fragment>
      <Dialog open={open} fullWidth classes={{ paper: classes.component }} maxWidth="xs">
        <DialogTitle className={classes.title}>
          <Typography className={classes.titleName}>
            {getLabel(isUnit ? channelDialog.unitTitle : channelDialog.departmentTitle)}
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.channelContent}>
          <InputLabel className={classes.labelField}>{getLabel(channelDialog.channelName)}</InputLabel>
          <TextField
            fullWidth
            disabled
            value={channelForm.name}
            className={classes.infoField}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AssignmentOutlined />
                </InputAdornment>
              ),
            }}
          />
          <InputLabel className={classes.labelField}>{getLabel(channelDialog.channelMode)}</InputLabel>
          <Select fullWidth value={channelForm.mode} onChange={handleChangeMode} className={classes.infoField}>
            {Object.values(SystemConstant.CHANNEL_MODE).map((item, index) => (
              <MenuItem key={index} value={item.code}>
                {getLabel(item.label)}
              </MenuItem>
            ))}
          </Select>
          <InputLabel className={classes.labelField}>{getLabel(channelDialog.channelManager)}</InputLabel>
          <Autocomplete
            value={channelForm.user}
            onChange={handleChangeManager}
            options={channelForm.userList}
            className={classes.infoField}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            noOptionsText={channelDialog.noUser}
            renderInput={params => (
              <TextField {...params} placeholder={getLabel(channelDialog.channelManager)} fullWidth />
            )}
          />
        </DialogContent>
        <DialogContent className={classes.boxButton}>
          <Button variant="outlined" color="error" sx={{ width: "50%" }} onClick={onClose}>
            {getLabel(LangConstant.L_CANCEL)}{" "}
          </Button>
          <Button
            disabled={!channelForm.name || !channelForm.user}
            variant="contained"
            sx={{ width: "50%", ml: 4 }}
            className={classes.editButton}
            onClick={handleOpenCofirmDialog}
          >
            {getLabel(LangConstant.L_CONFIRM_BUTTON)}
          </Button>
        </DialogContent>
      </Dialog>
      <ConfirmDialog
        open={confirmDialogState.open}
        title={confirmDialogState.title}
        onDeny={handleCloseConfirmDialog}
        onConfirm={handleCreateChannel}
      />
    </Fragment>
  );
};

export default CreateChannelDialog;

const useStyles = makeStyles(() => ({
  component: {
    backgroundColor: "#FFFFFF",
  },

  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "8px 0",
  },

  titleName: {
    fontSize: "24px",
    fontWeight: 700,
  },

  channelContent: {
    margin: "0px",
    height: "400px",
    overflowY: "scroll",
  },

  infoField: {
    margin: "10px 0px",
  },

  labelField: {
    fontWeight: 600,
    color: "#000000",
  },

  boxButton: {
    display: "flex",
    justifyContent: "space-between",
  },
}));
