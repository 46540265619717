import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { Box, Button, Popover, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Info, Key, ModeEdit, PersonOff, SupervisorAccount, Sync } from "@mui/icons-material";
import { ApiConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import { StorageUtils, SystemUtils } from "utils";
import { useChangePassword, useResetUserPassword } from "hooks";

const UserAction = ({
  data,
  open,
  anchorEl,
  onCloseAction,
  onClickUserInfo,
  onClickUserEdit,
  onOpenPasswordDialog,
}) => {
  const { t: getLabel } = useTranslation();
  const classes = useStyles();
  const queryClient = useQueryClient();
  const { changePassword } = useChangePassword();
  const { resetUserPassword } = useResetUserPassword();

  const isRootAdmin = StorageUtils.getStoreData(KeyConstant.KEY_ADMIN_ROLE) === SystemConstant.LOGIN_ROLE.ROOT;

  const handleResetPassword = () => {
    if (window.confirm(getLabel(LangConstant.TXT_CONFIRM_ACTION))) {
      resetUserPassword({ id: data.id });
      onCloseAction();
    }
  };

  const handleChangePermission = async (isAdmin = true) => {
    if (window.confirm(getLabel(LangConstant.TXT_CONFIRM_ACTION))) {
      const response = await changePassword({
        isAdmin: isAdmin,
        userId: data.accountId,
        oldPassword: null,
        newPassword: null,
        username: StorageUtils.getStoreData(KeyConstant.KEY_USERNAME),
      });

      if (response.status === ApiConstant.STT_OK) {
        SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_SUCCESS), "success");
        await queryClient.invalidateQueries({ queryKey: [ApiConstant.REACT_QUERY_KEYS.getUserList] });
        onOpenPasswordDialog(response.data.password);
      } else {
        SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
      }

      onCloseAction();
    }
  };

  return (
    <Fragment>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={onCloseAction}
        classes={{ paper: classes.paper }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box className={classes.wrapAction}>
          <Button className={classes.styleButton} onClick={onClickUserInfo}>
            <Info className={classes.actionIcon} />
            <Typography className={classes.actionName}>{getLabel(LangConstant.TXT_INFORMATION)}</Typography>
          </Button>
          <Button className={classes.styleButton} onClick={onClickUserEdit}>
            <ModeEdit className={classes.actionIcon} />
            <Typography className={classes.actionName}>{getLabel(LangConstant.TXT_EDIT_INFO)}</Typography>
          </Button>
          <Button className={classes.styleButton} onClick={handleResetPassword}>
            <Sync className={classes.actionIcon} />
            <Typography className={classes.actionName}>{getLabel(LangConstant.TXT_RESET_PASSWORD)}</Typography>
          </Button>
          {isRootAdmin ? (
            Boolean(data && data.isAdmin) ? (
              <Fragment>
                <Button className={classes.styleButton} onClick={() => handleChangePermission(false)}>
                  <PersonOff className={classes.actionIcon} />
                  <Typography className={classes.actionName}>
                    {getLabel(LangConstant.TXT_REMOVE_ADMIN_PERMISSION)}
                  </Typography>
                </Button>
                <Button className={classes.styleButton} onClick={() => handleChangePermission(true)}>
                  <Key className={classes.actionIcon} />
                  <Typography className={classes.actionName}>
                    {getLabel(LangConstant.TXT_GENERATE_NEW_ADMIN_PASSWORD)}
                  </Typography>
                </Button>
              </Fragment>
            ) : (
              <Button className={classes.styleButton} onClick={() => handleChangePermission(true)}>
                <SupervisorAccount className={classes.actionIcon} />
                <Typography className={classes.actionName}>
                  {getLabel(LangConstant.TXT_GRANT_ADMIN_PERMISSION)}
                </Typography>
              </Button>
            )
          ) : (
            <Fragment />
          )}
        </Box>
      </Popover>
    </Fragment>
  );
};

export default UserAction;

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: 0,
    borderRadius: 4,
    overflowX: "unset",
    overflowY: "unset",
    background: "#FFFFFF",
    width: 360,
  },

  actionName: {
    fontSize: 16,
    fontWeight: 500,
    textAlign: "left",
  },

  actionIcon: {
    minWidth: "unset",
    marginRight: 15,
  },

  wrapAction: {
    margin: 10,
  },

  styleButton: {
    color: "gray",
    textTransform: "none",
    width: "100%",
    justifyContent: "left",
  },
}));
