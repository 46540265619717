import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import qs from "qs";
import { ApiConstant, LangConstant, SystemConstant } from "const";
import { ApiUtils, HumpUtils, SystemUtils } from "utils";

export default function useChangePassword() {
  const { t: getLabel } = useTranslation();

  const changePasswordAsyncMutation = useMutation({
    mutationFn: async data => {
      const response = await ApiUtils.createApiWithToken(false).post(
        ApiConstant.CHANGE_PASSWORD,
        qs.stringify(HumpUtils.decamelizeKeys(data)),
      );
      return response.data;
    },
    onMutate: () => {
      SystemUtils.openCircleLoading();
    },
    onSettled: () => {
      SystemUtils.openCircleLoading(false);
    },
    onSuccess: async response => {
      if (response.status === ApiConstant.STT_OK) {
        await ApiUtils.apiCreateUserActivitiesLog(SystemConstant.LOGGER_ACTION_TYPES.CHANGE_PASSWORD);
      }
    },
    onError: error => {
      console.error(error);
      SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
    },
  });

  const changePassword = async payload => {
    return changePasswordAsyncMutation.mutateAsync(payload);
  };

  return { changePassword };
}
