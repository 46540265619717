import { SystemConstant } from "const";
import useUserList from "./useUserList";
import { CommonUtils } from "utils";
import { selectAllUnitValue } from "pages/CompanyManagement/components/AddMemberDialog";

export default function useUnitUserList(unitId, searchValue = "", page = SystemConstant.ACTIVE_PAGINATION) {
  const { totalUsers } = useUserList();

  const unitUserList = totalUsers
    .filter(user => user.unitId === unitId)
    .map(user => ({ label: user.name, id: user.accountId }));

  const unitUserListFilter = totalUsers
    .filter(
      user =>
        (user.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
          user.mail?.includes(searchValue) ||
          user.phone?.includes(searchValue)) &&
        (unitId && unitId !== selectAllUnitValue ? user.unitId === unitId : user),
    )
    .map(user => ({
      id: user.accountId,
      name: user.name,
      mail: user.mail,
      unit: user.unitName,
      department: user.departmentName,
    }));

  const totalPages = Math.ceil(unitUserListFilter.length / SystemConstant.USERS_LIMITED_NUMBER);

  return {
    unitUserList,
    totalPages,
    currentPageUsers: CommonUtils.paginateFromArray(unitUserListFilter, page, SystemConstant.USERS_LIMITED_NUMBER),
  };
}
