import React, { memo, useEffect, useState } from "react";
import { Box, LinearProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AppConstant } from "const";

const CircleLoading = () => {
  const classes = useStyle();

  const [loading, setLoading] = useState({ openRequest: 0, closeRequest: 0 });
  const isOpen = loading.openRequest !== loading.closeRequest;

  const handleOpenRequest = () => {
    setLoading(currentState => ({
      ...currentState,
      openRequest: currentState.openRequest + 1,
    }));
  };

  const handleCloseRequest = () => {
    if (loading.openRequest > loading.closeRequest) {
      const newCloseRequestNumber = loading.closeRequest + 1;
      setLoading(currentState => ({
        ...currentState,
        closeRequest:
          newCloseRequestNumber > currentState.openRequest ? currentState.openRequest : newCloseRequestNumber,
      }));
    }
  };

  const handleLoading = event => {
    const open = Boolean(event.detail && event.detail === true);
    if (open) handleOpenRequest();
    else handleCloseRequest();
  };

  useEffect(() => {
    window.addEventListener(AppConstant.CUSTOM_EVENTS.openCircleLoading, handleLoading);
    return () => window.removeEventListener(AppConstant.CUSTOM_EVENTS.openCircleLoading, handleLoading);
  });

  // Lúc đầu component này dùng CircularProgress nhưng mọi người feedback nó bị nhấp nháy nên đổi sang LinearProgress
  // Chưa có thời gian đổi tên component này !!!
  return (
    <Box className={classes.circleLoading} sx={{ display: isOpen ? "flex" : "none" }}>
      <Box sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
    </Box>
  );
};

export default memo(CircleLoading);

const useStyle = makeStyles(theme => ({
  circleLoading: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: theme.zIndex.modal + 1,
    background: "transparent",
    alignItems: "flex-start",
    justifyContent: "center",
  },
}));
