import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Paper } from "@mui/material";
import { LangConstant, SystemConstant } from "const";
import { useCreatePosition, usePositionList, useUnitDetails, useUnitList, useUpdatePosition } from "hooks";
import { SystemUtils, UnitUtils } from "utils";
import { PageTitle } from "components";
import { ActionDialog, Datatable, Heading } from "./components";

const PositionManagement = () => {
  const { t: getLabel } = useTranslation();
  const positionError = getLabel(LangConstant.OBJ_POSITION_ERROR, { returnObjects: true });
  const titleObjectContent = getLabel(LangConstant.OBJ_SIDEBAR_TITLE, { returnObjects: true });

  const { departments, handleGetUnitDetails } = useUnitDetails();
  const { totalUnitList } = useUnitList();
  const {
    pagination,
    searchInput,
    unitId,
    departmentId,
    positions,
    handleChangeSearchInput,
    handleChangeDepartment,
    handleChangeUnit,
    handleChangePage,
  } = usePositionList();
  const { createPosition } = useCreatePosition();
  const { updatePosition } = useUpdatePosition();
  const [actionDialogConfig, setActionDialogConfig] = useState({ open: false, position: null });
  const [selectedPositions, setSelectedPositions] = useState([]);
  const selectedPositionIdList = selectedPositions.map(position => position.positionId);

  const handleSelectedPosition = position => {
    setSelectedPositions(currentPositions => {
      if (selectedPositionIdList.includes(position.positionId)) {
        return currentPositions.filter(currentPosition => currentPosition.positionId !== position.positionId);
      }
      return [...currentPositions, position];
    });
  };

  const handleCloseActionDialog = () => {
    setActionDialogConfig({ open: false, position: null });
  };

  const handleCreatePosition = form => {
    if (!UnitUtils.isValidName(form.positionName)) {
      SystemUtils.appendNotification(getLabel(positionError[SystemConstant.POSITION_ERROR.IN_WRONG_FORMAT]), "error");
    } else {
      createPosition({
        positionName: form.positionName,
        unitId: form.unitId,
        departmentId: form.departmentId,
        onClose: handleCloseActionDialog,
      });
    }
  };

  const handleUpdatePosition = form => {
    if (!UnitUtils.isValidName(form.positionName)) {
      SystemUtils.appendNotification(getLabel(positionError[SystemConstant.POSITION_ERROR.IN_WRONG_FORMAT]), "error");
    } else {
      updatePosition({
        positionName: form.positionName,
        unitId: form.unitId,
        departmentId: form.departmentId,
        positionId: form.positionId,
        onClose: handleCloseActionDialog,
      });
    }
  };

  const handleOpenCreatePositionDialog = () => {
    setActionDialogConfig({ open: true, position: null });
  };

  const handleOpenEditPositionDialog = position => {
    setActionDialogConfig({ open: true, position: position });
  };

  const handleHeadingChangeUnit = event => {
    handleChangeUnit(event);
    handleGetUnitDetails(event.target.value);
  };

  const handleDeletePositionSuccess = (_, page) => {
    handleChangePage(_, page);
    setSelectedPositions([]);
  };

  return (
    <Fragment>
      <PageTitle>{getLabel(titleObjectContent.positionManagement)}</PageTitle>
      <Paper sx={{ px: 2, py: 4 }}>
        <Heading
          searchInput={searchInput}
          unitId={unitId}
          departmentId={departmentId}
          units={totalUnitList}
          departments={departments}
          selectedPositions={selectedPositions}
          onClickCreatePosition={handleOpenCreatePositionDialog}
          onChangeSearchInput={handleChangeSearchInput}
          onChangeDepartment={handleChangeDepartment}
          onChangeUnit={handleHeadingChangeUnit}
          onDeletePositionSuccess={handleDeletePositionSuccess}
        />
        <Datatable
          positions={positions}
          pagination={pagination}
          selectedPositions={selectedPositions}
          onChangePage={handleChangePage}
          onSelectPosition={handleSelectedPosition}
          onClickEdit={handleOpenEditPositionDialog}
        />
        <ActionDialog
          open={actionDialogConfig.open}
          position={actionDialogConfig.position}
          departments={departments}
          units={totalUnitList}
          onClose={handleCloseActionDialog}
          onCreate={handleCreatePosition}
          onUpdate={handleUpdatePosition}
          onChangeUnit={handleGetUnitDetails}
        />
      </Paper>
    </Fragment>
  );
};

export default PositionManagement;
