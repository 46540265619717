import React from "react";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { LangConstant } from "const";

const ConfirmDialog = ({ open, title, onDeny, onConfirm }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();

  return (
    <Dialog open={open} classes={{ paper: classes.component }} maxWidth="sm" fullWidth>
      <DialogTitle className={classes.title}>{title}</DialogTitle>
      <DialogActions className={classes.boxButton}>
        <Button variant="outlined" color="error" className={classes.buttonAction} onClick={onDeny}>
          {getLabel(LangConstant.L_CANCEL)}
        </Button>
        <Button variant="contained" className={classes.buttonAction} onClick={onConfirm}>
          {getLabel(LangConstant.L_CONFIRM_BUTTON)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;

const useStyles = makeStyles(() => ({
  component: {
    backgroundColor: "#FFFFFF",
  },

  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "8px",
  },

  titleName: {
    textAlign: "center",
    fontSize: "20px",
    fontWeight: 700,
    whiteSpace: "nowrap",
  },
  boxButton: {
    margin: "8px 0",
  },

  buttonAction: {
    width: "150px",
  },
}));
