import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Chip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LangConstant, SystemConstant } from "const";

const UserStatusActive = ({ status }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();

  const StatusLabel = () => {
    switch (status) {
      case SystemConstant.ACTIVE_STATUS.active:
        return (
          <Chip
            label={getLabel(LangConstant.L_ACTIVE_USER)} 
            color="success"
            variant="outlined"
            className={classes.status}
          />
        );
      default:
        return (
          <Chip
            label={getLabel(LangConstant.L_INACTIVE_USER)}
            color="error"
            variant="outlined"
            className={classes.status}
          />
        );
    }
  };

  return (
    <Box className={classes.container}>
      <StatusLabel />
    </Box>
  );
};

export default UserStatusActive;

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },

  status: {
    cursor: "default",
    width: "130px",
    position: "static",
  },

  iconButton: {
    border: "1px solid #D4D5D8",
    width: "32px",
    height: "32px",
  },
}));
