import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, CardMedia, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ImageConstant, KeyConstant, LangConstant, PathConstant, SystemConstant } from "const";
import { OTPVerify } from "components";
import { AuthUtils, StorageUtils } from "utils";
import { useLogin, useSmartOTP } from "hooks";

const SmartOTP = () => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_COMMON);
  const { login } = useLogin();
  const { otp, verifyOtp, handleChangeOtp, handleReCountTimer } = useSmartOTP();

  const title = getLabel(LangConstant.OBJ_ROUTER_TITLE, { returnObjects: true });

  const handleResendOtp = () => {
    const form = StorageUtils.getStoreData(KeyConstant.KEY_PRE_AUTH);
    login({ ...form, password: window.atob(form.password) });
    handleReCountTimer();
  };

  const handleSubmit = () => {
    const form = StorageUtils.getStoreData(KeyConstant.KEY_PRE_AUTH);
    const payload = { ...form, ...otp };
    verifyOtp(payload);
  };

  const isDisableButton = !Boolean(otp.code.length === SystemConstant.SMART_OTP_CONFIGS.length && otp.retry > 0);

  return (
    <Grid container className={classes.container}>
      <Grid item xl={4} lg={5} md={6} xs={12} className={classes.loginFormContainer}>
        <Box className={classes.optContainer}>
          {otp.blocked ? (
            <Box display="flex" justifyContent="center" flexDirection="column">
              <Typography variant="subtitle2" textAlign="center" color="error" whiteSpace="pre-line">
                {getLabel(LangConstant.TXT_SMART_OTP_BLOCK)}
              </Typography>
              <Button variant="contained" size="small" sx={{ margin: "16px auto" }} onClick={AuthUtils.handleLogout}>
                OK
              </Button>
            </Box>
          ) : (
            <Box>
              <OTPVerify
                TitleProps={{
                  title: getLabel(title[PathConstant.VERIFY_SMART_OTP]),
                  content: getLabel(LangConstant.TXT_VERIFY_SMART_OTP_CONTENT),
                }}
                InputProps={{
                  disabled: otp.retry === 0,
                  otpLength: SystemConstant.SMART_OTP_CONFIGS.length,
                  errorContent: otp.error,
                  onChange: handleChangeOtp,
                }}
                ActionProps={{
                  disabled: otp.retry === 0,
                  startCountDownNumber: otp.resentOtpTrigger,
                  onLogout: AuthUtils.handleLogout,
                  onResendOtp: handleResendOtp,
                }}
              />
              <Button
                size="large"
                variant="contained"
                fullWidth
                disabled={isDisableButton}
                sx={{ marginTop: "16px" }}
                onClick={handleSubmit}
              >
                {getLabel(LangConstant.TXT_VERIFY_OTP)}
              </Button>
            </Box>
          )}
        </Box>
      </Grid>
      <Grid item xl={8} lg={7} md={6} xs={12} className={classes.rightContainer}>
        <CardMedia image={ImageConstant.LoginBackgroundImage} />
      </Grid>
    </Grid>
  );
};

export default SmartOTP;

const useStyles = makeStyles(theme => ({
  container: {
    height: "100vh",
  },

  optContainer: {
    maxWidth: "400px",
    margin: "0 auto",
  },

  loginFormContainer: {
    padding: "0 80px",
    display: "flex",
    alignItems: "center",
  },

  rightContainer: {
    backgroundColor: "#f1f1f1",
    display: "flex",
    alignItems: "center",
    maxWidth: "100vw",
    width: "100%",
    justifyContent: "center",
    overflow: "hidden",
    "& div": {
      maxWidth: 634,
      maxHeight: 472,
      width: "100%",
      height: "100%",
      backgroundSize: "contain",
    },
  },
}));
