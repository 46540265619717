import { KeyConstant } from "const";
import { getStoreData, storeData } from "./storage.utils";
import { HumpUtils } from "utils";

export const handlePersistBranch = (branch, isChangeAccessToken = true) => {
  const isRemember = getStoreData(KeyConstant.KEY_REMEMBER_KEY);
  storeData(KeyConstant.KEY_BRANCH_ID, branch.id, isRemember);
  storeData(KeyConstant.KEY_BRANCH_TYPE, branch.type, isRemember);
  storeData(KeyConstant.KEY_CURRENT_DOMAIN, branch.domain, isRemember);
  storeData(KeyConstant.KEY_BRANCH_MODE, branch.branchMode, isRemember);
  storeData(KeyConstant.KEY_LOGIN_MODE, branch.loginMode, isRemember);
  storeData(KeyConstant.KEY_BRANCH_ATTACHMENT, branch.attachmentId, isRemember);
  if (isChangeAccessToken) storeData(KeyConstant.KEY_ACCESS_TOKEN, branch.accessToken, isRemember);
};

export const getCurrentBranch = () => {
  let currentBranchId = getStoreData(KeyConstant.KEY_BRANCH_ID);
  let branchList = getStoreData(KeyConstant.KEY_BRANCH_LIST);
  return branchList.find(item => item.id === currentBranchId);
};

export const sortBranchByName = branches => {
  branches.sort((prev, next) => {
    let prevBranchName = prev?.name?.toLowerCase();
    let nextBranchName = next?.name?.toLowerCase();
    if (prevBranchName < nextBranchName) return -1;
    if (prevBranchName > nextBranchName) return 1;
    return 0;
  });
  return branches;
};

export const handleUpdateBranchList = branch => {
  branch = HumpUtils.decamelizeKeys(branch);
  const isRemember = getStoreData(KeyConstant.KEY_REMEMBER_KEY);
  const currentBranchList = getStoreData(KeyConstant.KEY_BRANCH_LIST);
  const newBranchList = currentBranchList.map(currentBranch => {
    if (currentBranch.id === branch.id) return branch;
    return currentBranch;
  });
  storeData(KeyConstant.KEY_BRANCH_LIST, newBranchList, isRemember);
};
