import React from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { SnackbarProvider } from "notistack";
import { StyledEngineProvider, ThemeProvider } from "@mui/material";
import { AppConstant } from "const";
import theme from "theme/material";
import App from "./App";

const Provider = () => {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            maxSnack={AppConstant.MAX_SNACKS}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <App />
            <ReactQueryDevtools initialIsOpen={false} />
          </SnackbarProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </QueryClientProvider>
  );
};

ReactDOM.render(<Provider />, document.getElementById("root"));
