import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { AssignmentOutlined } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { LangConstant, SystemConstant } from "const";
import { useCreateUnit, useEditUnit } from "hooks";
import { BranchUtils, CommonUtils, SystemUtils, UnitUtils } from "utils";

const UnitDialog = ({ open, selectedUnit, onClose }) => {
  const isEdit = Boolean(selectedUnit);
  const currentBranch = BranchUtils.getCurrentBranch();

  const classes = useStyles();
  const { t: getLabel } = useTranslation();
  const unitDetail = getLabel(LangConstant.OBJ_UNIT_DETAIL, { returnObjects: true });
  const unitError = getLabel(LangConstant.OBJ_UNIT_ERROR, { returnObjects: true });
  const { createUnit } = useCreateUnit();
  const { editUnit } = useEditUnit();

  const [unitNameInput, setUnitNameInput] = useState(null);

  const handleChangeUnitName = event => {
    setUnitNameInput(CommonUtils.capitalizeFirstLetter(event.target.value));
  };

  const handleClose = () => {
    onClose();
    setUnitNameInput("");
  }

  const handleSubmit = () => {
    if (!UnitUtils.isValidName(unitNameInput)) {
      SystemUtils.appendNotification(getLabel(unitError[SystemConstant.UNIT_ERROR.IN_WRONG_FORMAT]), "error");
    } else if (isEdit) {
      editUnit({
        unitName: unitNameInput,
        unitId: selectedUnit?.unitId || "",
        onClose,
      });
    } else {
      createUnit({
        unitName: unitNameInput,
        onClose,
      });
      setUnitNameInput("");
    }
  };

  useEffect(() => {
    if (selectedUnit) setUnitNameInput(selectedUnit.unitName);
  }, [selectedUnit]);

  return (
    <Fragment>
      <Dialog open={open} classes={{ paper: classes.component }}>
        <DialogTitle className={classes.title}>
          <Typography className={classes.titleName}>
            {getLabel(isEdit ? LangConstant.TXT_EDIT_UNIT : LangConstant.TXT_CREATE_UNIT)}
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.unitContent}>
          <InputLabel className={classes.labelField}>{unitDetail.name}</InputLabel>
          <TextField
            fullWidth
            className={classes.infoField}
            defaultValue={isEdit ? selectedUnit.unitName : ""}
            value={unitNameInput}
            onChange={handleChangeUnitName}
            placeholder={getLabel(LangConstant.TXT_ENTER_UNIT_NAME)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AssignmentOutlined />
                </InputAdornment>
              ),
            }}
          />
          <InputLabel className={classes.labelField}>{unitDetail.branch}</InputLabel>
          <TextField
            disabled
            fullWidth
            className={classes.infoField}
            value={currentBranch.name}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AssignmentOutlined />
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
        <DialogContent className={classes.boxButton}>
          <Button variant="outlined" color="error" sx={{ width: "50%" }} onClick={handleClose}>
            {getLabel(LangConstant.L_CANCEL_ADD_BRANCH)}
          </Button>
          <Button
            variant="contained"
            sx={{ width: "50%", ml: 4 }}
            className={classes.editButton}
            onClick={handleSubmit}
          >
            {getLabel(isEdit ? LangConstant.L_CONFIRM_BUTTON : LangConstant.L_CREATE)}
          </Button>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default UnitDialog;

const useStyles = makeStyles(() => ({
  component: {
    backgroundColor: "#FFFFFF",
    minWidth: "550px",
  },

  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "8px 0",
  },

  titleName: {
    fontSize: "24px",
    fontWeight: 700,
  },

  unitContent: {
    margin: "0px",
    height: "400px",
    overflowY: "scroll",
  },

  infoField: {
    margin: "10px 0px",
  },

  labelField: {
    fontWeight: 600,
    color: "#000000",
  },

  boxButton: {
    display: "flex",
    justifyContent: "space-between",
  },
}));
