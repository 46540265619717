import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import qs from "qs";
import { ApiUtils, AuthUtils, SystemUtils } from "utils";
import { ApiConstant, LangConstant, SystemConstant } from "const";
import usePreAuth from "./usePreAuth";

export default function useLogin() {
  const {
    initSmartOTPOptions,
    hashPassword,
    persistPreAuthConfigs,
    persistFirstLoginConfigs,
    handleRootRoleLoginSuccess,
    handleAdminRoleLoginSuccess,
    handleResendOTPGotLimited,
    handleOTPBlocked,
    handleWrongUsernamePassword,
  } = usePreAuth();

  const { t: getLabel } = useTranslation(LangConstant.NS_LOGIN);
  const errorObjectContent = getLabel(LangConstant.ERR_OBJECT, { returnObjects: true });

  const [isLoginSuccess, setIsLoginSuccess] = useState(AuthUtils.hasLogin());
  const [error, setError] = useState(null);

  /**
   * Open notification
   */
  useEffect(() => {
    if (error) {
      if (errorObjectContent[error.status]) {
        SystemUtils.appendNotification(errorObjectContent[error.status], "error");
      } else {
        SystemUtils.appendNotification(errorObjectContent.somethingWrong, "error");
      }
    }
  }, [error]);

  const loginMutation = useMutation({
    mutationFn: async data => {
      const loginData = { ...data, mode: SystemConstant.WEB_ADMIN_MODE };
      const response = await ApiUtils.createApiByConfig().post(ApiConstant.LOGIN, qs.stringify(loginData));
      return response.data;
    },
    onMutate: () => {
      SystemUtils.openCircleLoading();
    },
    onSettled: () => {
      SystemUtils.openCircleLoading(false);
    },
    onSuccess: (response, loginData) => {
      initSmartOTPOptions(loginData);
      persistPreAuthConfigs(loginData, hashPassword(loginData));
      if (response.status === ApiConstant.STT_OK) {
        persistFirstLoginConfigs(response, loginData, hashPassword(loginData));
        if (response.data.role === SystemConstant.LOGIN_ROLE.ROOT) {
          handleRootRoleLoginSuccess(response, loginData);
          setIsLoginSuccess(true);
        } else if (response.data.role === SystemConstant.LOGIN_ROLE.ADMIN) {
          handleAdminRoleLoginSuccess(response, loginData);
          setIsLoginSuccess(true);
        }
      } else if (response.status === ApiConstant.STT_SMART_OTP_LIMIT) {
        handleResendOTPGotLimited(response, loginData);
        setIsLoginSuccess(true);
      } else if (response.status === ApiConstant.STT_SMART_OTP_BLOCKED) {
        handleOTPBlocked(response, loginData);
        setIsLoginSuccess(true);
      } else {
        handleWrongUsernamePassword(loginData);
        setError(response.data);
      }
    },
    onError: error => {
      console.error(error);
      SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
    },
  });

  const login = payload => {
    loginMutation.mutate(payload);
  };

  return {
    error,
    isLoginSuccess,
    login,
  };
}
