import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CheckCircleOutline, HighlightOffOutlined } from "@mui/icons-material";
import clsx from "clsx";
import { ImageConstant, KeyConstant, LangConstant } from "const";
import { BranchUtils, HumpUtils, StorageUtils } from "utils";
import { PageTitle, PaperHeader } from "components";

const License = () => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation([LangConstant.NS_COMMON, LangConstant.NS_LOGIN]);

  const titleObjectContent = getLabel(LangConstant.OBJ_SIDEBAR_TITLE, { returnObjects: true });
  const branchObj = getLabel(LangConstant.OBJ_TABLE_BRANCH, { returnObjects: true });

  const currentBranchId = StorageUtils.getStoreData(KeyConstant.KEY_BRANCH_ID);

  // TODO: Gọi API để get license của user
  const [license] = useState({ name: "PJ14", date: "20/05/2023", status: true });

  // TODO: Các gói dịch vụ của TRIOS
  // const [_licenseServices] = useState(TRIOS_LICENSE_SERVICE);

  const [currentBranch, setCurrentBranch] = useState({});

  const RightPaperHeader = () => {
    return (
      <Box className={classes.boxHeaderItem}>
        {license.status ? (
          <Box display="flex" alignItems="center">
            <Typography classes={{ root: classes.statusTitle }}>{getLabel(LangConstant.L_ACTIVE)}</Typography>
            <CheckCircleOutline color="success" />
          </Box>
        ) : (
          <Box display="flex" alignItems="center">
            <Typography classes={{ root: classes.statusTitle }}>{getLabel(LangConstant.L_EXPIRED)}</Typography>
            <HighlightOffOutlined color="error" />
          </Box>
        )}
      </Box>
    );
  };

  /**
   * Get current branch info from local storage
   */
  useEffect(() => {
    setCurrentBranch(HumpUtils.camelizeKeys(BranchUtils.getCurrentBranch()));
  }, [currentBranchId]);

  return (
    <Fragment>
      <PageTitle>{titleObjectContent.license}</PageTitle>
      <Box className={clsx(classes.box, classes.activationBox)}>
        <Box className={classes.boxHeader}>
          <PaperHeader
            icon={<ImageConstant.UserTagIcon className={classes.licenseIcon} />}
            title={getLabel(LangConstant.L_ACTIVATION_STATE)}
            rightEl={<RightPaperHeader />}
          />
        </Box>
        <Box>
          {/* {license.status ? (
            <Box>
              <Typography
                variant="caption"
                classes={{ root: classes.licenseMsg }}
              >
                {getLabel(LangConstant.TXT_SERVER_ACTIVE, {
                  name: license.name,
                })}
              </Typography>
              <Typography
                variant="caption"
                classes={{ root: classes.bolderLicenseMsg }}
              >
                &nbsp;
                {license.date}
              </Typography>
            </Box>
          ) : (
            <Typography
              variant="caption"
              classes={{ root: classes.licenseMsg }}
            >
              {getLabel(LangConstant.TXT_SERVER_EXPIRED)}
            </Typography>
          )} */}
          <Box className={classes.licenseDetails}>
            <Typography className={classes.licenseInfo}>{branchObj.license}</Typography>
            <Typography className={classes.licenseInfo}>{currentBranch.license}</Typography>
          </Box>
          <Box className={classes.licenseDetails}>
            <Typography className={classes.licenseInfo}>{branchObj.package}</Typography>
            <Typography className={classes.licenseInfo}>{currentBranch.package}</Typography>
          </Box>
          <Box className={classes.licenseDetails}>
            <Typography className={classes.licenseInfo}>{branchObj.packageId}</Typography>
            <Typography className={classes.licenseInfo}>{currentBranch.packageId}</Typography>
          </Box>
        </Box>
      </Box>
      {/* <Box className={classes.box}>
        <Box className={classes.boxHeader}>
          <Box className={classes.boxHeaderItem}>
            <UpgradeIcon className={classes.licenseIcon} />
            <Typography variant="h5">
              {getLabel(LangConstant.L_UPGRADE_LICENSE)}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.licenseContainer}>
          {licenseServices.map((licenseService) => (
            <Box key={licenseService.id} className={classes.licenseService}>
              {licenseService.active && (
                <Box className={classes.licenseServiceActive}>
                  <Box className={classes.licenseServiceActiveBox} />
                  <CheckOutlined className={classes.licenseServiceActiveIcon} />
                </Box>
              )}
            </Box>
          ))}
        </Box>
        <Box display="flex" alignItems="center">
          <PhoneInTalk />
          <Typography classes={{ root: classes.phoneCallingMsg }}>
            {getLabel(LangConstant.TXT_UPGRADE_LICENSE_PHONE_NUM)}
          </Typography>
          <Typography classes={{ root: classes.phoneNum }}>
            {AppConstant.TRIOS_SERVICE_PHONE_NUM}
          </Typography>
        </Box>
      </Box> */}
    </Fragment>
  );
};

export default License;

// const TRIOS_LICENSE_SERVICE = [
//   {
//     id: 1,
//     active: true,
//   },
//   {
//     id: 2,
//     active: false,
//   },
//   {
//     id: 3,
//     active: false,
//   },
// ];

const useStyles = makeStyles(theme => ({
  box: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 4,
    padding: "28px 36px",
    marginBottom: 16,
  },

  title: {
    marginBottom: "33px",
  },

  statusTitle: {
    color: "#CBCACA",
    fontSize: 15,
    fontWeight: 600,
    lineHeight: "20px",
    marginRight: 10,
  },

  activationBox: {
    width: "100%",
  },

  licenseIcon: {
    width: 36,
    height: 23,
  },

  licenseMsg: {
    display: "inline-block",
    color: theme.palette.common.black,
    fontSize: 15,
    fontWeight: 400,
    lineHeight: "20px",
    marginTop: 20,
  },

  bolderLicenseMsg: {
    display: "inline-block",
    color: theme.palette.common.black,
    fontSize: 15,
    fontWeight: 600,
    lineHeight: "20px",
    marginTop: 20,
  },

  licenseContainer: {
    display: "flex",
    justifyContent: "flex-start",
    margin: "26px 280px",
    flexWrap: "wrap",
  },

  licenseService: {
    position: "relative",
    borderRadius: 4,
    backgroundColor: theme.palette.common.black,
    height: 140,
    flex: "0 0 32%",
    margin: "0.5%",
  },

  licenseServiceActive: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    borderRadius: 4,
    backgroundColor: "transparent",
    overflow: "hidden",
    border: "1px solid " + theme.palette.primary.main,
  },

  licenseServiceActiveBox: {
    position: "absolute",
    bottom: 0,
    right: 0,
    backgroundColor: theme.palette.primary.main,
    width: 100,
    height: 200,
    transform: "translate(50%, 50%) rotate(45deg)",
  },

  licenseServiceActiveIcon: {
    color: theme.palette.common.white,
    position: "absolute",
    bottom: 10,
    right: 10,
  },

  phoneCallingMsg: {
    fontSize: 15,
    fontWeight: 600,
    lineHeight: "20px",
    display: "inline-block",
    marginLeft: 20,
  },

  phoneNum: {
    fontSize: 15,
    fontWeight: 600,
    lineHeight: "20px",
    display: "inline-block",
    marginLeft: 4,
    color: theme.palette.primary.main,
  },

  licenseDetails: {
    display: "flex",
    justifyContent: "space-between",
    margin: "10px 0px",
  },

  licenseInfo: {
    fontSize: 15,
  },
}));
