import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Dialog, IconButton, Typography } from "@mui/material";
import { Close, CloudUpload, FilePresent } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { AppConstant } from "const";

const ImportUserFromExcelDialog = ({ open, onDownload, onClose, onSubmit }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();

  const [file, setFile] = useState({ name: null, data: null });

  const handleSubmit = () => {
    onSubmit(file.data);
  };

  const handleChangeFile = event => {
    const files = event.target.files;
    const file = files.length > 0 ? files[0] : null;
    setFile({ name: file?.name || null, data: file });
  };

  const handleClose = () => {
    const input = document.getElementById("importUserFromExcel");
    input.value = "";
    setFile({ name: null, data: null });
    onClose();
  };

  return (
    <Dialog open={open} fullWidth maxWidth="xs">
      <Box className={classes.container}>
        <Box className={classes.heading}>
          <Typography variant="h5">{getLabel("TXT_UPLOAD_FILE")}</Typography>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
        <Box component="form" className={classes.form}>
          <Box className={classes.flexStartWrapper} onClick={onDownload}>
            <FilePresent color="primary" />
            <Typography>{getLabel("TXT_DOWNLOAD_SAMPLE_FILE")}</Typography>
          </Box>
          <Box className={classes.flexStartWrapper}>
            <Button
              size="small"
              htmlFor="importUserFromExcel"
              component="label"
              variant="contained"
              endIcon={<CloudUpload size="small" />}
              sx={{ flexShrink: 0 }}
            >
              {getLabel("TXT_UPLOAD_FILE")}
            </Button>
            <input
              hidden
              type="file"
              id="importUserFromExcel"
              accept={AppConstant.IMPORT_USER_FILE_EXTENSIONS.join(",")}
              onChange={handleChangeFile}
            />
            <Typography className={classes.fileName} variant="hidden" title={file.name || getLabel("TXT_NOT_FOUND")}>
              {file.name || getLabel("TXT_NOT_FOUND")}
            </Typography>
          </Box>
        </Box>
        <Button fullWidth variant="contained" onClick={handleSubmit}>
          {getLabel("L_ADD_BUTTON")}
        </Button>
      </Box>
    </Dialog>
  );
};

export default ImportUserFromExcelDialog;

const useStyles = makeStyles(() => ({
  container: {
    padding: "16px",
  },

  heading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "16px",
  },

  form: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    minHeight: "300px",
  },

  flexStartWrapper: {
    display: "inline-flex",
    alignItems: "center",
    gap: "8px",

    "&:first-child": {
      cursor: "pointer",
    },
  },

  fileName: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));
