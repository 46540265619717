import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LangConstant } from "const";
import { CommonUtils } from "utils";

const ActionDialog = ({ open, position, units, departments, onClose, onCreate, onUpdate, onChangeUnit }) => {
  const isEdit = Boolean(position);
  const classes = useStyles();
  const { t: getLabel } = useTranslation();

  const [form, setForm] = useState({
    positionName: position?.positionName || "",
    unitId: position?.unitId || "",
    departmentId: position?.departmentId || "",
    positionId: position?.positionId || "",
  });

  const handleChangeForm = event => {
    if (event.target.name === "positionName") {
      setForm(currentState => ({ ...currentState, [event.target.name]: CommonUtils.capitalizeFirstLetter(event.target.value) }));
    } else {
      setForm(currentState => ({ ...currentState, [event.target.name]: event.target.value }));
    }
    if (event.target.name === "unitId") {
      onChangeUnit(event.target.value);
      setForm(currentState => ({ ...currentState, departmentId: "" }));
    }
  };

  const handleSubmit = () => {
    isEdit ? onUpdate(form) : onCreate(form);
  };

  useEffect(() => {
    if (open && position) {
      onChangeUnit(position?.unitId);
      setForm(currentState => ({
        ...currentState,
        positionName: position?.positionName || "",
        unitId: position?.unitId || "",
        departmentId: position?.departmentId || "",
        positionId: position?.positionId || "",
      }));
    } else {
      setForm(currentState => ({
        ...currentState,
        positionName: "",
        unitId: "",
        departmentId: "",
        positionId: "",
      }));
    }
  }, [open, position]);

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ fontWeight: "bold" }}>
        {getLabel(isEdit ? "TXT_EDIT_POSITION_TITLE" : "TXT_CREATE_POSITION_TITLE")}
      </DialogTitle>
      <DialogContent>
        <Box component="form" className={classes.form}>
          <TextField
            fullWidth
            label={getLabel("TXT_POSITION_NAME")}
            placeholder={getLabel("P_POSITION_NAME")}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            value={form.positionName}
            name="positionName"
            onChange={handleChangeForm}
          />
          <FormControl fullWidth>
            <InputLabel shrink id="unit-label">
              {getLabel("TXT_FROM_UNIT")}
            </InputLabel>
            <Select
              value={form.unitId}
              name="unitId"
              labelId="unit-label"
              id="unit-simple-select"
              label={getLabel("TXT_FROM_UNIT")}
              onChange={handleChangeForm}
              notched
              displayEmpty
            >
              <MenuItem value="">{getLabel("TXT_CHOOSE_UNIT")}</MenuItem>
              {units.map(unit => (
                <MenuItem value={unit.unitId} key={unit.unitId}>
                  {unit.unitName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel shrink id="department-label">
              {getLabel("TXT_FROM_DEPARTMENT")}
            </InputLabel>
            <Select
              value={form.departmentId}
              name="departmentId"
              labelId="department-label"
              id="department-simple-select"
              label={getLabel("TXT_FROM_DEPARTMENT")}
              onChange={handleChangeForm}
              disabled={!Boolean(form.unitId)}
              notched
              displayEmpty
            >
              <MenuItem value="">{getLabel("TXT_CHOOSE_DEPARTMENT")}</MenuItem>
              {departments.map(department => (
                <MenuItem value={department.departmentId} key={department.departmentId}>
                  {department.departmentName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box className={classes.buttonContainer}>
            <Button size="large" fullWidth variant="outlined" color="error" onClick={onClose}>
              {getLabel(LangConstant.L_CANCEL_BUTTON)}
            </Button>
            <Button
              size="large"
              fullWidth
              variant="contained"
              color="primary"
              disabled={!Boolean(form.unitId && form.positionName)}
              onClick={handleSubmit}
            >
              {getLabel(isEdit ? LangConstant.L_UPDATE : LangConstant.L_CREATE)}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ActionDialog;

const useStyles = makeStyles(() => ({
  form: {
    padding: "8px 0",
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  },

  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
  },
}));
