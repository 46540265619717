export const ROOT = "/";

export const COMPANY_MANAGEMENT = "/company-management";

export const USER_MANAGEMENT = "/user-management";

export const LICENSE = "/license";

export const SETTINGS = "/settings";

export const LOGIN = "/login";

export const NOT_FOUND = "/not-found";

export const FIRST_LOGIN = "/first-login";

export const VERIFY_SMART_OTP = "/verify-smart-otp";

export const SYSTEM_TRACKING = "/system-tracking";

export const BRANCH_MANAGEMENT = "/branch-management";

export const BRANCH_QR = "/qr";

export const SERVER_MONITORING = "/server-monitoring";

export const POSITION_MANAGEMENT = "/position-management";
