import React, { memo } from "react";
import PropTypes from "prop-types";
import { SvgIcon } from "@mui/material";

const NeedHelpIcon = ({ className, width, height }) => {
  return (
    <SvgIcon className={className} sx={{ width, height }} viewBox={`0 0 54 54`}>
      <circle cx="27" cy="27" r="27" fill="#008FE8" />
      <path
        d="M26.07 27.85C26.84 26.46 28.32 25.64 29.18 24.41C30.09 23.12 29.58 20.71 27 20.71C25.31 20.71 24.48 21.99 24.13 23.05L21.54 21.96C22.25 19.83 24.18 18 26.99 18C29.34 18 30.95 19.07 31.77 20.41C32.47 21.56 32.88 23.71 31.8 25.31C30.6 27.08 29.45 27.62 28.83 28.76C28.58 29.22 28.48 29.52 28.48 31H25.59C25.58 30.22 25.46 28.95 26.07 27.85ZM29 35C29 36.1 28.1 37 27 37C25.9 37 25 36.1 25 35C25 33.9 25.9 33 27 33C28.1 33 29 33.9 29 35Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default memo(NeedHelpIcon);

NeedHelpIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

NeedHelpIcon.defaultProps = {
  width: 40,
  height: 40,
};
