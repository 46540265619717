import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { MoreVert } from "@mui/icons-material";
import { IconButton, TableCell, TableRow } from "@mui/material";
import { AppConstant } from "const";

const DepartmentListTable = ({ departmentList, onOpenAction, classes }) => {
  const { t: getLabel } = useTranslation();

  return (
    <Fragment>
      {departmentList && departmentList.length > 0 ? (
        departmentList.map(row => (
          <TableRow key={row.departmentId} sx={{ backgroundColor: "#f9f9f9" }}>
            <TableCell></TableCell>
            <TableCell
              className={classes.name}
              title={row.departmentName}
              sx={{ fontSize: "0.85rem", color: "#3f3f3f" }}
            >
              {row.departmentName}
            </TableCell>
            <TableCell sx={{ fontSize: "0.85rem", color: "#3f3f3f" }}>--</TableCell>
            <TableCell sx={{ fontSize: "0.85rem", color: "#3f3f3f" }}>{row.employeeNumber}</TableCell>
            <TableCell>
              <IconButton
                size="small"
                onClick={event => onOpenAction(event, row, AppConstant.COMPANY_MANAGEMENT_TYPE.department)}
              >
                <MoreVert fontSize="small" />
              </IconButton>
            </TableCell>
          </TableRow>
        ))
      ) : (
        <TableRow sx={{ backgroundColor: "#f9f9f9" }}>
          <TableCell></TableCell>
          <TableCell colSpan={4} align="left" sx={{ fontSize: "0.85rem", color: "#3f3f3f" }}>
            {getLabel("TXT_NO_DEPARTMENTS")}
          </TableCell>
        </TableRow>
      )}
    </Fragment>
  );
};

export default DepartmentListTable;
