import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { AppConstant, ImageConstant, LangConstant } from "const";
import { makeStyles } from "@mui/styles";
import { Box, Button, CardMedia, FormControlLabel, Input, Typography } from "@mui/material";

const SetAvatarForm = ({ onSubmitImageForm, onAvatarInputChange, avatarErrorMsg, avatar }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_LOGIN);

  return (
    <form className={classes.mediaForm} onSubmit={onSubmitImageForm}>
      <FormControlLabel
        control={
          <Input
            type="file"
            inputProps={{
              accept: AppConstant.ACCEPTABLE_IMAGE_TYPES.join(","),
            }}
            classes={{
              input: classes.mediaInput,
              root: classes.mediaInputRoot,
            }}
            onChange={onAvatarInputChange}
            disableUnderline
          />
        }
        label={
          <Box className={classes.mediaLabelContainer}>
            <CardMedia
              image={avatar.previewAvatar ? avatar.previewAvatar : ImageConstant.AddAvatarIcon}
              classes={{
                root: avatar.previewAvatar ? classes.previewMedia : classes.mediaLabel,
              }}
            />
          </Box>
        }
        labelPlacement="top"
        classes={{ root: classes.mediaControlLabelRoot }}
      />
      <Typography
        classes={{
          root: avatar.previewAvatar ? "hidden" : classes.addAvatarLabel,
        }}
      >
        {getLabel(LangConstant.TXT_ADD_AVATAR)}
      </Typography>
      <Button
        classes={{ root: classes.button }}
        disabled={avatar.previewAvatar === null}
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
      >
        {getLabel(LangConstant.L_CONTINUE_BUTTON)}
      </Button>
      {avatarErrorMsg && (
        <Typography className={classes.textError} variant="caption">
          {avatarErrorMsg}
        </Typography>
      )}
    </form>
  );
};

SetAvatarForm.prototype = {
  avatar: PropTypes.object,
  onSubmitImageForm: PropTypes.func,
  onAvatarInputChange: PropTypes.func,
  avatarErrorMsg: PropTypes.string,
};

export default SetAvatarForm;

const useStyles = makeStyles(theme => ({
  button: {
    minHeight: 40,
    borderRadius: 4,
    fontSize: 15,
    lineHeight: "20px",
    fontWeight: 600,
  },

  textError: {
    display: "block",
    textAlign: "center",
    color: theme.palette.error.main,
    paddingTop: 7,
  },

  mediaForm: {
    maxWidth: 346,
    width: "100%",
    minHeight: 216,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    position: "relative",
    margin: "56px auto",
  },

  mediaControlLabelRoot: {
    position: "relative",
    display: "block",
    margin: "0 auto",
    width: 120,
    height: 120,
    borderRadius: "50%",
    overflow: "hidden",
    border: "1px solid rgba(0, 0, 0, 0.1)",
  },

  mediaInputRoot: {
    position: "absolute",
    width: "100%",
    height: "100%",
  },

  mediaInput: {
    position: "absolute",
    top: 0,
    left: 0,
    opacity: 0,
    width: "100%",
    height: "100%",
    cursor: "pointer",
  },

  mediaLabel: {
    width: "100%",
    height: "100%",
    backgroundSize: "16%",
  },

  mediaLabelContainer: {
    width: "100%",
    height: "100%",
    backgroundColor: "#F5F5F5",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 0,
    margin: "0 auto",
  },

  addAvatarLabel: {
    textAlign: "center",
    fontSize: 15,
    lineHeight: "20px",
    margin: "14px 0",
    fontWeight: 600,
  },

  previewMedia: {
    width: "100%",
    height: "100%",
    backgroundSize: "cover",
  },
}));
