import { ApiConstant } from "const";
import { useQueryClient } from "react-query";

export default function useInvalidateQueries() {
  const queryClient = useQueryClient();

  const invalidateBranchQueries = async () => {
    await queryClient.invalidateQueries([ApiConstant.REACT_QUERY_KEYS.getBranchList]);
    await queryClient.invalidateQueries([ApiConstant.REACT_QUERY_KEYS.getBranchDetails]);
  };

  const invalidateSystemTrackingQueries = async () => {
    await queryClient.invalidateQueries([ApiConstant.REACT_QUERY_KEYS.getSystemErrors]);
    await queryClient.invalidateQueries([ApiConstant.REACT_QUERY_KEYS.getUserActivities]);
  };

  const invalidateUserQueries = async () => {
    await queryClient.invalidateQueries([ApiConstant.REACT_QUERY_KEYS.getUserList]);
  };

  const invalidateUnitQueries = async () => {
    await queryClient.invalidateQueries([ApiConstant.REACT_QUERY_KEYS.getUnitList]);
    await queryClient.invalidateQueries([ApiConstant.REACT_QUERY_KEYS.getTotalUnitList]);
    await queryClient.invalidateQueries([ApiConstant.REACT_QUERY_KEYS.getUnitDetails]);
  };

  const invalidateDepartmentQueries = async () => {
    await queryClient.invalidateQueries([ApiConstant.REACT_QUERY_KEYS.getDepartmentList]);
    await queryClient.invalidateQueries([ApiConstant.REACT_QUERY_KEYS.getDepartmentDetails]);
  };

  const invalidatePositionQueries = async () => {
    await queryClient.invalidateQueries([ApiConstant.REACT_QUERY_KEYS.getPositionList]);
    await queryClient.invalidateQueries([ApiConstant.REACT_QUERY_KEYS.getTotalPositionList]);
    await queryClient.invalidateQueries([ApiConstant.REACT_QUERY_KEYS.getPositionListByUnitId]);
  };

  return {
    invalidateBranchQueries,
    invalidateSystemTrackingQueries,
    invalidateUserQueries,
    invalidateUnitQueries,
    invalidateDepartmentQueries,
    invalidatePositionQueries,
  };
}
