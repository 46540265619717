import React from "react";
import { Typography } from "@mui/material";

const PageTitle = ({ children }) => {
  return (
    <Typography variant="h5" paddingBottom="8px" marginBottom="32px" borderBottom="2px solid black">
      {children}
    </Typography>
  );
};

export default PageTitle;
