import Cookies from "js-cookie";
import { PathConstant } from "const";
import { isJson } from "./common.utils";
import { handleLogout } from "./auth.utils";

export const storeData = (key, defaultValue = "", isRemember = false) => {
  const value = JSON.stringify({ value: defaultValue });
  if (isRemember) {
    Cookies.set(key, value, { expires: 365 });
  } else {
    Cookies.set(key, value);
  }
};

export const removeStoreData = key => {
  Cookies.remove(key);
};

export const getStoreData = key => {
  const defaultValue = JSON.stringify({ value: null });
  const json = Cookies.get(key) || defaultValue;
  if (!isJson(json)) {
    handleLogout(false, false);
    window.location.replace(PathConstant.LOGIN);
  }
  const output = JSON.parse(json);
  return output.value;
};

export const storePermanenceData = (key, defaultValue = "") => {
  const value = JSON.stringify({ value: defaultValue });
  localStorage.setItem(key, value);
};

export const removePermanenceData = key => {
  localStorage.removeItem(key);
};

export const getPermanenceData = key => {
  const defaultValue = JSON.stringify({ value: null });
  const json = localStorage.getItem(key) || defaultValue;
  if (!isJson(json)) {
    handleLogout(false, false);
    window.location.replace(PathConstant.LOGIN);
  }
  const output = JSON.parse(json);
  return output.value;
};
