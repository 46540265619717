import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { ApiConstant, LangConstant } from "const";
import { ApiUtils, HumpUtils, SystemUtils } from "utils";

export default function useSystemTracking(payload) {
  const { t: getLabel } = useTranslation();

  const systemErrorQuery = useQuery({
    enabled: false,
    cacheTime: 0,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    queryKey: [ApiConstant.REACT_QUERY_KEYS.getSystemErrors],
    queryFn: async () => {
      SystemUtils.openCircleLoading();
      const response = await ApiUtils.createApiWithToken(false).get(
        ApiConstant.GET_BRANCH_ERRORS,
        HumpUtils.decamelizeKeys(payload),
      );
      if (response.status === ApiConstant.STT_OK) {
        return {
          data: response.data.data.data,
          totalElements: response.data.data.total_elements,
        };
      }
      throw response;
    },
    onSettled: () => {
      SystemUtils.openCircleLoading(false);
    },
    onError: error => {
      console.error(error);
      SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
    },
  });

  const userActivityQuery = useQuery({
    enabled: false,
    cacheTime: 0,
    keepPreviousData: true,
    queryKey: [ApiConstant.REACT_QUERY_KEYS.getUserActivities],
    queryFn: async () => {
      SystemUtils.openCircleLoading();
      const response = await ApiUtils.createApiWithToken(false).get(
        ApiConstant.GET_USER_ACTIVITIES,
        HumpUtils.decamelizeKeys(payload),
      );
      if (response.status === ApiConstant.STT_OK) {
        return {
          data: response.data.data.data,
          totalElements: response.data.data.total_elements,
        };
      }
      throw response;
    },
    onSettled: () => {
      SystemUtils.openCircleLoading(false);
    },
    onError: error => {
      console.error(error);
      SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
    },
  });

  return {
    systemErrors: systemErrorQuery.data,
    userActivities: userActivityQuery.data,
    fetchSystemErrors: systemErrorQuery.refetch,
    fetchUserActivities: userActivityQuery.refetch,
  };
}
