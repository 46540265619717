import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Pagination,
  TableRow,
} from "@mui/material";
import { LangConstant } from "const";
import dayjs from "dayjs";

const UserActivityTable = ({ classes, limitation, page, setPage, userActivities }) => {
  const { t: getLabel } = useTranslation();

  const tableUserActivities = getLabel(LangConstant.OBJ_TABLE_USER_ACTIVITIES, { returnObjects: true });
  const tableUserActivityTypes = getLabel(LangConstant.OBJ_USER_ACTIVITIES, { returnObjects: true });

  const tableHeaderField = [
    { label: tableUserActivities.number, width: "10%" },
    { label: tableUserActivities.user, width: "20%" },
    { label: tableUserActivities.action, width: "30%" },
    { label: tableUserActivities.time, width: "20%" },
    { label: tableUserActivities.ipAddress, width: "20%" },
  ];

  const handlePageChange = (_, newPage) => {
    setPage(newPage);
  };

  const handleGetActionName = actionType => {
    return tableUserActivityTypes[actionType];
  };

  const handleConvertTime = timestamp => {
    try {
      return dayjs(timestamp).format("DD/MM/YYYY HH:mm");
    } catch (error) {
      return "";
    }
  };

  return (
    <Box>
      <Paper className={classes.tableContainer}>
        <TableContainer sx={{ height: "550px" }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow className={classes.tableRow}>
                {tableHeaderField.map(item => (
                  <TableCell key={item.label} sx={{ width: item.width }}>
                    {item.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {userActivities.data && userActivities.data.length > 0 ? (
                userActivities.data.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell className={classes.tableCell}>{(page - 1) * limitation + (index + 1)}</TableCell>
                      <TableCell className={classes.tableCell}>
                        {item.user_name && item.user_name !== "" ? item.user_name : item.user_id}
                      </TableCell>
                      <TableCell className={classes.tableCell}>{handleGetActionName(item.action_type)}</TableCell>
                      <TableCell className={classes.tableCell}>{handleConvertTime(item.created)}</TableCell>
                      <TableCell className={classes.tableCell}>{item.ip_address}</TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    <small>
                      <i>{getLabel(LangConstant.TXT_NOT_FOUND)}</i>
                    </small>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {Math.ceil(userActivities.totalElements / limitation) > 1 && (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", margin: "24px 0" }}>
          <Pagination
            count={Math.ceil(userActivities.totalElements / limitation)}
            page={page}
            onChange={handlePageChange}
            color="primary"
            variant="outlined"
            shape="rounded"
            showFirstButton
            showLastButton
          />
        </Box>
      )}
    </Box>
  );
};

export default memo(UserActivityTable);
