import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import { LangConstant } from "const";
import en from "./translations/en.lang";
import vi from "./translations/vi.lang";

const option = {
  defaultNS: "common",
  resources: { en, vi },
  lng: LangConstant.DEFAULT_LANGUAGE,
  interpolation: { escapeValue: false },
};

const callback = err => {
  if (err) return console.error(err);
};

i18next.use(initReactI18next).init(option, callback);

export default i18next;
